import { ArrowPathIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import { Spinner } from "../../icons/Spinner";
import { ProgressBar } from "../../ProgressBar";
import { fileWrapper } from "./NewFilesUploader";

export function FileWrapStatus(props: {
  fileWrap: fileWrapper;
  onRetry: () => void;
}) {
  if (props.fileWrap.status === "creating") {
    return (
      <div className="flex items-center gap-x-1.5">
        <Spinner color="gray" size="s" />
        <p className="text-sm text-gray-500/80 font-semibold">
          Creating file...
        </p>
      </div>
    );
  }

  if (props.fileWrap.status === "uploading") {
    // retunr a progress bar

    return (
      <div className="w-32">
        <ProgressBar height="h-2" progress={props.fileWrap.uploadProgress} />
      </div>
    );
  }

  if (props.fileWrap.status === "error") {
    return (
      <div className="">
        <p className="text-xs text-orange-500">Error uploading file</p>
        <button
          className="flex items-center gap-x-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => {
            props.onRetry();
          }}
        >
          <ArrowPathIcon className="h-5 w-5" />
          <p className="text-sm font-semibold">Try again</p>
        </button>
      </div>
    );
  }

  if (props.fileWrap.status === "complete") {
    return (
      <div>
        <CheckCircleIcon className="h-5 w-5 text-green-500" />
      </div>
    );
  }

  return null;
}
