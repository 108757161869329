import { useHistory, useParams } from "react-router-dom";
import useGqlClient from "../../hooks/useGqlClient";
import {
  ActiveDealAccountQuery,
  DealGroupType,
  DealFirmType,
  DealQuery,
  DealRole,
  DealRunner,
  FirmQuery,
  useActiveDealAccountQuery,
  useCreateDealAccountsMutation,
  useCreateDealFirmMutation,
  useCreateDealInviteMutation,
  useDealGroupQuery,
  useDealQuery,
  useFirmQuery,
} from "../../graphql/generated";
import Loading from "../../components/Loading";
import { Steps } from "./CreateDeal";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Button } from "../../components/tailwind/Button";
import { Card } from "../../components/Card";
import { useEffect, useState } from "react";
import { Avatar } from "../../components/account/Avatar";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import { formatDealRole } from "../../utils/enums";
import { AnimatedModal } from "../../components/AnimatedModal";
import { TextInput } from "../../components/tailwind/TextInput";
import { CloseIcon } from "../../components/CloseIcon";
import { TextArea } from "../../components/tailwind/TextArea";
import {
  adminRoles,
  dealRoleOptions,
  DealRoleRestricted,
} from "../../components/DealRoleRestricted";
import { H4 } from "../../components/Heading";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { useQueryClient } from "@tanstack/react-query";

export function People() {
  const { dealId } = useParams<{ dealId: string }>();
  const history = useHistory();

  const client = useGqlClient();
  const dealQuery = useDealQuery(client, {
    id: dealId,
  });

  const [openModal, setOpenModal] = useState<
    "" | "firm" | "invite" | "member" | "add_people"
  >("");

  const [selectedDealFirm, setSelectedDealFirm] = useState<
    DealQuery["deal"]["sellers"][0]["dealFirms"][0] | null
  >(null);

  useEffect(() => {
    if (
      dealQuery.data &&
      dealQuery.data.deal.sellers.length > 0 &&
      dealQuery.data.deal.runner === DealRunner.Seller
    ) {
      const dealFirm = dealQuery.data.deal.sellers[0].dealFirms.find(
        (df) => df.type === DealFirmType.Original
      );
      if (dealFirm) {
        setSelectedDealFirm(dealFirm);
      }

      return;
    }

    if (
      dealQuery.data &&
      dealQuery.data.deal.buyers.length > 0 &&
      dealQuery.data.deal.runner === DealRunner.Buyer
    ) {
      const dealFirm = dealQuery.data.deal.buyers[0].dealFirms.find(
        (df) => df.type === DealFirmType.Original
      );
      if (dealFirm) {
        setSelectedDealFirm(dealFirm);
      }
    }
  }, [dealQuery.data, dealQuery.isRefetching]);

  if (dealQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (dealQuery.isPending || !dealQuery.data) {
    return <Loading />;
  }

  const dealFirmGroups =
    dealQuery.data.deal.runner === DealRunner.Seller
      ? dealQuery.data.deal.sellers
      : dealQuery.data.deal.buyers;

  return (
    <div className="flex-1">
      <div className="flex justify-center">
        <div className="hidden xl:block">
          <Steps currentStep={3} />
        </div>
        <div className="w-full xl:w-1/3">
          <p className="text-lg font-semibold">People</p>
          <p className="text-sm text-gray-500 font-light">
            Add more people from your firm. You can invite people from outside
            your firm later.
          </p>

          <div>
            {dealFirmGroups.map((fg) => {
              return (
                <div key={fg.id}>
                  <p className="font-semibold mt-4">{fg.name}</p>
                  <p className="text-sm text-gray-500 leading-tight">Group</p>
                  <Card margin="s 0 0 0">
                    <div className="p-3">
                      {selectedDealFirm ? (
                        <div>
                          <div className=" flex items-center justify-between">
                            <p className="font-semibold text-gray-600">
                              Members
                            </p>
                            <button
                              onClick={() => {
                                setOpenModal("add_people");
                              }}
                              className="text-indigo-500 font-semibold text-sm hover:text-indigo-600"
                            >
                              <div className="flex items-center">
                                <PlusIcon className="w-5 h-5 " />
                                <p>Add people from your firm</p>
                              </div>
                            </button>
                          </div>
                          {selectedDealFirm.dealAccounts.map((da) => {
                            return (
                              <div>
                                <div
                                  key={da.account.id}
                                  className="py-2 flex items-center justify-between"
                                >
                                  <div className="flex items-center flex-1">
                                    <Avatar account={da.account} size="m" />
                                    <div className="ml-3">
                                      <p className="text-sm font-semibold text-gray-600">
                                        {da.account.name}
                                      </p>
                                      <p className="text-xs leading-tight text-gray-500">
                                        {da.account.email}
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <p className="text-sm text-gray-400">
                                      {formatDealRole(da.role)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end mt-8 gap-x-3">
            <Button
              onClick={() => {
                history.push(`/deal/dashboard`);
              }}
              variant="positive"
              text="Continue"
            />
          </div>
        </div>
      </div>
      {selectedDealFirm ? (
        // TODO: getting deleted anyway
        <AddPeopleFromFirm
          dealGroup={dealFirmGroups[0]}
          dealId={dealId}
          open={openModal === "add_people"}
          firmGroupType={DealGroupType.Seller}
          onClose={() => {
            setOpenModal("");
          }}
        />
      ) : null}
    </div>
  );
}

function NewFirmModal(props: {
  permissions: DealQuery["deal"]["dataRoom"]["permissions"];
  dealFirmGroupId: string;
  onFirmCreated: () => void;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <AnimatedModal
      open={props.open}
      size="md"
      onClose={() => {
        props.onClose();
      }}
    >
      <NewFirmModalContent
        permissions={props.permissions}
        dealFirmGroupId={props.dealFirmGroupId}
        onFirmCreated={props.onFirmCreated}
        open={props.open}
        onClose={props.onClose}
      />
    </AnimatedModal>
  );
}

function NewFirmModalContent(props: {
  permissions: DealQuery["deal"]["dataRoom"]["permissions"];
  dealFirmGroupId: string;
  onFirmCreated: () => void;
  open: boolean;
  onClose: () => void;
}) {
  const client = useGqlClient();

  const dealGroupQuery = useDealGroupQuery(client, {
    id: props.dealFirmGroupId,
  });

  const createDealFirm = useCreateDealFirmMutation(client);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [error, setError] = useState("");

  const [selectedPermission, setSelectedPermission] = useState<Option>({
    label: props.permissions[0].name,
    description: props.permissions[0].description,
    value: props.permissions[0].id,
  });

  if (dealGroupQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (dealGroupQuery.isPending || !dealGroupQuery.data) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-lg font-semibold">New firm</p>
          <p className="text-sm text-gray-500 font-light">
            Add a new firm to {dealGroupQuery.data.dealGroup.name}
          </p>
        </div>
        <CloseIcon onClose={props.onClose} />
      </div>
      <TextInput
        placeholder="Enter the name of the firm..."
        margin="m 0"
        label="Name"
        value={name}
        onChange={(e) => {
          setNameError("");
          setName(e.currentTarget.value);
        }}
        error={nameError}
      />

      {dealGroupQuery.data.dealGroup.type === DealGroupType.Buyer ? (
        <>
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Data Room Permission
          </label>
          <Dropdown
            onSelect={(option) => {
              setSelectedPermission(option);
            }}
            selectedOption={selectedPermission}
            options={props.permissions.map((p) => {
              return {
                label: p.name,
                description: p.description,
                value: p.id,
              };
            })}
          />
        </>
      ) : null}

      <div className="flex justify-end mt-8 gap-x-3">
        <Button
          onClick={() => {
            if (!selectedPermission.value) {
              setError("Permission is required");
              return;
            }

            if (!name) {
              setNameError("Name is required");
              return;
            }

            setError("");

            // TODO: Come back to this
            // createDealFirm.mutate(
            //   {
            //     input: {
            //       name,
            //       dealFirmGroupId: props.dealFirmGroupId,
            //       createNdaFile: false,
            //       dataRoomAccessPreNda: false,
            //     },
            //   },
            //   {
            //     onSuccess: () => {
            //       props.onFirmCreated();
            //     },
            //     onError: (e) => {
            //       setError("Failed to create firm");
            //     },
            //   }
            // );
          }}
          variant="positive"
          text="Create"
          isLoading={createDealFirm.isPending}
          loadingText="Creating..."
        />
      </div>
      {error ? (
        <div className="mt-4">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      ) : null}
    </div>
  );
}

export function DealInviteModal(props: {
  open: boolean;
  onClose: () => void;
  dealId: string;
  dealFirmId: string;
  onInviteSent: () => void;
}) {
  return (
    <AnimatedModal
      open={props.open}
      size="md"
      onClose={() => {
        props.onClose();
      }}
    >
      <DealInviteModalContent
        dealId={props.dealId}
        dealFirmId={props.dealFirmId}
        onInviteSent={props.onInviteSent}
        open={props.open}
        onClose={props.onClose}
      />
    </AnimatedModal>
  );
}

function DealInviteModalContent(props: {
  dealId: string;
  dealFirmId: string;
  onInviteSent: () => void;
  open: boolean;
  onClose: () => void;
}) {
  const client = useGqlClient();
  const [message, setMessage] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteEmailError, setInviteEmailError] = useState("");
  const [inviteRole, setInviteRole] = useState<Option>({
    label: "Seller",
    value: DealRole.SellerStaff,
    description: "Can view and manage documents in the data room",
  });

  const createDealInvite = useCreateDealInviteMutation(client);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-lg font-semibold">Invite</p>
          <p className="text-sm text-gray-500 font-light">
            Invite someone to the firm
          </p>
        </div>
        <CloseIcon onClose={props.onClose} />
      </div>
      <div className=" mt-4 gap-x-2">
        <TextInput
          label="Email"
          inputSize="s"
          type="email"
          placeholder="john.smith@liquidacquire.com"
          value={inviteEmail}
          error={inviteEmailError}
          onChange={(e) => {
            setInviteEmailError("");
            setInviteEmail(e.currentTarget.value);
          }}
        />

        <p className="mt-3 block text-sm font-medium leading-6 text-gray-900">
          Deal role
        </p>
        <Dropdown
          size="s"
          onSelect={(o) => {
            setInviteRole(o);
          }}
          options={[
            {
              label: "Deal admin",
              value: DealRole.DealAdmin,
              description: "Can manage all aspects of the deal",
            },
            {
              label: "Seller",
              value: DealRole.SellerStaff,
              description: "Can view and manage documents in the data room",
            },
          ]}
          selectedOption={inviteRole}
        />
        <div className="flex items-center justify-between mt-4">
          <p className="block text-sm font-medium leading-6 text-gray-900">
            Message
          </p>
          <span className="text-sm text-gray-400">Optional</span>
        </div>
        <TextArea
          rows={2}
          name="message"
          value={message}
          onChange={(e) => {
            setMessage(e.currentTarget.value);
          }}
          placeholder="Anything they might need to know?"
        />
        <div className="mt-8 flex justify-end">
          <Button
            text="Invite"
            variant="positive"
            isLoading={createDealInvite.isPending}
            onClick={() => {
              if (!inviteEmail) {
                setInviteEmailError("Email is required");
                return;
              }

              setInviteEmailError("");
            }}
          />
        </div>
      </div>
    </div>
  );
}

function AddPeopleFromFirm(props: {
  open: boolean;
  onClose: () => void;
  dealGroup: DealQuery["deal"]["sellers"][0];
  firmGroupType: DealGroupType;
  dealId: string;
}) {
  const client = useGqlClient();
  const activeDealAccount = useActiveDealAccountQuery(client, {
    id: props.dealId,
  });

  if (activeDealAccount.error) {
    return null;
  }

  if (activeDealAccount.isPending || !activeDealAccount.data) {
    return null;
  }

  return (
    <AnimatedModal size="lg" open={props.open} onClose={props.onClose}>
      <DealRoleRestricted roles={adminRoles}>
        <div>
          <div className="flex items-center justify-between">
            <H4>Add people from your firm</H4>
            <CloseIcon onClose={props.onClose} />
          </div>
          <AddPeopleFromFirmContent
            dealGroup={props.dealGroup}
            onClose={props.onClose}
            activeDealAccount={activeDealAccount.data.deal.activeDealAccount}
            dealId={props.dealId}
            firmGroupType={props.firmGroupType}
          />
        </div>
      </DealRoleRestricted>
    </AnimatedModal>
  );
}

interface AccountToAdd {
  id: string;
  role: DealRole;
}

function AddPeopleFromFirmContent(props: {
  activeDealAccount: ActiveDealAccountQuery["deal"]["activeDealAccount"];
  dealGroup: DealQuery["deal"]["sellers"][0];
  firmGroupType: DealGroupType;
  onClose: () => void;
  dealId: string;
}) {
  const account = useSelector(authSelectors.account);
  const dealRunner = useSelector(authSelectors.activeDealRunner);
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const [accountsToAdd, setAccountsToAdd] = useState<AccountToAdd[]>([]);

  const createDealAccounts = useCreateDealAccountsMutation(client);

  const firmQuery = useFirmQuery(client, {
    id: account && account.firm ? account.firm.id : "",
  });

  if (firmQuery.error) {
    return <div>Error</div>;
  }

  if (firmQuery.isPending || !firmQuery.data) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const availableAccounts = firmQuery.data.firm.accounts.filter((acc) => {
    return !props.dealGroup.accounts.some(
      (dealAcc) => dealAcc.account.id === acc.id
    );
  });

  return (
    <div>
      <div className="mt-3">
        {availableAccounts.length === 0 ? (
          <p className="text-gray-500">No accounts available to add</p>
        ) : null}
        {availableAccounts.map((acc) => {
          return (
            <div key={acc.id} className="py-2">
              <Account
                roleOptions={dealRoleOptions}
                account={acc}
                onToggle={(role, isChecked) => {
                  if (isChecked) {
                    setAccountsToAdd((prev) => {
                      return [...prev, { id: acc.id, role }];
                    });
                  } else {
                    setAccountsToAdd((prev) => {
                      return prev.filter((a) => a.id !== acc.id);
                    });
                  }
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="mt-8 flex justify-end">
        <Button
          variant="positive"
          text="Add"
          isDisabled={accountsToAdd.length === 0}
          isLoading={createDealAccounts.isPending}
          loadingText="Adding..."
          onClick={() => {
            createDealAccounts.mutate(
              {
                input: {
                  dealGroupId: props.dealGroup.id,
                  dealId: props.dealId,
                  accounts: accountsToAdd.map((a) => {
                    return {
                      accountId: a.id,
                      role: a.role,
                      ndaSigned: true,
                    };
                  }),
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["Deal", { id: props.dealId }],
                  });
                  props.onClose();
                },
              }
            );
          }}
        />
      </div>
    </div>
  );
}

function Account(props: {
  roleOptions: Option[];
  account: FirmQuery["firm"]["accounts"][0];
  onToggle(role: DealRole, isChecked: boolean): void;
}) {
  const [selectedRole, setSelectedRole] = useState<Option>(
    props.roleOptions[0]
  );
  return (
    <div className="flex justify-between">
      <div className="relative flex items-start">
        <div className="flex h-6 items-start">
          <input
            id="account_to_add_checkbox"
            aria-describedby="comments-description"
            name="account_to_add_checkbox"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            onChange={(e) => {
              props.onToggle(
                selectedRole.value as DealRole,
                e.currentTarget.checked
              );
            }}
          />
        </div>
        <div className="ml-3 text-sm leading-tight">
          <label
            htmlFor="account_to_add_checkbox"
            className="font-medium text-gray-900"
          >
            {props.account.name}
          </label>
          <p id="account_to_add_email" className="text-gray-500">
            {props.account.email}
          </p>
        </div>
      </div>
      <Dropdown
        hideSelectedOptionDescription
        selectedOption={selectedRole}
        options={props.roleOptions}
        onSelect={(e) => {
          setSelectedRole(e);
        }}
      />
    </div>
  );
}
