import {
  DataRoomFile,
  DataRoomFolderFragmentFragment,
  DataRoomFolderQuery,
  DataRoomPermission,
} from "@/src/graphql/generated";
import { AnimatedModal } from "../../AnimatedModal";
import { H3 } from "../../Heading";
import { CloseIcon } from "../../CloseIcon";
import { Option } from "../../tailwind/Dropdown";
import { useEffect, useState } from "react";
import { MultiStepUploader, SingleTabUploader } from "./MultiStepUploader";
import { useInvalidateQueryKeys } from "@/src/hooks/useInvalidateQueryKeys";

interface NewFilesUploaderProps {
  open: boolean;
  data: DataRoomFolderQuery | undefined;
  isLoading: boolean;
  error?: unknown;
  onClose: () => void;
  permissions: DataRoomPermission[];
  type: "multi-step" | "single-step";
}

export interface fileWrapper {
  id: number;
  file: File;
  name: string;
  dataRoomPermissionId: string;
  uploadProgress: number;
  status: "creating" | "uploading" | "complete" | "error" | "pending";
  selectedPermission: Option;
}

export interface transactionFileWrapper {
  isSelected: boolean;
  file: DataRoomFile;
  name: string;
  dataRoomPermissionId: string;
  status: "pending" | "complete" | "error" | "copying";
}

export function NewFilesUploader(props: NewFilesUploaderProps) {
  const [folders, setFolders] = useState<
    DataRoomFolderFragmentFragment["parentFolders"]
  >([]);

  const {
    invalidateDataRoomFolders,
    invalidateTransactionFiles,
    invalidateDealActivities,
  } = useInvalidateQueryKeys();

  useEffect(() => {
    if (props.isLoading || props.error || !props.data) {
      return;
    }

    setFolders(props.data.dataRoomFolder.parentFolders);
  }, [props.data, props.isLoading, props.error]);

  const crumbs = [
    "Home",
    ...folders
      .slice(0, folders.length - 1)
      .map((f) => f.name)
      .reverse(),
  ];

  function clearCaches() {
    invalidateDataRoomFolders();
    invalidateTransactionFiles();
    invalidateDealActivities();
  }

  if (!props.data || props.isLoading) {
    return null;
  }

  return (
    <AnimatedModal
      padding="p-0"
      open={props.open}
      onClose={() => {
        clearCaches();
        props.onClose();
      }}
      size="xxl"
    >
      <div>
        <div className="p-4 flex justify-between items-center">
          <div>
            <H3>New files</H3>
            <p className="text-sm text-gray-500 leading-none">
              In {`${crumbs.join(" / ")}`}
            </p>
          </div>
          <CloseIcon
            onClose={() => {
              props.onClose();
            }}
          />
        </div>
        {props.type === "multi-step" ? (
          <MultiStepUploader
            permissions={props.permissions}
            dataRoomFolderId={props.data?.dataRoomFolder.id}
            onDone={() => {
              clearCaches();
              props.onClose();
            }}
          />
        ) : (
          <SingleTabUploader
            dataRoomFolderId={props.data?.dataRoomFolder.id}
            permissions={props.permissions}
            onDone={() => {
              clearCaches();
              props.onClose();
            }}
          />
        )}
      </div>
    </AnimatedModal>
  );
}

export function SingleStepUploader() {
  return <div>SingleStepUploader</div>;
}
