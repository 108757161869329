import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";

const ProtectedRoute = (
  props: RouteProps & {
    children: React.ReactNode;
    onlyUnAuthenticated?: boolean;
  }
) => {
  const { isSignedIn } = useAuth();

  let onlyUnAuthenticated =
    props.onlyUnAuthenticated === null ? false : props.onlyUnAuthenticated;

  let children = props.children;

  const renderChildren = () => {
    if (!isSignedIn) {
      if (onlyUnAuthenticated) {
        return children;
      }
      return <Redirect to="/signup" />;
    }

    return children;
  };

  if (isSignedIn && onlyUnAuthenticated) {
    return <Redirect to="/" />;
  }

  return <Route {...props}>{renderChildren}</Route>;
};

export default ProtectedRoute;
