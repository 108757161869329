import {
  DealFirmQuery,
  useRemoveDealAccountMutation,
  useDealAccountQuery,
  DealGroup,
  useDealActivityQuery,
  DealAccountQuery,
  DealAccessStatus,
  useUpdateDealAccountMutation,
} from "../../graphql/generated";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import { useQueryClient } from "@tanstack/react-query";
import useGqlClient from "../../hooks/useGqlClient";
import { useHistory, useParams } from "react-router-dom";
import Loading from "@/src/components/Loading";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CircleMinusIcon,
  EyeIcon,
} from "lucide-react";
import { Avatar } from "@/src/components/account/Avatar";
import { Card } from "@/src/components/Card";
import { Button } from "@/src/components/tailwind/Button";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { formatDataRoomAccess } from "./AccessList";
import { formatEnum } from "@/src/utils/enums";
import { FileIcon } from "@/src/components/FileIcon";
import { classNames } from "@/src/utils/cn";
import { FolderIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { ActivityItems } from "@/src/components/activity/ActivityItems";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import { buildFolderHierarchy, FolderWrapper } from "./AccessMatrix";
import { toasts } from "@/src/components/toasts/toasts";
import { actions } from "@/src/store/auth/slice";

export function DealAccountDetails() {
  const { dealAccountId } = useParams<{ dealAccountId: string }>();
  const client = useGqlClient();
  const history = useHistory();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const dealAccountQuery = useDealAccountQuery(client, {
    id: dealAccountId,
  });

  const dispatch = useDispatch();

  const [fileSearch, setFileSearch] = useState("");

  if (dealAccountQuery.error) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <p className="text-gray-500 font-semibold">Something went wrong</p>
      </div>
    );
  }

  if (dealAccountQuery.isLoading || !dealAccountQuery.data) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <Loading />
      </div>
    );
  }

  const dealAccount = dealAccountQuery.data.dealAccount;
  const folders = buildFolderHierarchy(dealAccount.allFolders);
  return (
    <div className="w-full">
      <button
        type="button"
        className="font-semibold text-sm text-gray-500 hover:text-gray-700 flex items-center gap-x-2"
        onClick={() => history.goBack()}
      >
        <ArrowLeftIcon className="w-4 h-4" /> Back
      </button>
      <div className="mt-4 flex flex-col xl:flex-row lg:gap-x-12">
        <div>
          <div className="flex items-center gap-x-3">
            <Avatar account={dealAccount.account} size="l" />
            <div>
              <p className="text-lg font-semibold">
                {dealAccount.account.name}
              </p>
              <p className="text-sm text-gray-500">
                {dealAccount.account.email}
              </p>
            </div>
          </div>

          <div className="mt-4 flex gap-x-2">
            <Button
              text={`View deal as ${dealAccount.account.name}`}
              variant="neutral"
              size="s"
              icon={EyeIcon}
              onClick={() => {
                dispatch(
                  actions.setActor({
                    actor: {
                      account: dealAccount.account,
                      dealAccountId: dealAccount.id,
                    },
                  })
                );
                history.push("/deal/dashboard");
              }}
            />
            <AccessStatusButton dealAccount={dealAccount} />
          </div>

          <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-1">
            <p className="text-sm text-gray-500">Last seen</p>
            <p className="text-sm text-gray-600 font-semibold">
              {formatDistanceToNow(fromUnixTime(dealAccount.lastSeen), {
                addSuffix: true,
              })}
            </p>
            <p className="text-sm text-gray-500">Access</p>
            <p className="text-sm text-gray-600 font-semibold">
              {formatDataRoomAccess(
                dealAccount.overrideDataRoomAccess,
                dealAccount.granularAccess,
                dealAccount.dataRoomPermission,
                dealAccount.dealGroup as DealGroup,
                dealAccount.dealAccessStatus
              )}
            </p>
            <p className="text-sm text-gray-500">Role</p>
            <p className="text-sm text-gray-600 font-semibold">
              {formatEnum(dealAccount.role)}
            </p>
            <p className="text-sm text-gray-500">Group</p>
            <p className="text-sm text-gray-600 font-semibold">
              {dealAccount.dealGroup ? dealAccount.dealGroup.name : "-"}
            </p>
          </div>
        </div>

        <div className="flex-1 mt-8 lg:mt-0">
          <div>
            <p className=" text-gray-700 flex-1 font-semibold">
              Documents {dealAccount.account.name} has access to
            </p>

            <Card margin="s 0 0 0">
              <input
                className="mb-2 focus:ring-0 rounded-t-md text-sm border-t-0 border-x-0 border-b border-gray-300 w-full focus:border-gray-700"
                placeholder="Search..."
                value={fileSearch}
                onChange={(e) => setFileSearch(e.target.value)}
              />
              <div className="overflow-y-auto max-h-[400px]">
                {folders.map((folder) => (
                  <FolderContent
                    folder={folder}
                    searchTerm={fileSearch}
                    depth={0}
                  />
                ))}
              </div>
            </Card>
          </div>
          <div className="mt-5">
            <p className="mb-2 text-gray-700 font-semibold">Activity</p>
            <DealActivity
              dealId={activeDealId ?? ""}
              accountId={dealAccount.account.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function DealActivity(props: { dealId: string; accountId: string }) {
  const client = useGqlClient();
  const dealActivityQuery = useDealActivityQuery(client, {
    dealId: props.dealId,
    input: {
      accountID: props.accountId,
    },
  });

  if (dealActivityQuery.error) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <p className="text-gray-500 font-semibold">Something went wrong</p>
      </div>
    );
  }

  if (dealActivityQuery.isLoading || !dealActivityQuery.data) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <ActivityItems items={dealActivityQuery.data.dealActivity.activity} />
    </div>
  );
}

function RemoveDealAccount(props: {
  dealAccount: DealFirmQuery["dealFirm"]["dealAccounts"][0];
  dealFirmId: string;
  dealFirmGroupId: string;
  open: boolean;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const removeDealAccount = useRemoveDealAccountMutation(client);

  return (
    <AnimatedModal open={props.open} onClose={props.onClose}>
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-semibold text-gray-800">
          Remove {props.dealAccount.account.name} from the deal
        </h2>
        <CloseIcon onClose={props.onClose} />
      </div>
      <p className="text-sm text-gray-600 mt-2">
        Are you sure you want to remove this user from the deal?
      </p>
      <div className="flex justify-end mt-4">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onClose();
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            removeDealAccount.mutate(
              {
                id: props.dealAccount.id,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["DealFirm", { id: props.dealFirmId }],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["DealFirmGroup", { id: props.dealFirmGroupId }],
                  });
                  props.onClose();
                },
              }
            );
          }}
        >
          Remove
        </button>
      </div>
    </AnimatedModal>
  );
}

function FolderContent(props: {
  folder: FolderWrapper;
  searchTerm: string;
  depth: number;
}) {
  const [showChildren, setShowChildren] = useState(true);

  if (props.searchTerm.length > 2) {
    if (
      !props.folder.folder.name
        .toLowerCase()
        .includes(props.searchTerm.toLowerCase()) &&
      props.folder.files.filter((file) =>
        file.name.toLowerCase().includes(props.searchTerm.toLowerCase())
      ).length === 0
    ) {
      return null;
    }
  }

  return (
    <div className="">
      <div
        onClick={() => {
          setShowChildren(!showChildren);
        }}
        className={classNames(
          "items-center  hover:bg-gray-200/70 px-2 flex py-1 justify-between cursor-pointer"
        )}
        style={{ paddingLeft: `${props.depth * 0.8}rem` }}
      >
        <div className="flex items-center gap-x-1">
          {showChildren ? (
            <ChevronDownIcon className="w-4 h-4" />
          ) : (
            <ChevronRightIcon className="w-4 h-4" />
          )}
          <FolderIcon className="w-5 h-5 text-blue-700/70" />
          <p className="font-semibold mt-1 ml-1 select-none text-gray-800 text-sm">
            {props.folder.folder.name ? props.folder.folder.name : "Home"}
          </p>
        </div>
      </div>
      {showChildren ? (
        <div className="">
          <div>
            {props.folder.folders.map((folder) => (
              <FolderContent
                folder={folder}
                searchTerm={props.searchTerm}
                depth={props.depth + 1}
                key={folder.folder.id}
              />
            ))}
          </div>
          <div>
            {props.folder.files
              .filter((file) =>
                props.searchTerm.length > 2
                  ? file.name
                      .toLowerCase()
                      .includes(props.searchTerm.toLowerCase())
                  : true
              )
              .map((file) => {
                return (
                  <div
                    key={file.id}
                    className={`cursor-pointer pl-6 pr-3 justify-between hover:bg-gray-200/70  items-center flex  py-2`}
                    style={{
                      paddingLeft: `${(props.depth + 1) * 0.8}rem`,
                    }}
                  >
                    <div className="ml-2 flex items-center">
                      <FileIcon fileType={file.fileType} />
                      <p className="ml-1 select-none font-semibold text-sm text-gray-600">
                        {file.name}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function AccessStatusButton(props: {
  dealAccount: DealAccountQuery["dealAccount"];
}) {
  const dealAccount = props.dealAccount;
  const dealGroup = dealAccount.dealGroup;
  const client = useGqlClient();
  const updateDealAccount = useUpdateDealAccountMutation(client);
  const queryClient = useQueryClient();

  function updateDealAccountFn(status: DealAccessStatus) {
    updateDealAccount.mutate(
      {
        input: {
          id: dealAccount.id,
          dealAccessStatus: status,
          overrideDataRoomAccess: dealAccount.overrideDataRoomAccess,
          filePermissions: dealAccount.granularAccess?.files.map((f) => ({
            fileId: f.id,
            type: f.type,
          })),
          folderPermissions: dealAccount.granularAccess?.folders.map((f) => ({
            folderId: f.id,
            type: f.type,
          })),
          role: dealAccount.role,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["DealAccount", { id: dealAccount.id }],
          });
          toasts.success("Account access updated");
        },
        onError: () => {
          toasts.error("Failed to update account access");
        },
      }
    );
  }

  if (
    dealAccount.dealAccessStatus === DealAccessStatus.Blocked ||
    (dealGroup && dealGroup.dealAccessStatus === DealAccessStatus.Blocked)
  ) {
    return (
      <Button
        text="Unblock account"
        variant="green"
        size="s"
        icon={CheckCircleIcon}
        onClick={() => {
          updateDealAccountFn(DealAccessStatus.Granted);
        }}
      />
    );
  }

  return (
    <Button
      text="Block account"
      variant="danger"
      size="s"
      icon={CircleMinusIcon}
      onClick={() => {
        updateDealAccountFn(DealAccessStatus.Blocked);
      }}
    />
  );
}
