export const MOBILE_NAV_BREAKPOINT = 800;
export const LG_BREAKPOINT = 1024;

interface Config {
  apiEndpoint: string;
  openAiToken: string;
}

let config: Config = {
  apiEndpoint: "",
  openAiToken: "",
};

if (process.env.NODE_ENV === "production") {
}

export default config;
