export function apiEndpoint() {
  let endpoint = "http://localhost:8080/graphql";

  if (process.env.NODE_ENV === "production") {
    if (window.location.hostname === "uk.liquidacquire.com") {
      endpoint = "https://uk.api.liquidacquire.com/graphql";
    }

    if (window.location.hostname === "us.liquidacquire.com") {
      endpoint = "https://us.api.liquidacquire.com/graphql";
    }
  }

  return endpoint;
}

export function wsEndpoint() {
  let endpoint = "ws://localhost:8080/ws";

  if (process.env.NODE_ENV === "production") {
    if (window.location.hostname === "uk.liquidacquire.com") {
      endpoint = "wss://uk.api.liquidacquire.com/ws";
    }

    if (window.location.hostname === "us.liquidacquire.com") {
      endpoint = "wss://us.api.liquidacquire.com/ws";
    }
  }

  return endpoint;
}

export function pspdfkitEndpoint() {
  let endpoint = "http://localhost:5001/";

  if (process.env.NODE_ENV === "production") {
    if (window.location.hostname === "uk.liquidacquire.com") {
      endpoint = "";
    }

    if (window.location.hostname === "us.liquidacquire.com") {
      endpoint = "https://us.documentengine.liquidacquire.com/";
    }
  }

  return endpoint;
}
