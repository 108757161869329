import { useDataRoomFileQuery } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { AnimatedModal } from "../AnimatedModal";
import { CloseIcon } from "../CloseIcon";
import { FileIcon } from "../FileIcon";
import { H4 } from "../Heading";
import Loading from "../Loading";

export function DetailedFileHistoryModal(props: {
  dataRoomFileId: string;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <AnimatedModal size="full" open={props.open} onClose={props.onClose}>
      <DetailedFileHistoryInner
        dataRoomFileId={props.dataRoomFileId}
        onClose={props.onClose}
      />
    </AnimatedModal>
  );
}

function DetailedFileHistoryInner(props: {
  dataRoomFileId: string;
  onClose: () => void;
}) {
  const client = useGqlClient();
  const dataRoomFileQuery = useDataRoomFileQuery(client, {
    id: props.dataRoomFileId,
  });

  if (dataRoomFileQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (dataRoomFileQuery.isPending || !dataRoomFileQuery.data) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-1.5">
          <FileIcon
            fileType={dataRoomFileQuery.data.dataRoomFile.fileType}
            size="l"
          />
          <div>
            <H4>{dataRoomFileQuery.data.dataRoomFile.name} history</H4>
            <p className="leading-tight text-sm text-gray-500/80">
              {dataRoomFileQuery.data.dataRoomFile.versions.length} versions
            </p>
          </div>
        </div>
        <CloseIcon onClose={props.onClose} />
      </div>
    </div>
  );
}
