import { Account, AccountFragmentFragment } from "../../graphql/generated";

const sizesMap = {
  xs: "w-4 h-4",
  s: "w-5 h-5",
  m: "w-6 h-6",
  l: "w-8 h-8",
  xl: "w-10 h-10",
  xxl: "w-12 h-12",
};

const textSizeMap = {
  xs: "text-xs",
  s: "text-xs",
  m: "text-xs",
  l: "text-sm",
  xl: "text-lg",
  xxl: "text-xl",
};

export function Avatar(props: {
  account:
    | Partial<Account>
    | AccountFragmentFragment
    | Pick<Account, "avatar" | "avatarColor" | "name">;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) {
  if (props.account.avatar) {
    return (
      <img
        src={props.account.avatar}
        alt="avatar"
        className={`relative  ring-2  ring-white rounded-full ${
          sizesMap[props.size ? props.size : "m"]
        }`}
      />
    );
  }

  return (
    <div
      style={{
        backgroundColor: props.account.avatarColor
          ? props.account.avatarColor
          : "white",
      }}
      className={`relative ring-2 ring-white  text-center rounded-full items-center justify-center flex ${
        sizesMap[props.size ? props.size : "m"]
      }`}
    >
      <p
        className={`text-white text-center font-semibold ${
          textSizeMap[props.size ? props.size : "m"]
        }`}
      >
        {props.account.name ? props.account.name[0] : ""}
      </p>
    </div>
  );
}
