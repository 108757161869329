import React, { createContext, useContext, useEffect, useState } from "react";
import { wsEndpoint } from "../utils/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import useWebSockets from "react-use-websocket";
import { useQueryClient } from "@tanstack/react-query";
import { toasts } from "../components/toasts/toasts";
import { useInvalidateQueryKeys } from "../hooks/useInvalidateQueryKeys";
import { DealFirmGroupPresence } from "../types";
import { actions } from "../store/presence/slice";
import { useAuth } from "@clerk/clerk-react";
import { DealExportStatus } from "../graphql/generated";

// Define a type for the WebSocket context value
interface WebSocketContextType {
  sendMessage: (message: string) => void;
  subscribeToTopic: (topic: string) => void;
  lastMessage: MessageEvent<any> | null;
  unsubscribeFromTopic: (topic: string) => void;
}

// Create the context with an initial undefined value
const WebSocketContext = createContext<WebSocketContextType | null>(null);

// Custom hook to use the WebSocket context
export const useWebSocket = () =>
  useContext(WebSocketContext) as WebSocketContextType;

// Props type for the provider
interface WebSocketProviderProps {
  children: React.ReactNode;
}

export enum WebsocketMessageType {
  DataRoomFileError = "data_room_file_error",
  DataRoomFileErrorUpdated = "data_room_file_error_updated",
  DataRoomFileErrorDismissed = "data_room_file_error_dismissed",
  DataRoomFileCreated = "data_room_file_created",
  DataRoomFileUpdated = "data_room_file_updated",
  DataRoomFileVersionCreated = "data_room_file_version_created",
  DataRoomFileVersionUpdated = "data_room_file_version_updated",

  DealThreadMessageCreated = "deal_thread_message_created",

  DdqlImportComplete = "ddql_import_complete",

  DealFirmGroupPresence = "deal_firm_group_presence",

  DealExportUpdated = "deal_export_updated",
}

export function WebSocketProvider(props: WebSocketProviderProps) {
  const account = useSelector(authSelectors.account);
  const activeDealId = useSelector(authSelectors.activeDealId);
  const dispatch = useDispatch();

  const { getToken, isLoaded, isSignedIn } = useAuth();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    async function fetchToken() {
      if (!isLoaded || !isSignedIn) {
        setToken(null);
        return;
      }
      const token = await getToken();
      setToken(token);
    }

    fetchToken();
  }, [isLoaded, isSignedIn]);

  const { invalidateDealActivities, invalidateDataRoomFolders } =
    useInvalidateQueryKeys();

  const queryClient = useQueryClient();

  const { sendJsonMessage, lastMessage } = useWebSockets(
    wsEndpoint(),
    {
      share: true,
      shouldReconnect: () => !!token,
      queryParams: { token: token ?? "" },
      onClose: (e) => console.log("WebSocket closed:", e),
      onOpen: (e) => {
        console.log("WebSocket opened:", e);
        subscribeToDeals();
      },
      onMessage: (e) => handleMessage(e.data),
      onError: (e) => console.error("WebSocket error:", e),
    },
    !!token
  );

  function subscribeToDeals() {
    if (!account || !account.deals) {
      return;
    }

    for (const d of account.deals) {
      subscribeToTopic(`deal:${d.id}`);
    }
  }

  const sendMessage = (message: any) => {
    sendJsonMessage(message);
  };

  const subscribeToTopic = (topic: string) => {
    sendJsonMessage({ action: "subscribe", topic: topic });
  };

  const unsubscribeFromTopic = (topic: string) => {
    sendJsonMessage({ action: "unsubscribe", topic: topic });
  };

  const handleMessage = (message: string) => {
    console.log("Received message on topic:", message);
    try {
      const messageData = JSON.parse(message) as WebsocketMessage;
      console.log("Type: ", messageData.type);
      switch (messageData.type) {
        case WebsocketMessageType.DataRoomFileError:
          handleDataRoomFileError(messageData.data);
          break;
        case WebsocketMessageType.DataRoomFileErrorUpdated:
          handleDataRoomFileErrorUpdated(messageData.data);
          break;
        case WebsocketMessageType.DataRoomFileCreated:
          handleDataRoomFileCreated(messageData.data);
          break;
        case WebsocketMessageType.DataRoomFileUpdated:
          handleDataRoomFileUpdated(messageData.data);
          break;
        case WebsocketMessageType.DataRoomFileVersionCreated:
          handleDataRoomFileVersionCreated(messageData.data);
          break;
        case WebsocketMessageType.DataRoomFileVersionUpdated:
          handleDataRoomFileVersionUpdated(messageData.data);
          break;
        case WebsocketMessageType.DataRoomFileErrorDismissed:
          handleDataRoomFileErrorDismissed(messageData.data);
          break;
        case WebsocketMessageType.DealThreadMessageCreated:
          handleDealThreadMessageCreated(messageData.data);
          break;
        case WebsocketMessageType.DdqlImportComplete:
          handleDdqlImportComplete(messageData.data);
          break;
        case WebsocketMessageType.DealFirmGroupPresence:
          handleDealFirmGroupPresence(messageData.data);
          break;
        case WebsocketMessageType.DealExportUpdated:
          handleDealExportUpdated(messageData.data);
          break;
        default:
          console.error("Unknown message type:", messageData);
      }
    } catch (e) {
      console.error("Failed to parse message:", e, message);
    }
  };

  function handleDealFirmGroupPresence(message: DealFirmGroupPresenceData) {
    dispatch(actions.updateDealFirmGroupPresence(message.dealFirmGroup));
  }

  function handleDdqlImportComplete(message: DdqlImportCompleteData) {
    queryClient.invalidateQueries({
      queryKey: [
        "DealFirmGroupQuestions",
        {
          id: message.dealFirmGroupID,
        },
      ],
    });

    queryClient.invalidateQueries({
      queryKey: [
        "Ddql",
        {
          id: message.ddqlID,
        },
      ],
    });

    toasts.success("DDQL import complete");
  }

  function handleDealThreadMessageCreated(
    message: DealThreadMessageCreatedData
  ) {
    queryClient.invalidateQueries({
      queryKey: ["ActiveDealAccount", { id: activeDealId }],
    });
  }

  function handleDataRoomFileError(message: DataRoomFileErrorData) {
    invalidateDealActivities();

    queryClient.invalidateQueries({
      queryKey: ["DataRoomFileError", { id: message.dataRoomFileErrorID }],
    });
    toasts.error(message.message);
  }

  function handleDataRoomFileErrorUpdated(
    message: DataRoomFileErrorUpdatedData
  ) {
    invalidateDealActivities();

    queryClient.invalidateQueries({
      queryKey: ["DataRoomFileError", { id: message.dataRoomFileErrorID }],
    });
  }

  function handleDataRoomFileErrorDismissed(
    message: DataRoomFileErrorDismissedData
  ) {
    invalidateDealActivities();

    queryClient.invalidateQueries({
      queryKey: ["DataRoomFileErrors", { dealId: activeDealId }],
    });

    queryClient.invalidateQueries({
      queryKey: ["DataRoomFileError", { id: message.dataRoomFileErrorID }],
    });
  }

  function handleDealExportUpdated(message: DealExportUpdatedData) {
    queryClient.invalidateQueries({
      queryKey: ["DealExports", { dealId: message.dealId }],
    });

    if (message.status === DealExportStatus.Complete) {
      toasts.success("Deal export complete");
    }
  }

  function handleDataRoomFileCreated(message: DataRoomFileCreatedData) {
    invalidateDealActivities();
    invalidateDataRoomFolders();

    queryClient.invalidateQueries({
      queryKey: ["DataRoomFile", { id: message.dataRoomFileID }],
    });

    queryClient.invalidateQueries({
      queryKey: ["FileDownloadUrl", { id: message.dataRoomFileID }],
    });
  }

  function handleDataRoomFileUpdated(message: DataRoomFileUpdatedData) {
    invalidateDealActivities();
    invalidateDataRoomFolders();

    queryClient.invalidateQueries({
      queryKey: ["DataRoomFile", { id: message.dataRoomFileID }],
    });

    queryClient.invalidateQueries({
      queryKey: ["FileDownloadUrl", { id: message.dataRoomFileID }],
    });

    queryClient.invalidateQueries({
      queryKey: ["DataRoomFileView", { id: message.dataRoomFileID }],
    });
  }

  function handleDataRoomFileVersionUpdated(
    message: DataRoomFileVersionCreated
  ) {
    invalidateDataRoomFolders();

    queryClient.invalidateQueries({
      queryKey: ["DataRoomFile", { id: message.dataRoomFileID }],
    });

    const errorCheckQueries = queryClient
      .getQueryCache()
      .findAll({ queryKey: ["ErrorCheck"] });
    for (const query of errorCheckQueries) {
      queryClient.invalidateQueries({ queryKey: [query.queryKey] });
    }
  }

  function handleDataRoomFileVersionCreated(
    message: DataRoomFileVersionCreated
  ) {
    invalidateDealActivities();

    queryClient.invalidateQueries({
      queryKey: ["DataRoomFile", { id: message.dataRoomFileID }],
    });

    queryClient.invalidateQueries({
      queryKey: ["FileDownloadUrl", { id: message.dataRoomFileID }],
    });
  }

  return (
    <WebSocketContext.Provider
      value={{
        sendMessage,
        subscribeToTopic,
        unsubscribeFromTopic,
        lastMessage,
      }}
    >
      {props.children}
    </WebSocketContext.Provider>
  );
}

interface DealFirmGroupPresenceData {
  dealFirmGroup: DealFirmGroupPresence;
}

interface DealExportUpdatedData {
  dealExportId: string;
  dealId: string;
  status: DealExportStatus;
}

interface DdqlImportCompleteData {
  dealFirmGroupID: string;
  ddqlID: string;
}

interface DealThreadMessageCreatedData {
  dealThreadID: string;
  dealThreadMessageID: string;
}

interface DataRoomFileErrorData {
  dataRoomFileErrorID: string;
  dataRoomFileID: string;
  message: string;
}

interface DataRoomFileErrorUpdatedData {
  dataRoomFileErrorID: string;
}

interface DataRoomFileErrorDismissedData {
  dataRoomFileErrorID: string;
  dismissalReason: string;
}

interface DataRoomFileCreatedData {
  dataRoomFileID: string;
}

interface DataRoomFileUpdatedData {
  dataRoomFileID: string;
}

interface DataRoomFileVersionCreated {
  dataRoomFileVersionID: string;
  dataRoomFileID: string;
}

interface BaseWebsocketMessage {
  type: WebsocketMessageType;
  data: object;
}

interface DataRoomFileErrorMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DataRoomFileError;
  data: DataRoomFileErrorData;
}

interface DataRoomFileErrorUpdatedMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DataRoomFileErrorUpdated;
  data: DataRoomFileErrorUpdatedData;
}

interface DataRoomFileCreatedMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DataRoomFileCreated;
  data: DataRoomFileCreatedData;
}

interface DataRoomFileUpdatedMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DataRoomFileUpdated;
  data: DataRoomFileUpdatedData;
}

interface DataRoomFileVersionCreatedMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DataRoomFileVersionCreated;
  data: DataRoomFileVersionCreated;
}

interface DataRoomFileVersionUpdatedMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DataRoomFileVersionUpdated;
  data: DataRoomFileVersionCreated;
}

interface DataRoomFileErrorDismissedMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DataRoomFileErrorDismissed;
  data: DataRoomFileErrorDismissedData;
}

interface DealThreadMessageCreatedMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DealThreadMessageCreated;
  data: DealThreadMessageCreatedData;
}

interface DdqlImportCompleteMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DdqlImportComplete;
  data: DdqlImportCompleteData;
}

interface DealFirmGroupPresenceMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DealFirmGroupPresence;
  data: DealFirmGroupPresenceData;
}

interface DealExportUpdatedMessage extends BaseWebsocketMessage {
  type: WebsocketMessageType.DealExportUpdated;
  data: DealExportUpdatedData;
}

export function DataRoomFileTopic(dataRoomFileId: string): string {
  return `data_room_file:${dataRoomFileId}`;
}

export type WebsocketMessage =
  | DataRoomFileErrorMessage
  | DataRoomFileErrorUpdatedMessage
  | DataRoomFileCreatedMessage
  | DataRoomFileErrorDismissedMessage
  | DataRoomFileVersionCreatedMessage
  | DealThreadMessageCreatedMessage
  | DataRoomFileVersionUpdatedMessage
  | DdqlImportCompleteMessage
  | DealFirmGroupPresenceMessage
  | DealExportUpdatedMessage
  | DataRoomFileUpdatedMessage;
