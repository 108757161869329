import { NavLink, useHistory, useParams } from "react-router-dom";
import {
  ChevronDownIcon,
  DocumentArrowUpIcon,
  DocumentMagnifyingGlassIcon,
  DocumentPlusIcon,
  FolderIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Button } from "../../../components/tailwind/Button";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "../../../store/auth/selector";
import useGqlClient from "../../../hooks/useGqlClient";
import {
  AllDataRoomFoldersQuery,
  DataRoomFileFragmentFragment,
  DataRoomFileQuery,
  ErrorCheckFileInput,
  ErrorCheckQuery,
  ErrorCheckingStatus,
  ErrorChecksQuery,
  FileVersionTextChange,
  useAllDataRoomFoldersQuery,
  useCreateErrorCheckMutation,
  useDataRoomFileQuery,
  useErrorCheckQuery,
  useErrorChecksQuery,
  useUpdateErrorCheckMutation,
} from "../../../graphql/generated";
import { UseQueryResult, useQueryClient } from "@tanstack/react-query";
import Loading from "../../../components/Loading";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { toasts } from "../../../components/toasts/toasts";
import { classNames } from "../../../utils/cn";
import { Fragment, useEffect, useState } from "react";
import { FileIcon } from "../../../components/FileIcon";
import { Pills } from "../../../components/Pills";
import { FilePill } from "../../../components/FilePill";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  formatDistance,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  formatISO,
  fromUnixTime,
  secondsToMilliseconds,
} from "date-fns";
import { Pulse } from "../../../components/Pulse";
import { formatCurrency } from "../../../utils/formatCurrency";
import { EditableText } from "../../../components/EditableText";
import { ActivityItems } from "../../../components/activity/ActivityItems";
import Dropdown, { Option } from "../../../components/tailwind/Dropdown";
import { TextDiffs } from "../../../components/tailwind/TextDiffs";
import { NewVersionUploader } from "../../../components/data_room/file_uploaders/NewVersionUploader";
import { Spinner } from "../../../components/icons/Spinner";
import { AnimatedModal } from "../../../components/AnimatedModal";
import { CloseIcon } from "../../../components/CloseIcon";

export function ErrorChecking() {
  const { id } = useParams<{ id: string }>();
  return (
    <div className="flex-1 flex flex-col ">
      <div className="flex-1 flex flex-col h-full w-full ">
        <div className="lg:hidden flex  items-center justify-center flex-1">
          <p className="font-semibold text-gray-600">Not supported on mobile</p>
        </div>
        <div className="hidden lg:flex flex-1 ">
          <div className="flex flex-1 relative ">
            <Sidebar />
            <ErrorCheckingContent id={id} />
          </div>
        </div>
      </div>
    </div>
  );
}

function ErrorCheckingContent(props: { id: string | undefined }) {
  if (!props.id) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <p className="text-xs text-gray-500">No check selected</p>
      </div>
    );
  }
  return <ErrorCheckingContentInner id={props.id} />;
}

function ErrorCheckingContentInner(props: { id: string }) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const errorCheck = useErrorCheckQuery(client, { id: props.id });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const updateErrorCheck = useUpdateErrorCheckMutation(client);
  const [showFileDetails, setShowFileDetails] = useState(false);
  const [showAddFiles, setShowAddFiles] = useState(false);

  if (errorCheck.error) {
    return (
      <div className="flex-1 flex items-center justify-center h-full">
        <p className="text-xs text-gray-500">Something went wrong</p>
      </div>
    );
  }

  if (errorCheck.isPending || !errorCheck.data) {
    return (
      <div className="flex-1 flex items-center justify-center h-full">
        <Loading />
      </div>
    );
  }

  if (errorCheck.data.errorCheck.files.length === 0) {
    return <AddFiles errorCheckId={props.id} dealId={activeDealId ?? ""} />;
  }

  return (
    <div className="w-full">
      <div className="w-full px-3 justify-between h-12 bg-white flex items-center border-b border-gray-200">
        {/* <p className="font-semibold text-sm text-gray-700">
          {errorCheck.data.errorCheck.name
            ? errorCheck.data.errorCheck.name
            : "New error check"}
        </p> */}
        <EditableText
          initialText={
            errorCheck.data.errorCheck.name
              ? errorCheck.data.errorCheck.name
              : "New error check"
          }
          onSave={(text) => {
            const fileUpdate: ErrorCheckFileInput[] =
              errorCheck.data.errorCheck.files.map((f) => {
                return {
                  dataRoomFileID: f.file.id,
                  dataRoomFileVersionID: f.currentVersion.id,
                };
              });

            updateErrorCheck.mutate(
              {
                input: {
                  id: props.id,
                  name: text,
                  files: fileUpdate,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["ErrorCheck", { id: props.id }],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["ErrorChecks", { dealId: activeDealId ?? "" }],
                  });
                },
                onError: () => {
                  toasts.error("Failed to update error check name");
                },
              }
            );
          }}
          onCancel={() => {}}
        />
        <div className="w-1/3">
          <input
            className="h-8 hidden xl:flex cursor-text items-center w-full border-0 bg-gray-100 rounded-md shadow-sm pl-2 pr-3 text-gray-900 placeholder:text-gray-400  sm:text-sm focus:outline-none"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </div>
        <div className="flex items-center gap-x-2">
          <Button
            size="s"
            variant="neutral"
            text="Add files"
            icon={DocumentPlusIcon}
            onClick={() => {
              setShowAddFiles(true);
            }}
          />
          <Button
            size="s"
            variant="neutral"
            text="File details"
            icon={DocumentMagnifyingGlassIcon}
            onClick={() => {
              setShowFileDetails(true);
            }}
          />
        </div>
      </div>
      <div className="w-full  flex-1 flex ">
        <ErrorCheckTable
          data={errorCheck.data.errorCheck}
          searchTerm={searchTerm}
        />
      </div>
      <SlideOver
        open={showFileDetails}
        onClose={() => setShowFileDetails(false)}
        errorCheck={errorCheck.data.errorCheck}
      />
      <AddFilesModal
        open={showAddFiles}
        onClose={() => setShowAddFiles(false)}
        dealId={activeDealId ?? ""}
        errorCheckId={props.id}
      />
    </div>
  );
}

function Sidebar() {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const createErrorCheck = useCreateErrorCheckMutation(client);

  function createErrorCheckFn() {
    if (!activeDealId) return;
    createErrorCheck.mutate(
      {
        input: {
          dataRoomFileIDs: [],
          dealID: activeDealId ?? "",
        },
      },
      {
        onSuccess: (dat) => {
          queryClient.invalidateQueries({
            queryKey: ["ErrorChecks", { dealId: activeDealId ?? "" }],
          });
          history.push(`/toolkit/error-checking/${dat.createErrorCheck.id}`);
        },
        onError: () => {
          toasts.error("Failed to create error check");
        },
      }
    );
  }

  const errorChecks = useErrorChecksQuery(client, {
    dealId: activeDealId ?? "",
  });

  useEffect(() => {
    if (id) {
      return;
    }

    if (errorChecks.data && errorChecks.data.errorChecks.length > 0) {
      history.push(
        `/toolkit/error-checking/${errorChecks.data.errorChecks[0].id}`
      );
    }
  }, [errorChecks.data, id]);

  return (
    <div className="min-w-80 bg-white border-r border-gray-200 border-b">
      <div className="flex justify-between items-center p-3 py-2.5 ">
        <p className="font-semibold text-sm text-gray-600">Checks</p>
        <button
          onClick={() => {
            createErrorCheckFn();
          }}
          className="flex items-center p-0.5 border rounded-md border-gray-300 hover:border-gray-500 hover:bg-gray-50"
        >
          <PlusIcon className="h-5 w-5 " />
        </button>
      </div>
      <SidebarList
        query={errorChecks}
        onCreate={() => {
          createErrorCheckFn();
        }}
      />
    </div>
  );
}

function SidebarList(props: {
  query: UseQueryResult<ErrorChecksQuery, unknown>;
  onCreate: () => void;
}) {
  if (props.query.error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-xs text-gray-500">Something went wrong</p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loading />
      </div>
    );
  }

  if (props.query.data.errorChecks.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <div>
          <p className=" text-center text-sm text-gray-500">No error checks</p>
          <Button
            onClick={props.onCreate}
            margin="s 0 0 0"
            icon={PlusIcon}
            variant="neutral"
            text="New check"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "calc(100vh)",
      }}
      className="overflow-y-scroll "
    >
      <TransitionGroup>
        {props.query.data.errorChecks.map((check) => {
          return (
            <CSSTransition
              key={check.id}
              timeout={300} // This controls the duration of the animation
              classNames="fade-slide-down"
              onEnter={(node: any) => node.offsetHeight} // Trigger reflow to enable animation
            >
              <NavLink
                key={check.id}
                to={`/toolkit/error-checking/${check.id}`}
                className={(isActive) => {
                  return classNames(
                    "px-3 py-3 hover:bg-gray-100 font-semibold text-gray-600 flex items-center"
                  );
                }}
                activeClassName="bg-gray-100 text-gray-500"
              >
                <div className="cursor-pointer ">
                  <p className="text-sm text-gray-500 font-semibold">
                    {check.name ? check.name : "New error check"}
                  </p>
                  <p className="text-xs text-gray-500 font-light">
                    {formatDistanceToNowStrict(fromUnixTime(check.createdAt), {
                      addSuffix: true,
                    })}
                  </p>
                  <div className="overflow-x-scroll flex flex-wrap gap-2 mt-2 w-72 scrollbar-none">
                    {check.files.map((file) => {
                      return (
                        <FilePill
                          key={file.file.id}
                          id={file.file.id}
                          name={file.file.name}
                          type={file.file.fileType}
                          showDetailsCard={false}
                        />
                      );
                    })}
                  </div>
                </div>
              </NavLink>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
}

function ErrorCheckTable(props: {
  data: ErrorCheckQuery["errorCheck"];
  searchTerm: string;
}) {
  const files = props.data.files;
  const rows = props.data.rows;

  const client = useGqlClient();
  const updateErrorCheck = useUpdateErrorCheckMutation(client);
  const queryClient = useQueryClient();

  return (
    <div
      style={{
        width: 300,
        height: "calc(100vh)",
      }}
      className="flex flex-1 overflow-x-auto overflow-y-auto"
    >
      <div className=" ">
        <table className="table divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className=" px-6 py-3 text-left text-xs font-medium text-gray-500"
              >
                Key
              </th>
              {files.map((file) => (
                <th
                  key={file.file.id}
                  scope="col"
                  className=" text-left text-xs font-medium text-gray-500 "
                >
                  <TableHeaderDropdown
                    selectedVersionId={file.currentVersion.id}
                    latestVersionId={file.file.versions[0].id}
                    file={file}
                    onVersionSelect={(versionId) => {
                      if (updateErrorCheck.isPending) {
                        return;
                      }

                      const fileUpdate: ErrorCheckFileInput[] = files.map(
                        (f) => {
                          if (f.file.id === file.file.id) {
                            return {
                              dataRoomFileID: f.file.id,
                              dataRoomFileVersionID: versionId,
                            };
                          }
                          return {
                            dataRoomFileID: f.file.id,
                            dataRoomFileVersionID: f.currentVersion.id,
                          };
                        }
                      );

                      updateErrorCheck.mutate(
                        {
                          input: {
                            id: props.data.id,
                            files: fileUpdate,
                          },
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries({
                              queryKey: ["ErrorCheck", { id: props.data.id }],
                            });
                          },
                          onError: () => {
                            toasts.error("Failed to update file version");
                          },
                        }
                      );
                    }}
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rows.map((row, rowIndex) => {
              if (
                props.searchTerm.length > 0 &&
                !row.key.toLowerCase().includes(props.searchTerm.toLowerCase())
              ) {
                return null;
              }

              return (
                <tr key={rowIndex}>
                  <td
                    title={row.key}
                    className="border-r border-gray-200  px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  >
                    {row.key.length > 40
                      ? `${row.key.slice(0, 40)}...`
                      : row.key}
                  </td>
                  {files.map((file, colIndex) => {
                    const valueObject = row.values.find(
                      (value) => value.dataRoomFileId === file.file.id
                    );

                    return (
                      <td
                        key={colIndex}
                        className={`border-r border-gray-200 font-semibold text-right px-2 py-4 whitespace-nowrap text-sm ${
                          row.hasDiscrepancy
                            ? "text-orange-500"
                            : "text-gray-500"
                        }`}
                      >
                        {valueObject ? formatCurrency(valueObject.value) : "-"}
                        <p
                          className="text-xs text-gray-500 font-light"
                          title={valueObject?.key}
                        >
                          {valueObject
                            ? valueObject.key.length > 35
                              ? `${valueObject.key.slice(0, 35)}...`
                              : valueObject.key
                            : "-"}
                        </p>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function AddFilesModal(props: {
  dealId: string;
  errorCheckId: string;
  open: boolean;
  onClose: () => void;
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const allFoldersQuery = useAllDataRoomFoldersQuery(client, {
    dealId: props.dealId,
  });

  const errorCheck = useErrorCheckQuery(client, { id: props.errorCheckId });
  const updateErrorCheck = useUpdateErrorCheckMutation(client);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedFiles, setSelectedFiles] = useState<SelectedFiles>({
    files: {},
  });

  useEffect(() => {
    if (!allFoldersQuery.data || !errorCheck.data || !props.open) {
      return;
    }

    const selectedFiles: SelectedFiles = {
      files: {},
    };

    allFoldersQuery.data.deal.dataRoom.allFolders.forEach((folder) => {
      folder.files.forEach((file) => {
        selectedFiles.files[file.id] = {
          selected: errorCheck.data.errorCheck.files.find(
            (f) => f.file.id === file.id
          )
            ? true
            : false,
          file: file,
        };
      });
    });

    setSelectedFiles(selectedFiles);
  }, [allFoldersQuery.data, errorCheck.data, props.open]);

  function handleFileToggle(fileId: string, selected: boolean) {
    const newSelectedFiles = { ...selectedFiles };

    newSelectedFiles.files[fileId].selected = selected;
    setSelectedFiles(newSelectedFiles);
  }

  return (
    <AnimatedModal open={props.open} onClose={props.onClose} size="xl">
      <div>
        <div className="flex justify-between items-center">
          <div className="justify-start flex flex-col  w-full">
            <p className="font-semibold text-sm text-gray-700">
              Update error check
            </p>
            <p className="text-xs text-gray-500">
              Select all the files you want in this check
            </p>
          </div>
          <CloseIcon
            onClose={() => {
              props.onClose();
              setSelectedFiles({ files: {} });
            }}
          />
        </div>
        <div className="mt-4 w-full rounded-md border-gray-200 border overflow-y-hidden">
          <div className="w-full">
            <input
              type="text"
              className="px-3 py-1.5 text-sm focus:ring-0 border-b border-x-0 border-t-0 border-gray-200 focus:border-gray-200"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.currentTarget.value);
              }}
            />
          </div>

          <AddFilesContent
            query={allFoldersQuery}
            onFileSelected={(fileId) => {
              handleFileToggle(fileId, !selectedFiles.files[fileId].selected);
            }}
            searchTerm={searchTerm}
            selectedFiles={selectedFiles}
          />
        </div>

        <div className="w-full flex mt-3 justify-between ">
          {Object.keys(selectedFiles.files).filter(
            (fileId) => selectedFiles.files[fileId].selected
          ).length === 0 ? (
            <p className="text-xs text-gray-500">No files selected</p>
          ) : (
            <Pills>
              {Object.keys(selectedFiles.files).map((fileId) => {
                if (!selectedFiles.files[fileId].selected) {
                  return null;
                }
                return (
                  <FilePill
                    key={fileId}
                    id={fileId}
                    name={selectedFiles.files[fileId].file.name}
                    type={selectedFiles.files[fileId].file.fileType}
                  />
                );
              })}
            </Pills>
          )}

          <div className=" justify-end flex-shrink-0 items-start">
            <Button
              variant="positive"
              text="Update"
              isLoading={updateErrorCheck.isPending}
              loadingText="Updating..."
              isDisabled={
                Object.keys(selectedFiles.files).filter(
                  (fileId) => selectedFiles.files[fileId].selected
                ).length === 0
              }
              onClick={() => {
                if (
                  Object.keys(selectedFiles.files).filter(
                    (fileId) => selectedFiles.files[fileId].selected
                  ).length === 0
                ) {
                  return;
                }

                if (updateErrorCheck.isPending) {
                  return;
                }

                const files: ErrorCheckFileInput[] = Object.keys(
                  selectedFiles.files
                )
                  .filter((fileId) => selectedFiles.files[fileId].selected)
                  .map((fileId) => {
                    return {
                      dataRoomFileID: selectedFiles.files[fileId].file.id,
                      dataRoomFileVersionID:
                        selectedFiles.files[fileId].file.latestVersion.id,
                    };
                  });
                updateErrorCheck.mutate(
                  {
                    input: {
                      id: props.errorCheckId,
                      files,
                    },
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ["ErrorCheck", { id: props.errorCheckId }],
                      });
                      queryClient.invalidateQueries({
                        queryKey: ["ErrorChecks", { dealId: props.dealId }],
                      });
                      props.onClose();
                      setSelectedFiles({ files: {} });
                    },
                    onError: () => {
                      toasts.error("Failed to add files");
                    },
                  }
                );
              }}
            />
          </div>
        </div>
      </div>
    </AnimatedModal>
  );
}

interface SelectedFiles {
  files: {
    [key: string]: {
      selected: boolean;
      file: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0]["files"][0];
    };
  };
}

function AddFiles(props: { errorCheckId: string; dealId: string }) {
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const allFoldersQuery = useAllDataRoomFoldersQuery(client, {
    dealId: props.dealId,
  });

  const errorCheck = useErrorCheckQuery(client, { id: props.errorCheckId });
  const updateErrorCheck = useUpdateErrorCheckMutation(client);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedFiles, setSelectedFiles] = useState<SelectedFiles>({
    files: {},
  });

  useEffect(() => {
    if (!allFoldersQuery.data || !errorCheck.data) {
      return;
    }

    const selectedFiles: SelectedFiles = {
      files: {},
    };

    allFoldersQuery.data.deal.dataRoom.allFolders.forEach((folder) => {
      folder.files.forEach((file) => {
        selectedFiles.files[file.id] = {
          selected: errorCheck.data.errorCheck.files.find(
            (f) => f.file.id === file.id
          )
            ? true
            : false,
          file: file,
        };
      });
    });

    setSelectedFiles(selectedFiles);
  }, [allFoldersQuery.data, errorCheck.data]);

  function handleFileToggle(fileId: string, selected: boolean) {
    const newSelectedFiles = { ...selectedFiles };

    newSelectedFiles.files[fileId].selected = selected;
    setSelectedFiles(newSelectedFiles);
  }

  return (
    <div className="flex-1 mt-12 flex-col items-center flex">
      <div className="justify-start flex flex-col  w-2/3">
        <p className="font-semibold text-sm text-gray-700">No files</p>
        <p className="text-xs text-gray-500">Select files you want to check</p>
      </div>
      <div className="mt-4 w-2/3 rounded-md border-gray-200 border overflow-y-hidden">
        <div className="w-full">
          <input
            type="text"
            className="px-3 py-1.5 text-sm focus:ring-0 border-b border-x-0 border-t-0 border-gray-200 focus:border-gray-200"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </div>

        <AddFilesContent
          query={allFoldersQuery}
          onFileSelected={(fileId) => {
            handleFileToggle(fileId, !selectedFiles.files[fileId].selected);
          }}
          searchTerm={searchTerm}
          selectedFiles={selectedFiles}
        />
      </div>

      <div className="w-2/3 flex mt-3 justify-between ">
        {Object.keys(selectedFiles.files).filter(
          (fileId) => selectedFiles.files[fileId].selected
        ).length === 0 ? (
          <p className="text-xs text-gray-500">No files selected</p>
        ) : (
          <Pills>
            {Object.keys(selectedFiles.files).map((fileId) => {
              if (!selectedFiles.files[fileId].selected) {
                return null;
              }
              return (
                <FilePill
                  key={fileId}
                  id={fileId}
                  name={selectedFiles.files[fileId].file.name}
                  type={selectedFiles.files[fileId].file.fileType}
                />
              );
            })}
          </Pills>
        )}

        <div className=" justify-end flex-shrink-0 items-start">
          <Button
            variant="positive"
            text="Add files"
            isLoading={updateErrorCheck.isPending}
            loadingText="Adding files..."
            isDisabled={
              Object.keys(selectedFiles.files).filter(
                (fileId) => selectedFiles.files[fileId].selected
              ).length === 0
            }
            onClick={() => {
              if (
                Object.keys(selectedFiles.files).filter(
                  (fileId) => selectedFiles.files[fileId].selected
                ).length === 0
              ) {
                return;
              }

              if (updateErrorCheck.isPending) {
                return;
              }

              const files: ErrorCheckFileInput[] = Object.keys(
                selectedFiles.files
              )
                .filter((fileId) => selectedFiles.files[fileId].selected)
                .map((fileId) => {
                  return {
                    dataRoomFileID: selectedFiles.files[fileId].file.id,
                    dataRoomFileVersionID:
                      selectedFiles.files[fileId].file.latestVersion.id,
                  };
                });
              updateErrorCheck.mutate(
                {
                  input: {
                    id: props.errorCheckId,
                    files,
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: ["ErrorCheck", { id: props.errorCheckId }],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ["ErrorChecks", { dealId: props.dealId }],
                    });
                  },
                  onError: () => {
                    toasts.error("Failed to add files");
                  },
                }
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function AddFilesContent(props: {
  query: UseQueryResult<AllDataRoomFoldersQuery, unknown>;
  onFileSelected: (fileId: string) => void;
  searchTerm: string;
  selectedFiles: SelectedFiles;
}) {
  if (props.query.error) {
    return (
      <div>
        <p className="font-semibold text-gray-700">Something went wrong</p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return <Loading />;
  }

  return (
    <div className="w-full bg-white h-96 overflow-y-scroll">
      {props.query.data.deal.dataRoom.allFolders.map((folder) => {
        return (
          <div key={folder.id}>
            <FolderContent
              folder={folder}
              selectedFiles={props.selectedFiles}
              onToggleFile={(fileId: string) => {
                props.onFileSelected(fileId);
              }}
              searchTerm={props.searchTerm}
            />
          </div>
        );
      })}
    </div>
  );
}

function TableHeaderDropdown(props: {
  file: ErrorCheckQuery["errorCheck"]["files"][0];
  onVersionSelect: (versionId: string) => void;
  latestVersionId: string;
  selectedVersionId: string;
}) {
  const file = props.file;
  const selectedVersion = props.file.file.versions.find(
    (v) => v.id === props.selectedVersionId
  );

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div>
        <Menu.Button
          className={classNames(
            "p-3 py-1.5 inline-flex w-full justify-between items-center text-left gap-x-1.5 border-x bg-white px-3  text-sm ",
            "shadow-sm  ring-inset font-light text-gray-500  outline-none focus:ring-gray-600 hover:bg-gray-50"
          )}
        >
          <div className="flex items-center gap-x-2">
            <FileIcon fileType={file.file.fileType} />
            <div className="flex items-center gap-x-2 justify-between">
              <div>
                <p className="text-gray-500 text-xs font-semibold truncate">
                  {file.file.name}
                </p>
                <p className="leading-tight font-light text-xs text-gray-500 truncate">
                  {`${
                    props.selectedVersionId === props.latestVersionId
                      ? "Latest"
                      : "Old"
                  } \u2022 ${formatDistanceToNowStrict(
                    fromUnixTime(selectedVersion?.createdAt ?? 0),
                    { addSuffix: true }
                  )}`}
                </p>
              </div>
              {file.file.errorCheckingStatus ===
              ErrorCheckingStatus.Checking ? (
                <Spinner size="s" color="gray" />
              ) : (
                <Pulse
                  color={
                    props.latestVersionId === props.selectedVersionId
                      ? "green"
                      : "gray"
                  }
                />
              )}
            </div>
          </div>
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-full z-10 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {props.file.file.versions.map((version) => {
              return (
                <Menu.Item key={version.id}>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-3 py-2 text-sm w-full text-left"
                      )}
                      onClick={() => {
                        props.onVersionSelect(version.id);
                      }}
                    >
                      <div className="flex items-start gap-x-1.5">
                        <Pulse
                          color={
                            version.id === props.latestVersionId
                              ? "green"
                              : "gray"
                          }
                        />
                        <div>
                          <p className="text-xs font-semibold text-gray-600">
                            {version.summary ? version.summary : "No summary"}
                          </p>
                          <p className="text-xs font-light text-gray-500">
                            Uploaded{" "}
                            {formatDistanceToNow(
                              fromUnixTime(version.createdAt),
                              { addSuffix: true }
                            )}
                          </p>
                        </div>
                      </div>
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function FolderContent(props: {
  folder: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0];
  selectedFiles: SelectedFiles;
  onToggleFile: (fileId: string) => void;
  searchTerm: string;
}) {
  const [showChildren, setShowChildren] = useState(true);

  if (props.searchTerm.length > 2) {
    if (
      !props.folder.name
        .toLowerCase()
        .includes(props.searchTerm.toLowerCase()) &&
      props.folder.files.filter((file) =>
        file.name.toLowerCase().includes(props.searchTerm.toLowerCase())
      ).length === 0
    ) {
      return null;
    }
  }

  return (
    <div className="">
      <div
        className={classNames(
          "items-center  hover:bg-gray-200/70 px-2 flex py-1 justify-between cursor-pointer"
        )}
      >
        <div className="flex items-center">
          <FolderIcon className="w-5 h-5 text-blue-700/70" />
          <p className="font-semibold mt-1 ml-1 select-none text-gray-800 text-sm">
            {props.folder.parentFolders.length > 0
              ? `${props.folder.parentFolders
                  .map((f) => (f.name ? f.name : "Home"))
                  .reverse()
                  .join(" / ")}`
              : "Home /"}
          </p>
        </div>
      </div>
      {showChildren ? (
        <div className="">
          <div>
            {props.folder.files
              .filter((file) =>
                props.searchTerm.length > 2
                  ? file.name
                      .toLowerCase()
                      .includes(props.searchTerm.toLowerCase())
                  : true
              )
              .map((file) => {
                const fileSelected = props.selectedFiles.files[file.id]
                  ? props.selectedFiles.files[file.id].selected
                  : false;

                return (
                  <div
                    key={file.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      props.onToggleFile(file.id);
                    }}
                    className={`cursor-pointer px-2 justify-between hover:bg-gray-200/70  items-center flex  py-2 ${
                      !fileSelected ? "opacity-50 hover:opacity-100" : ""
                    }`}
                  >
                    <div className="ml-2 flex items-center">
                      <FileIcon fileType={file.fileType} />
                      <p className="ml-1 select-none font-semibold text-sm text-gray-600">
                        {file.name}
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      className="rounded-md "
                      checked={fileSelected}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function SlideOver(props: {
  onClose: () => void;
  open: boolean;
  errorCheck: ErrorCheckQuery["errorCheck"];
}) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll no-scrollbar bg-white border-b border-gray z-50 pt-6 shadow-xl">
                    <div className="px-4 sm:px-6 shadow pb-4">
                      <div className="flex items-start  justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          File details
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => props.onClose()}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative overflow-y-scroll flex-1">
                      <FileDetailsContent errorCheck={props.errorCheck} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function FileDetailsContent(props: {
  errorCheck: ErrorCheckQuery["errorCheck"];
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);

  useEffect(() => {
    if (props.errorCheck.files.length > 0) {
      setSelectedFileId(props.errorCheck.files[0].file.id);
    }
  }, [props.errorCheck.id]);

  return (
    <div className="flex-1 h-full bg-gray-50">
      <div className="sticky top-0 z-10 bg-white flex overflow-x-scroll scrollbar-none gap-x-2 shadow-lg">
        {props.errorCheck.files.map((file) => {
          return (
            <button
              key={file.file.id}
              className={`bg-white z-20 p-3 py-4 border-b-2 ${
                selectedFileId === file.file.id
                  ? "border-indigo-500 hover:border-indigo-600"
                  : "border-gray-200 hover:border-gray-300"
              }`}
              onClick={() => {
                setSelectedFileId(file.file.id);
              }}
            >
              <div className="flex gap-x-2">
                <FileIcon fileType={file.file.fileType} />
                <p className="truncate text-sm font-semibold text-gray-700">
                  {file.file.name}
                </p>
              </div>
            </button>
          );
        })}
      </div>

      {selectedFileId ? (
        <FileDetails fileId={selectedFileId} errorCheck={props.errorCheck} />
      ) : (
        <div className="flex-1 flex justify-center items-center">
          <p className="text-xs text-gray-500">No file selected</p>
        </div>
      )}
    </div>
  );
}

const fileDetailsTabs = ["Version history", "Recent activity"];

function FileDetails(props: {
  fileId: string;
  errorCheck: ErrorCheckQuery["errorCheck"];
}) {
  const { id } = useParams<{ id: string }>();
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const dataRoomFileQuery = useDataRoomFileQuery(client, { id: props.fileId });
  const updateErrorCheck = useUpdateErrorCheckMutation(client);

  const [selectedTab, setSelectedTab] = useState(fileDetailsTabs[0]);
  const [showFileUploader, setShowFileUploader] = useState(false);

  if (dataRoomFileQuery.error) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <p className="font-semibold text-gray-700">Something went wrong</p>
      </div>
    );
  }

  if (dataRoomFileQuery.isPending || !dataRoomFileQuery.data) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  const file = dataRoomFileQuery.data.dataRoomFile;

  return (
    <div className="p-3 pt-4 bg-gray-50">
      <div className="flex items-center gap-x-2">
        <FileIcon size="l" fileType={file.fileType} />
        <div>
          <p className="font-semibold text-gray-700 text-sm">{file.name}</p>
          <p className="text-xs text-gray-500">
            Created{" "}
            {formatDistanceToNow(fromUnixTime(file.createdAt), {
              addSuffix: true,
            })}
          </p>
        </div>
      </div>

      <div className="mt-3">
        <Button
          icon={DocumentArrowUpIcon}
          variant="neutral"
          text="Upload new version"
          size="s"
          onClick={() => {
            setShowFileUploader(true);
          }}
        />
      </div>

      <div className="mt-5">
        <nav className="flex space-x-4" aria-label="Tabs">
          {fileDetailsTabs.map((tab) => (
            <button
              onClick={() => {
                setSelectedTab(tab);
              }}
              key={tab}
              className={classNames(
                tab === selectedTab
                  ? "bg-gray-100 text-gray-700"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-sm font-medium"
              )}
              aria-current={tab === selectedTab ? "page" : undefined}
            >
              {tab}
            </button>
          ))}
        </nav>

        <div className="mt-3">
          <FileDetailsTabRenderer tab={selectedTab} file={file} />
        </div>
      </div>
      <NewVersionUploader
        open={showFileUploader}
        fileName={dataRoomFileQuery.data.dataRoomFile.name}
        dataRoomFileId={dataRoomFileQuery.data.dataRoomFile.id}
        onNewVersion={(versionId) => {
          const fileUpdate: ErrorCheckFileInput[] = props.errorCheck.files.map(
            (f) => {
              if (f.file.id === props.fileId) {
                return {
                  dataRoomFileID: f.file.id,
                  dataRoomFileVersionID: versionId,
                };
              }
              return {
                dataRoomFileID: f.file.id,
                dataRoomFileVersionID: f.currentVersion.id,
              };
            }
          );

          updateErrorCheck.mutate(
            {
              input: {
                id: id,
                files: fileUpdate,
              },
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: ["ErrorCheck", { id: id }],
                });
              },
              onError: () => {
                toasts.error("Failed to update file version");
              },
            }
          );
        }}
        onClose={() => {
          setShowFileUploader(false);
          queryClient.invalidateQueries({
            queryKey: ["ErrorCheck", { id: id }],
          });
        }}
      />
    </div>
  );
}

function FileDetailsTabRenderer(props: {
  tab: string;
  file: DataRoomFileQuery["dataRoomFile"];
}) {
  switch (props.tab) {
    case "Version history":
      return <FileDetailsVersionHistory file={props.file} />;
    case "Recent activity":
      return <FileDetailsRecentActivity file={props.file} />;
    default:
      return null;
  }
}

function FileDetailsVersionHistory(props: {
  file: DataRoomFileQuery["dataRoomFile"];
}) {
  const versions = props.file.versions;
  return (
    <div className="mt-3">
      <ul role="list" className="mt-3 space-y-6">
        <TransitionGroup className="space-y-6">
          {versions.map((version, i) => {
            return (
              <CSSTransition
                key={version.id}
                timeout={300} // This controls the duration of the animation
                classNames="fade-slide-down"
                onEnter={(node: any) => node.offsetHeight} // Trigger reflow to enable animation
              >
                <li key={version.id}>
                  <Version
                    fileId={props.file.id}
                    version={version}
                    isFirst={i === 0}
                    isLast={i === versions.length - 1}
                  />
                </li>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </ul>
    </div>
  );
}

function FileDetailsRecentActivity(props: {
  file: DataRoomFileQuery["dataRoomFile"];
}) {
  return <ActivityItems items={props.file.activity} />;
}

function Version(props: {
  fileId: string;
  version: DataRoomFileFragmentFragment["versions"][0];
  isLast: boolean;
  isFirst: boolean;
}) {
  const version = props.version;
  const dispatch = useDispatch();

  return (
    <div
      className={classNames(
        "relative flex gap-x-4 transition-all duration-300 ease-in-out"
      )}
    >
      <div
        className={classNames(
          props.isLast ? "h-6" : "-bottom-6",
          "absolute left-0 top-0 flex w-6 justify-center"
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <>
        {props.isFirst ? (
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-concrete-50">
            <div
              className={classNames(
                "bg-green-400/10 text-green-400 animate-pulse",
                "flex-none rounded-full p-1"
              )}
            >
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
          </div>
        ) : (
          <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-concrete-50">
            <div
              className={classNames(
                "h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"
              )}
            />
          </div>
        )}

        <div className="flex-auto rounded-md space-y-2 p-3 pt-1.5 ring-1 ring-inset ring-gray-200">
          <div className="flex justify-between gap-x-4 -py-0.5">
            <p className=" text-xs leading-5 text-gray-500">
              <span className="font-medium text-gray-900">
                {version.createdBy.name}
              </span>{" "}
              uploaded
            </p>
            <time
              title={formatISO(secondsToMilliseconds(version.createdAt))}
              dateTime={formatISO(secondsToMilliseconds(version.createdAt))}
              className="flex-none text-xs leading-5 text-gray-500"
            >
              {formatDistance(
                secondsToMilliseconds(version.createdAt),
                new Date(),
                {
                  addSuffix: true,
                }
              )}
            </time>
          </div>
          {version.summary ? (
            <>
              <p className="text-xs font-semibold text-gray-600">Summary</p>
              <p className="text-xs leading-2 text-gray-500">
                {version.summary}
              </p>
            </>
          ) : null}
          <p className="text-xs leading-tight font-semibold text-gray-600">
            Changes
          </p>
          <p className="text-xs leading-tight text-gray-500">
            {version.changes.summary}
          </p>
          <div>
            <div className="my-3">
              {version.changes.textChanges.map((tc) => {
                return (
                  <div key={tc.section} className="mt-2">
                    <VersionTextChanges textChange={tc} />
                  </div>
                );
              })}
            </div>
          </div>

          <Pills>
            <FilePill
              id={props.fileId}
              name={version.fileName}
              type={version.fileType}
            />
          </Pills>
        </div>
      </>
    </div>
  );
}

const versionTextChangeOptions: Option[] = [
  {
    label: "Compare",
    value: "compare",
  },
  {
    label: "New",
    value: "new",
  },
  {
    label: "Previous",
    value: "previous",
  },
];

function VersionTextChanges(props: { textChange: FileVersionTextChange }) {
  const [selectedOption, setSelectedOption] = useState<Option>(
    versionTextChangeOptions[0]
  );
  return (
    <div>
      <div className="bg-white rounded-md p-2 border border-gray-200">
        <div className="flex items-center justify-between mb-1">
          <p className="font-semibold text-gray-600 text-xs">
            {props.textChange.section}
          </p>
          <Dropdown
            variant="text"
            size="xs"
            options={versionTextChangeOptions}
            selectedOption={selectedOption}
            onSelect={(o) => {
              setSelectedOption(o);
            }}
          />
        </div>
        {selectedOption.value === "new" ? (
          <p className="text-xs  text-gray-500">{props.textChange.new}</p>
        ) : null}
        {selectedOption.value === "previous" ? (
          <p className="text-xs  text-gray-500">{props.textChange.previous}</p>
        ) : null}
        {selectedOption.value === "compare" ? (
          <TextDiffs diffs={props.textChange.diffs} />
        ) : null}
      </div>
    </div>
  );
}
