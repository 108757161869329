import { withMarginTw } from "../styles/withMargin";

export function Pills(props: { children: React.ReactNode; margin?: string }) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <div className={`flex mt-2 flex-wrap items-center gap-2 ${margin}`}>
      {props.children}
    </div>
  );
}
