import { XCircleIcon } from "@heroicons/react/20/solid";

export function CloseIcon(props: { onClose: () => void }) {
  return (
    <XCircleIcon
      className="w-5 h-5 text-gray-400 hover:text-gray-500 cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        props.onClose();
      }}
    />
  );
}
