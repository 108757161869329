import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import Logo from "../../components/Logo";
import { useDispatch, useSelector } from "react-redux";
import useGqlClient from "../../hooks/useGqlClient";
import {
  FirmInviteQuery,
  useAcceptFirmInviteMutation,
  useAccountQuery,
  useCreateAccountMutation,
  useFirmInviteQuery,
} from "../../graphql/generated";
import { authSelectors } from "../../store/auth/selector";
import Loading from "../../components/Loading";
import { Card } from "../../components/Card";
import { useEffect, useState } from "react";
import { Button } from "../../components/tailwind/Button";
import { actions } from "../../store/auth/slice";
import { SignIn, SignUp, useSession } from "@clerk/clerk-react";
import { SignupSource } from "../Signup";

export function FirmInvite() {
  return (
    <div className="flex flex-1 justify-center py-20 px-10">
      <div className="hidden xl:block z-0 absolute inset-0 bg-persian-950/90 w-1/2"></div>
      <div className="grid z-10 grid-cols-1 xl:grid-cols-2 max-w-5xl">
        <div className="mr-12 hidden xl:block">
          <Logo width={105} height={35} />
          <p className="text-blue-100/50 font-semibold">Making deals happen.</p>

          <p className="text-white mt-4">
            Liquid pulls everything in a deal into one place. Powered by a
            powerful Data Room with a full audit log, granular access control
            and an AI toolkit to increase efficiency.
          </p>

          <div className="flex items-center mt-12 gap-x-3">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://liquidacquire.com/privacy-policy"
              className="text-persian-200 font-semibold hover:text-white text-sm"
            >
              Privacy Policy
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://liquidacquire.com/terms-of-service"
              className="text-persian-200 font-semibold hover:text-white text-sm"
            >
              Terms & Conditions
            </a>
          </div>
        </div>

        <div className="ml-12">
          <Switch>
            <Route path="/firm-invite/:token">
              <InviteDetails />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

function InviteDetails() {
  const { token } = useParams<{ token: string }>();

  const client = useGqlClient();
  const inviteQuery = useFirmInviteQuery(client, {
    token,
  });

  const account = useSelector(authSelectors.account);
  const history = useHistory();

  if (inviteQuery.error) {
    return (
      <div>
        <div>
          <p className="text-gray-800 font-semibold text-lg">Invite expired</p>
          <p className="text-gray-500">
            It looks like this link doesn't work anymore.
          </p>
          <p className="text-gray-500"></p>
        </div>
        <Card margin="l 0 0 0" padding="s m">
          {account ? (
            <>
              <p className="font-semibold text-sm text-gray-700">
                Welcome back {account.name},
              </p>
              <p className="text-gray-500">
                You'll need to ask your admin for a new invite
              </p>
              <Button
                margin="l 0 0 0"
                text="Take me home"
                variant="positive"
                onClick={() => {
                  history.push("/home");
                }}
              />
            </>
          ) : (
            <>
              <p className="font-semibold text-gray-700">
                Contact the admin for a new invite
              </p>
              <Button
                margin="l 0 0 0"
                text="Back to login"
                variant="positive"
                onClick={() => {
                  history.push("/login");
                }}
              />
            </>
          )}
        </Card>
      </div>
    );
  }

  if (inviteQuery.isPending || !inviteQuery.data) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <p className="text-gray-800 font-semibold text-lg">
          You've been invited to join your coworker on Liquid
        </p>
        <p className="text-gray-500">
          {inviteQuery.data.firmInvite.invitedBy.name}{" "}
          <span className="font-semibold">
            ({inviteQuery.data.firmInvite.invitedBy.email})
          </span>{" "}
          has invited you to join them on Liquid.
        </p>
      </div>
      <CardContent firmInvite={inviteQuery.data.firmInvite} />
    </>
  );
}

function CardContent(props: { firmInvite: FirmInviteQuery["firmInvite"] }) {
  const account = useSelector(authSelectors.account);
  const { session, isSignedIn } = useSession();
  const client = useGqlClient();
  const createAccount = useCreateAccountMutation(client);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isSignedIn) {
      return;
    }

    if (!session || !session.user) {
      return;
    }

    if (!session.user.unsafeMetadata) {
      return;
    }

    if (session.user.unsafeMetadata.onboardingStatus !== "initial") {
      return;
    }

    if (createAccount.isPending) {
      return;
    }

    if (account) {
      return;
    }

    createAccount.mutate(
      {
        clerkUserId: session.user.id,
      },
      {
        onSuccess: (data) => {
          session.reload();
          dispatch(actions.refreshAccount({ account: data.createAccount }));
        },
      }
    );
  }, [
    isSignedIn,
    session,
    session?.user.unsafeMetadata,
    (client as any).options,
  ]);

  if (!isSignedIn) {
    return <Authenticate firmInvite={props.firmInvite} />;
  }

  if (
    !session ||
    !session.user ||
    session.user.unsafeMetadata.onboardingStatus === "initial" ||
    !account
  ) {
    return <Loading />;
  }

  return <AcceptInvite firmInvite={props.firmInvite} />;
}

function AcceptInvite(props: { firmInvite: FirmInviteQuery["firmInvite"] }) {
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);

  const dispatch = useDispatch();
  const history = useHistory();

  const acceptFirmInvite = useAcceptFirmInviteMutation(client);

  const { token } = useParams<{ token: string }>();
  const account = useSelector(authSelectors.account);

  function joinFirm() {
    acceptFirmInvite.mutate(
      {
        token,
      },
      {
        onSuccess: (data) => {
          accountQuery.refetch().then((accountData) => {
            if (accountData.data) {
              dispatch(
                actions.refreshAccount({ account: accountData.data!.account })
              );

              history.push("/");
            }
          });
        },
      }
    );
  }

  if (account && account.email !== props.firmInvite.email) {
    return (
      <Card padding="m" margin="l 0 0 0">
        <div>
          <p className="text-gray-800 font-semibold">Unable to join firm</p>
          <p className="text-sm text-gray-500">
            The email you're logged in with doesn't match the invite.
          </p>
          <div className="flex justify-end mt-6">
            <Button
              onClick={() => {
                history.push("/");
              }}
              text="Take me home"
              variant="positive"
            />
          </div>
        </div>
      </Card>
    );
  }

  return (
    <Card padding="m" margin="l 0 0 0">
      <div>
        <p className="text-gray-800 font-semibold">Join Liquid</p>
        <p className="text-sm text-gray-500">
          You'll be joining {props.firmInvite.invitedBy.name} on Liquid.
        </p>
        <div className="flex justify-end mt-6">
          <Button
            onClick={() => {
              joinFirm();
            }}
            text="Join"
            variant="positive"
            loadingText="Joining..."
            isLoading={acceptFirmInvite.isPending}
          />
        </div>
      </div>
    </Card>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Authenticate(props: { firmInvite: FirmInviteQuery["firmInvite"] }) {
  const queryParams = useQuery();
  const location = useLocation();

  const [mode, setMode] = useState<"login" | "signup">("signup");

  useEffect(() => {
    if (queryParams.get("mode") === "login") {
      setMode("login");
    }
  }, [queryParams.toString()]);

  if (mode === "login") {
    return (
      <SignIn
        initialValues={{ emailAddress: props.firmInvite.email }}
        signUpUrl={`${location.pathname}?mode=signup`}
        redirectUrl={undefined}
        afterSignInUrl={location.pathname}
      />
    );
  }

  return (
    <div>
      <div className="mt-3">
        <SignUp
          appearance={{
            layout: {
              socialButtonsPlacement: "bottom",
              socialButtonsVariant: "iconButton",
            },
          }}
          initialValues={{ emailAddress: props.firmInvite.email }}
          signInUrl={`${location.pathname}?mode=login`}
          redirectUrl={undefined}
          afterSignUpUrl={location.pathname}
          unsafeMetadata={{
            onboardingStatus: "initial",
            signupSource: SignupSource.firmInvite,
          }}
        />
      </div>
    </div>
  );
}
