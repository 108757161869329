import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/cn";

interface Props {
  rows: number;
  name: string;
  value: string;
  label?: string;
  error?: string;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  maxLength?: number;
}

export function TextArea(props: Props) {
  return (
    <>
      <div
        className={classNames(
          "relative overflow-hidden rounded-lg ring-1 ring-inset  bg-white focus-within:ring-2 ",
          props.error
            ? "ring-red-300 focus-within:ring-red-600"
            : "ring-gray-300 focus-within:ring-gray-600"
        )}
      >
        <label htmlFor={props.name} className="sr-only">
          {props.label ?? props.name}
        </label>
        <textarea
          onBlur={props.onBlur}
          maxLength={props.maxLength}
          rows={props.rows}
          name={props.name}
          id={props.name}
          className={classNames(
            "p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          )}
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
        />
        {props.error ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {props.error ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.error}
        </p>
      ) : null}
    </>
  );
}
