import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { H1, H4 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import {
  DataRoomFileOnlineStatus,
  DataRoomFileUploadStatus,
  DealActivityType,
  FileDownloadUrlDocument,
  FileDownloadUrlQuery,
  FileDownloadUrlQueryVariables,
  useDataRoomFileQuery,
  useTrackActivityMutation,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";

import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/react/20/solid";
import { FileView } from "./FileContent";
import { Tabs } from "./Tabs";
import { FileIcon } from "../../../components/FileIcon";
import {
  DealRoleRestricted,
  adminRoles,
} from "../../../components/DealRoleRestricted";
import { NewVersionUploader } from "../../../components/data_room/file_uploaders/NewVersionUploader";
import { useDispatch } from "react-redux";
import { actions } from "../../../store/dataRoomFile/slice";
import { saveAs } from "file-saver";
import { Button } from "../../../components/tailwind/Button";
import { toasts } from "../../../components/toasts/toasts";
import { StatusDot } from "@/src/components/StatusDot";
import { Breadcrumbs } from "@/src/components/data_room/data_room_file/breadcrumbs";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function DataRoomFile() {
  const { id } = useParams<{ id: string }>();
  const client = useGqlClient();
  const dispatch = useDispatch();

  const query = useQuery();

  const [showFileUploader, setShowFileUploader] = useState(false);

  const trackActivity = useTrackActivityMutation(client);

  const dataRoomFileQuery = useDataRoomFileQuery(
    client,
    {
      id,
    },
    {
      queryKey: ["DataRoomFileView", { id }],
      queryHash: `["DataRoomFileView", {id: ${id}}]`,
      gcTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!dataRoomFileQuery.data) {
      return;
    }

    if (
      dataRoomFileQuery.data.dataRoomFile.uploadStatus ===
      DataRoomFileUploadStatus.Ready
    ) {
      return;
    }

    const intervalId = setInterval(() => {
      dataRoomFileQuery.refetch();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    dataRoomFileQuery,
    dataRoomFileQuery.isLoading,
    dataRoomFileQuery.data,
    dataRoomFileQuery.refetch,
  ]);

  useEffect(() => {
    if (!dataRoomFileQuery.data) {
      return;
    }
    trackActivity.mutate({
      type: DealActivityType.FileViewed,
      fileId: id,
      dataRoomId: "",
      folderId: dataRoomFileQuery.data.dataRoomFile.folderId,
    });

    dispatch(
      actions.setSelectedFileVersionId({
        fileId: id,
        versionId: dataRoomFileQuery.data.dataRoomFile.latestVersion.id,
      })
    );
  }, [id, dataRoomFileQuery.data]);

  if (dataRoomFileQuery.isPending) {
    return <Loading />;
  }

  if (dataRoomFileQuery.error || !dataRoomFileQuery.data) {
    return (
      <div className="w-full">
        <H1>Something went wrong</H1>
      </div>
    );
  }

  const pageIndex = query.get("pageIndex") ?? undefined;
  const rectsOnPage = query.get("rectsOnPage") ?? undefined;

  return (
    <div className="w-full relative">
      <div className="shadow-sm  sticky top-0">
        <div className="bg-white z-20 px-8 py-2 w-full border-b border-gray-300/80">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <FileIcon
                fileType={dataRoomFileQuery.data.dataRoomFile.fileType}
                size="s"
              />
              <H4>{dataRoomFileQuery.data.dataRoomFile.name}</H4>
              <DealRoleRestricted roles={adminRoles}>
                <StatusDot
                  status={
                    dataRoomFileQuery.data.dataRoomFile.onlineStatus ===
                    DataRoomFileOnlineStatus.Online
                      ? "positive"
                      : "neutral"
                  }
                />
              </DealRoleRestricted>
            </div>
            {dataRoomFileQuery.data &&
            !dataRoomFileQuery.data.dataRoomFile.deletedAt ? (
              <div className="flex gap-x-2">
                <DealRoleRestricted roles={adminRoles}>
                  <Button
                    text="New Version"
                    icon={PlusIcon}
                    size="s"
                    variant="neutral"
                    onClick={() => {
                      setShowFileUploader(true);
                    }}
                  />
                </DealRoleRestricted>
                <Button
                  text=""
                  variant="neutral"
                  size="s"
                  onClick={() => {
                    client
                      .request<
                        FileDownloadUrlQuery,
                        FileDownloadUrlQueryVariables
                      >(FileDownloadUrlDocument, {
                        id: dataRoomFileQuery.data.dataRoomFile.id,
                      })
                      .then((res) => {
                        const downloadUrl = res.fileDownloadUrl.downloadUrl;
                        fetch(downloadUrl).then(async (res) => {
                          const blob = await res.blob();
                          saveAs(
                            blob,
                            `${dataRoomFileQuery.data.dataRoomFile.name}`
                          );
                        });
                        trackActivity.mutate({
                          type: DealActivityType.FileDownloaded,
                          fileId: id,
                          dataRoomId: "",
                          folderId:
                            dataRoomFileQuery.data.dataRoomFile.folderId,
                        });
                      })
                      .catch((err) => {
                        toasts.error("Failed to download file");
                      });
                  }}
                  icon={ArrowDownTrayIcon}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="bg-white border-b py-1 border-gray-200 z-20 px-8 w-full flex items-center gap-x-2">
          <Breadcrumbs
            folders={dataRoomFileQuery.data.dataRoomFile.parentFolders}
            root="deal/documents"
          />
          <p className="text-sm text-gray-400">/</p>
          <FileIcon
            fileType={dataRoomFileQuery.data.dataRoomFile.fileType}
            size="s"
          />
          <p className="text-sm text-gray-900">
            {dataRoomFileQuery.data.dataRoomFile.name}
          </p>
        </div>
      </div>

      <div className="p-8 pt-4 grid grid-cols-1 xl:grid-cols-2 gap-6">
        <div>
          <FileView
            file={dataRoomFileQuery.data.dataRoomFile}
            pageIndex={pageIndex ? parseInt(pageIndex) : undefined}
            rectsOnPage={rectsOnPage ? JSON.parse(rectsOnPage) : undefined}
          />
        </div>

        <div className="mt-4">
          <Tabs
            dataRoomFile={dataRoomFileQuery.data.dataRoomFile}
            type="seller"
          />
        </div>
      </div>

      <NewVersionUploader
        open={showFileUploader}
        fileName={dataRoomFileQuery.data.dataRoomFile.name}
        dataRoomFileId={dataRoomFileQuery.data.dataRoomFile.id}
        onClose={() => {
          setShowFileUploader(false);
        }}
      />
    </div>
  );
}
