import { toast } from "sonner";

function error(message: string) {
  toast.error(message);
}

function success(message: string) {
  toast.success(message);
}

function info(message: string) {
  toast.info(message);
}

export const toasts = {
  error,
  success,
  info,
};
