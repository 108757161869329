import { useQueryClient } from "@tanstack/react-query";
import useGqlClient from "../../../hooks/useGqlClient";
import {
  useCreateDataFileVersionMutation,
  useS3FileUploadCompleteMutation,
} from "../../../graphql/generated";
import { useEffect, useRef, useState } from "react";
import { AnimatedModal } from "../../AnimatedModal";
import { H3 } from "../../Heading";
import { CloseIcon } from "../../CloseIcon";
import { TextArea } from "../../tailwind/TextArea";
import { classNames } from "../../../utils/cn";
import { LocalFileIcon } from "../../icons/LocalFileIcon";
import { formatFileSize } from "../../../utils/formatFileSize";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { Button } from "../../tailwind/Button";
import { Alert } from "../../Alert";
import { useInvalidateQueryKeys } from "../../../hooks/useInvalidateQueryKeys";

interface FileUploaderProps {
  open: boolean;
  fileName: string;
  dataRoomFileId: string;
  onClose: () => void;
  onNewVersion?: (id: string) => void;
  initialSummary?: string;
}

export function NewVersionUploader(props: FileUploaderProps) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const createDataFileVersion = useCreateDataFileVersionMutation(client);
  const fileUploadComplete = useS3FileUploadCompleteMutation(client);
  const { invalidateDealActivities } = useInvalidateQueryKeys();

  const [summary, setSummary] = useState(props.initialSummary || "");
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [fileError, setFileError] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);

  const createButtonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [dragActive, setDragActive] = useState(false);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files.length > 1) {
        setError("You can only upload one file at a time");
        return;
      }

      setFile(e.dataTransfer.files[0]);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (createButtonRef.current) {
          createButtonRef.current.click();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function clearForm() {
    setSummary("");
    setFile(null);
    setError("");
    setFileError("");
  }

  return (
    <AnimatedModal
      open={props.open}
      size="md"
      onClose={() => {
        clearForm();
        props.onClose();
      }}
    >
      <div>
        <div className="flex justify-between items-center">
          <H3>New version of {props.fileName}</H3>

          <CloseIcon
            onClose={() => {
              clearForm();
              props.onClose();
            }}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (!file) {
              setFileError(!file ? "File is required" : "");
              return;
            }

            createDataFileVersion.mutate(
              {
                dataRoomFileID: props.dataRoomFileId,
                fileName: file.name,
                fileType: file.type,
                summary,
              },
              {
                onSuccess: (data) => {
                  setUploadingFile(true);
                  queryClient.invalidateQueries({
                    queryKey: [
                      "DataRoomFileView",
                      { id: props.dataRoomFileId },
                    ],
                  });
                  fetch(data.createDataRoomFileVersion.url, {
                    method: "PUT",
                    body: file,
                  })
                    .catch((e) => {
                      setError("Error uploading file");
                    })
                    .then((res) => {
                      setUploadingFile(false);
                      queryClient.invalidateQueries({
                        queryKey: [
                          "DataRoomFile",
                          { id: props.dataRoomFileId },
                        ],
                      });
                      queryClient.invalidateQueries({
                        queryKey: [
                          "FileDownloadUrl",
                          { id: props.dataRoomFileId },
                        ],
                      });
                      queryClient.invalidateQueries({
                        queryKey: [
                          "DataRoomFileView",
                          { id: props.dataRoomFileId },
                        ],
                      });
                      fileUploadComplete.mutate(
                        {
                          dataRoomFileID: props.dataRoomFileId,
                          dataRoomFileVersionID:
                            data.createDataRoomFileVersion
                              .dataRoomFileVersionID,
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries({
                              queryKey: [
                                "DataRoomFile",
                                { id: props.dataRoomFileId },
                              ],
                            });
                          },
                        }
                      );
                      invalidateDealActivities();
                      clearForm();
                      if (props.onNewVersion) {
                        props.onNewVersion(
                          data.createDataRoomFileVersion.dataRoomFileVersionID
                        );
                      }
                      props.onClose();
                    });
                },
              }
            );
          }}
        >
          <div className="flex mt-3 items-center justify-between">
            <p className="text-sm font-medium leading-6 text-gray-900">
              Description of changes
            </p>
            <p className="text-xs text-gray-500">{summary.length} / 200</p>
          </div>
          <TextArea
            maxLength={200}
            rows={2}
            name="summary"
            placeholder="A short description of the changes..."
            value={summary}
            onChange={(e) => {
              setSummary(e.currentTarget.value);
            }}
          />

          <p className="text-sm mt-3 font-medium leading-6 text-gray-900">
            File
          </p>
          {file ? (
            <div
              className={classNames(
                "relative px-2 flex-wrap py-3 gap-x-2 border rounded-md border-gray-300 flex items-center"
              )}
            >
              <LocalFileIcon fileType={file.type} />
              <div>
                <p className="font-semibold text-gray-700 truncate">
                  {file.name}
                </p>
                <p className="text-sm text-gray-500 leading-none">
                  {formatFileSize(file.size)}
                </p>
              </div>
              <div className="absolute -top-2 -right-2">
                <CloseIcon
                  onClose={() => {
                    setFile(null);
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <div
                className={classNames(
                  "p-2 py-4 gap-x-2 border-2 rounded-md border-dashed border-gray-300 flex items-center justify-center",
                  dragActive ? "border-blue-500 shadow" : "",
                  fileError ? "border-red-500" : ""
                )}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              >
                <p className="text-sm text-gray-500 font-medium">
                  Drop file here or
                </p>
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1 text-blue-500 border px-2 py-1 rounded-full font-semibold hover:border-blue-400 hover:shadow text-sm"
                    onClick={() => {
                      setFileError("");
                      if (inputRef.current) {
                        inputRef.current.click();
                      }
                    }}
                  >
                    <DocumentPlusIcon className="w-5 h-5" />
                    <p>Upload</p>
                  </button>
                  <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.files) {
                        if (e.target.files.length > 1) {
                          setError("You can only upload one file at a time");
                          return;
                        }

                        setFile(e.target.files[0]);
                      }
                    }}
                    ref={inputRef}
                    type="file"
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              {fileError ? (
                <p className="text-red-500 font-medium mt-2 text-sm">
                  {fileError}
                </p>
              ) : null}
            </>
          )}

          {error ? <Alert margin="m 0 0 0" type="error" text={error} /> : null}
          <div className="mt-12 flex justify-end">
            <Button
              ref={createButtonRef}
              type="submit"
              variant="positive"
              text="Create version"
              isLoading={createDataFileVersion.isPending || uploadingFile}
              loadingText="Creating..."
              onClick={() => {}}
              isDisabled={!file}
            />
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}
