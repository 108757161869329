import { classNames } from "../utils/cn";

export function StatusDot(props: {
  status: "positive" | "neutral" | "negative";
}) {
  return (
    <div
      className={classNames(
        props.status === "positive"
          ? "bg-green-400"
          : props.status === "neutral"
          ? "bg-gray-400"
          : "bg-red-400",
        "w-1 h-1 rounded-full"
      )}
    />
  );
}
