import { useDispatch, useSelector } from "react-redux";
import { H4 } from "../../components/Heading";
import {
  AllFilesQuery,
  DashboardQuery,
  DealAccessStatus,
  DealActivityType,
  DealThreadMessage,
  DealThreadMessageRole,
  DealThreadMessageStatus,
  useActiveDealAccountQuery,
  useAllFilesQuery,
  useCreateDealThreadMessageMutation,
  useCreateDealThreadMutation,
  useDashboardQuery,
} from "../../graphql/generated";
import { v4 as uuid } from "uuid";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  differenceInDays,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  fromUnixTime,
  getUnixTime,
  isAfter,
  sub,
} from "date-fns";
import Loading, { Loader } from "../../components/Loading";
import { Card } from "../../components/Card";
import {
  ArrowLeftCircleIcon,
  ExclamationTriangleIcon,
  LockClosedIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { Link, NavLink } from "react-router-dom";
import { Avatar } from "../../components/account/Avatar";
import { FilePill } from "../../components/FilePill";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { ActivityItem } from "../../components/activity/ActivityItem";
import { classNames } from "../../utils/cn";
import { UseQueryResult, useQueryClient } from "@tanstack/react-query";
import { FileIcon } from "../../components/FileIcon";
import {
  DealRoleRestricted,
  adminRoles,
} from "../../components/DealRoleRestricted";
import { Dialog, Transition } from "@headlessui/react";
import { animated, useTransition } from "react-spring";
import { useWebSocket } from "../../contexts/websockets";
import Markdown from "react-markdown";
import { assistantSelectors } from "../../store/assistant/selector";
import { AppState } from "../../store";
import { actions } from "../../store/assistant/slice";
import { Pills } from "../../components/Pills";
import { DealProgress } from "../../components/DealProgress";
import { Pulse } from "../../components/Pulse";
import { presenceSelectors } from "../../store/presence/selector";
import { Button } from "../../components/tailwind/Button";
import { toasts } from "../../components/toasts/toasts";
import {
  FolderIcon,
  LockKeyholeIcon,
  MessageCircleQuestionIcon,
} from "lucide-react";

export function Dashboard() {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const [showAssitant, setShowAssitant] = useState(false);
  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1 p-8 flex flex-col">
        <DashboardContent dealId={activeDealId ?? ""} />
      </div>
      <SlideOver onClose={() => setShowAssitant(false)} open={showAssitant} />
    </div>
  );
}

function DashboardContent(props: { dealId: string }) {
  const [startDate, setStartDate] = useState<number>(
    getUnixTime(sub(new Date(), { days: 6 }))
  );
  const [endDate, setEndDate] = useState<number>(getUnixTime(new Date()));

  const client = useGqlClient();
  const dashboardQuery = useDashboardQuery(client, {
    dealId: props.dealId,
    activityInput: {
      startDate: startDate,
      endDate: endDate,
    },
    input: {
      startDate: startDate,
      endDate: endDate,
    },
  });

  if (dashboardQuery.error) {
    return <div>Something went wrong</div>;
  }

  if (dashboardQuery.isPending || !dashboardQuery.data) {
    return (
      <div className="flex-1 ">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <H4>Overview</H4>
      <div className="mt-1 flex flex-col xl:flex-row gap-4">
        <DataRoomCardContent />
        <div className="w-full xl:w-1/3">
          <Card>
            <div className="h-60 flex flex-col">
              <div className="flex px-3 pt-3 pb-2 border-b border-gray-200 items-center ">
                <MessageCircleQuestionIcon className="w-4 h-4 text-gray-600" />
                <div className="ml-2">
                  <p className="text-gray-600 text-sm font-semibold">
                    Questions
                  </p>
                </div>
              </div>
              <div className="overflow-y-scroll no-scrollbar flex flex-col flex-1">
                <div className="flex flex-col flex-1">
                  {dashboardQuery.data.questions.questions.length === 0 ? (
                    <div className="flex  justify-center flex-1  items-center">
                      <p className="text-gray-500 text-xs">No questions</p>
                    </div>
                  ) : null}
                  {dashboardQuery.data.questions.questions
                    .filter((question) => !question.answer)
                    .map((question) => {
                      return (
                        <Link
                          key={question.id}
                          to={`/questions/${question.id}`}
                          className="cursor-pointer"
                        >
                          <div className="flex hover:bg-gray-100 px-3 py-2 items-center space-x-3">
                            <Avatar account={question.createdBy} />
                            <div className="flex-1">
                              <p className="text-gray-600 text-sm font-semibold leading-tight">
                                {question.title}
                              </p>
                              <p className="text-xs text-gray-500/80 leading-tight text-ellipsis">
                                Created{" "}
                                {formatDistanceToNow(
                                  fromUnixTime(question.createdAt),
                                  {
                                    addSuffix: true,
                                  }
                                )}
                              </p>
                            </div>
                            {question.dataRoomFile ? (
                              <FilePill
                                id={question.dataRoomFile.id}
                                name={question.dataRoomFile.name}
                                type={question.dataRoomFile.fileType}
                              />
                            ) : null}
                            <div className="flex gap-x-2">
                              <ChatBubbleLeftIcon className="mt-0.5 w-4 h-4 text-gray-500" />
                              <p className="text-gray-600 text-xs">
                                {
                                  question.activity.filter(
                                    (a) => a.type === DealActivityType.Comment
                                  ).length
                                }
                              </p>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <DealRoleRestricted roles={adminRoles}>
          <div className="w-full xl:w-1/3">
            <Card>
              <div className="h-60 flex flex-col">
                <div className="flex px-3 pt-3 border-b border-gray-200 pb-2 items-center ">
                  <LockKeyholeIcon className="w-4 h-4 text-gray-600" />
                  <div className="ml-2">
                    <p className="text-gray-600 text-sm font-semibold">
                      Access
                    </p>
                  </div>
                </div>
                <div className="overflow-y-scroll no-scrollbar">
                  {dashboardQuery.data.deal.buyers.map((buyer, i) => {
                    return (
                      <NavLink
                        key={buyer.id}
                        className={(isActive) => {
                          return classNames(
                            "py-1.5 px-3 hover:bg-gray-100 font-semibold text-gray-700 flex justify-between items-center",
                            i === dashboardQuery.data.deal.buyers.length - 1
                              ? "rounded-b-md"
                              : ""
                          );
                        }}
                        activeClassName="bg-gray-200 text-gray-500"
                        to={`/deal/access/group/${buyer.id}`}
                      >
                        <div>
                          <p className="text-gray-600 text-sm font-semibold">
                            {buyer.name}
                          </p>
                          <p className="text-xs text-gray-500/80 leading-tight font-normal">
                            {buyer.dataRoomPermission.name}
                          </p>
                        </div>
                        {buyer.dealAccessStatus !== DealAccessStatus.Granted ? (
                          <LockClosedIcon className="w-4 h-4 text-gray-500" />
                        ) : null}
                      </NavLink>
                    );
                  })}
                </div>
              </div>
            </Card>
          </div>
        </DealRoleRestricted>
      </div>
      {/* <DealRoleRestricted
        buyerRunDealRoles={[]}
        sellerRunDealRoles={[...sellerRoles, ...adminRoles]}
      >
        <div className="mt-8 w-full">
          <H4>Buyers</H4>
          {dashboardQuery.data.deal.buyers.length === 0 ? (
            <p className=" text-gray-500/80 text-sm">No buyers</p>
          ) : null}
          <div className="w-full flex gap-x-2 mt-2 scrollbar-none">
            {dashboardQuery.data.deal.buyers.map((buyer) => {
              return (
                <BuyerCard
                  key={buyer.id}
                  buyer={buyer}
                  query={dashboardQuery}
                />
              );
            })}
          </div>
        </div>
      </DealRoleRestricted> */}
      <div className="mt-8 w-full xl:w-1/2">
        <H4>Recent activity</H4>
        {dashboardQuery.data.dealActivity.activity.length === 0 ? (
          <p className="text-gray-500 text-xs">No recent activity</p>
        ) : null}
        <ul role="list" className="mt-3 space-y-6">
          {dashboardQuery.data.dealActivity.activity.map((activity, i) => {
            return (
              <ActivityItem
                mode="verbose"
                isFirst={i === 0}
                isLast={
                  i === dashboardQuery.data.dealActivity.activity.length - 1
                }
                key={activity.id}
                activity={activity}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function BuyerCard(props: {
  query: UseQueryResult<DashboardQuery, unknown>;
  buyer: DashboardQuery["deal"]["buyers"][0];
}) {
  const buyer = props.buyer;
  const dealFirmGroupPresence = useSelector((state: AppState) =>
    presenceSelectors.dealFirmGroupPresence(state, buyer.id)
  );

  if (!props.query.data) {
    return null;
  }

  return (
    <div key={buyer.id} className="min-w-72">
      <NavLink to={`/buyers/${buyer.id}`}>
        <Card padding="m">
          <div className="flex items-center justify-between">
            <p className="font-semibold text-gray-700 text-sm">{buyer.name}</p>
            <DealFirmGroupStatus
              dealFirmGroupId={buyer.id}
              dealFirmGroup={buyer}
            />
          </div>
          <div className="flex space-x-0.5 mt-2">
            <DealProgress
              dealStages={props.query.data.deal.dealStages}
              currentDealStage={buyer.currentDealStage}
              currentDealStageStatus={buyer.currentDealStageStatus}
              mode="compact"
            />
          </div>
          <div className="mt-4 gap-1 grid grid-cols-2">
            <p className="text-xs font-semibold text-gray-600">Questions</p>
            <p className="text-xs text-gray-500 text-right">
              {buyer.questions.length}
            </p>

            <p className="text-xs font-semibold text-gray-600">
              Data Room permission level
            </p>
            <p className="text-xs text-gray-500 text-right">
              {buyer.dataRoomPermission.name}
            </p>

            <p className="text-xs font-semibold text-gray-600">Last activity</p>
            <p className="text-xs text-gray-500 text-right">
              {buyer.activity.length === 0 ? (
                "No activity"
              ) : (
                <>
                  {formatDistanceToNowStrict(
                    fromUnixTime(buyer.activity[0].createdAt),
                    { addSuffix: true }
                  )}
                </>
              )}
            </p>

            <p className="text-xs font-semibold text-gray-600">
              Last file upload
            </p>
            <p className="text-xs text-gray-500 text-right">
              {buyer.files.length === 0 ? (
                <p className="text-xs text-gray-500">No files</p>
              ) : (
                <div className="flex justify-end">
                  <FilePill
                    id={
                      buyer.files.sort((a, b) => a.createdAt - b.createdAt)[0]
                        .id
                    }
                    name={
                      buyer.files.sort((a, b) => a.createdAt - b.createdAt)[0]
                        .name
                    }
                    type={
                      buyer.files.sort((a, b) => a.createdAt - b.createdAt)[0]
                        .fileType
                    }
                  />
                </div>
              )}
            </p>
          </div>
        </Card>
      </NavLink>
    </div>
  );
}

function DealFirmGroupStatus(props: {
  dealFirmGroupId: string;
  dealFirmGroup: DashboardQuery["deal"]["buyers"][0];
}) {
  const dealFirmGroupPresence = useSelector((state: AppState) =>
    presenceSelectors.dealFirmGroupPresence(state, props.dealFirmGroupId)
  );

  if (props.dealFirmGroup.dealAccessStatus != DealAccessStatus.Granted) {
    return (
      <div className="flex items-center">
        <LockClosedIcon className="w-4 h-4 text-gray-500" />
        <p className="text-xs text-gray-500">No deal access</p>
      </div>
    );
  }

  if (
    (!dealFirmGroupPresence ||
      dealFirmGroupPresence.activeUsers.length === 0) &&
    props.dealFirmGroup.lastSeen
  ) {
    const lastSeen = fromUnixTime(props.dealFirmGroup.lastSeen);

    if (differenceInDays(new Date(), lastSeen) > 7) {
      return (
        <div className="flex items-center gap-x-1.5">
          <p className="text-xs text-gray-500">Inactive</p>
          <Pulse color="gray" animate />
        </div>
      );
    }

    return (
      <div className="flex items-center gap-x-1.5">
        <p className="text-xs text-gray-500">
          Last seen{" "}
          {formatDistanceToNowStrict(
            fromUnixTime(props.dealFirmGroup.lastSeen),
            { addSuffix: true }
          )}
        </p>
        <Pulse color="gray" />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-x-1.5">
      <p className="text-xs text-green-500">
        {dealFirmGroupPresence && dealFirmGroupPresence.activeUsers
          ? dealFirmGroupPresence.activeUsers.length
          : 0}{" "}
        online
      </p>
      <Pulse color="green" animate />
    </div>
  );
}

function DealAccessText(props: { dealAccessStatus: DealAccessStatus }) {
  switch (props.dealAccessStatus) {
    case DealAccessStatus.Granted:
      return (
        <p className="text-xs text-green-800 leading-tight text-right">
          Granted
        </p>
      );
    case DealAccessStatus.PendingManualApproval:
      return (
        <p className="text-xs text-orange-800 leading-tight text-right">
          Awaiting access
        </p>
      );
    case DealAccessStatus.PendingNda:
      return (
        <p className="text-xs text-orange-800 leading-tight text-right">
          NDA needs signed
        </p>
      );
    case DealAccessStatus.Blocked:
      return (
        <p className="text-xs text-red-800 leading-tight text-right">
          Access revoked
        </p>
      );
    default:
      return null;
  }
}

function DataRoomCardContent() {
  const activeDealId = useSelector(authSelectors.activeDealId);

  const client = useGqlClient();
  const allFiles = useAllFilesQuery(client, {
    dealId: activeDealId ?? "",
  });

  return (
    <div className="w-full xl:w-1/3">
      <Card>
        <div className="h-60 flex flex-col">
          <div className="flex pt-3 border-b border-gray-200 pb-2 px-3 items-center">
            <FolderIcon className="w-4 h-4 text-gray-600" />
            <div className="flex items-center justify-between flex-1">
              <p className="text-gray-600 text-sm ml-2 font-semibold">
                Documents
              </p>

              <p className="text-xs text-gray-500/80">Recent files</p>
            </div>
          </div>
          <div className="overflow-y-scroll no-scrollbar flex flex-col flex-1">
            <DataRoomCardContentInner query={allFiles} />
          </div>
        </div>
      </Card>
    </div>
  );
}

function DataRoomCardContentInner(props: {
  query: UseQueryResult<AllFilesQuery, unknown>;
}) {
  if (props.query.error) {
    return (
      <div className="flex justify-center  flex-1  items-center">
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return (
      <div className="flex justify-center  flex-1  items-center">
        <Loading />
      </div>
    );
  }

  const recentFiles = props.query.data.deal.dataRoom.allFiles.filter((file) =>
    isAfter(fromUnixTime(file.createdAt), sub(new Date(), { hours: 300 }))
  );

  if (recentFiles.length === 0) {
    return (
      <div className="flex justify-center  flex-1  items-center">
        <p className="text-gray-500 text-xs">No recent files</p>
      </div>
    );
  }

  return (
    <div>
      {recentFiles.map((file) => {
        return (
          <NavLink
            key={file.id}
            to={`/deal/documents/folder/${file.folderId}/file/${file.id}`}
          >
            <div
              key={file.id}
              className="flex cursor-pointer hover:bg-gray-100 items-center justify-between px-2.5 py-2"
            >
              <div className="flex items-center">
                <FileIcon fileType={file.fileType} />
                <p className="text-sm ml-1 font-semibold text-gray-700 leading-tight">
                  {file.name}
                </p>
              </div>
              <p className="text-xs justify-self-end text-gray-500/80">
                {formatDistanceToNowStrict(fromUnixTime(file.createdAt), {
                  addSuffix: true,
                })}
              </p>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
}

function SlideOver(props: { onClose: () => void; open: boolean }) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const createDealThread = useCreateDealThreadMutation(client);
  const dispatch = useDispatch();
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll no-scrollbar bg-white border-b border-gray z-50 pt-6 shadow-xl">
                    <div className="px-1 sm:px-3 shadow pb-4">
                      <div className="flex items-start  justify-between">
                        <div className="flex items-center gap-x-2">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => props.onClose()}
                          >
                            <span className="absolute -inset-2.5" />
                            <span className="sr-only">Close panel</span>
                            <ArrowLeftCircleIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Assistant
                          </Dialog.Title>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <Button
                            icon={PlusIcon}
                            variant="neutral"
                            text="New chat"
                            size="s"
                            onClick={() => {
                              if (!activeDealId) return;

                              createDealThread.mutate(
                                {
                                  input: {
                                    dealID: activeDealId,
                                  },
                                },
                                {
                                  onError: () => {
                                    toasts.error("Failed to create chat");
                                  },
                                  onSuccess: (data) => {
                                    dispatch(
                                      actions.setActiveDealThreadId(
                                        data.createDealThread.id
                                      )
                                    );

                                    for (const message of data.createDealThread
                                      .messages) {
                                      dispatch(
                                        actions.addDealThreadMessage({
                                          dealThreadId:
                                            data.createDealThread.id,
                                          message: message as DealThreadMessage,
                                        })
                                      );
                                    }
                                  },
                                }
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="relative overflow-y-scroll  flex-1 ">
                      <Chat />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function Chat() {
  const [newMessage, setNewMessage] = useState("");

  const { subscribeToTopic, unsubscribeFromTopic } = useWebSocket();

  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const activeDealAccount = useActiveDealAccountQuery(client, {
    id: activeDealId ?? "",
  });

  const activeDealThreadId = useSelector(assistantSelectors.activeDealThreadId);

  const createDealThread = useCreateDealThreadMutation(client);
  const createDealThreadMessage = useCreateDealThreadMessageMutation(client);

  const dealThreadMessages = useSelector((state: AppState) =>
    assistantSelectors.dealThreadMessages(state, activeDealThreadId ?? "")
  );

  const lastMessage =
    dealThreadMessages && dealThreadMessages.length > 0
      ? dealThreadMessages[dealThreadMessages.length - 1]
      : undefined;
  const [chatDisabled, setChatDisabled] = useState(false);

  useEffect(() => {
    if (!lastMessage) {
      return;
    }

    if (
      lastMessage.role === DealThreadMessageRole.User &&
      (lastMessage.status === DealThreadMessageStatus.Sending ||
        lastMessage.status === DealThreadMessageStatus.Sent)
    ) {
      setChatDisabled(true);
      return;
    }

    setChatDisabled(false);
    textAreaRef?.current?.focus();
  }, [lastMessage?.status, lastMessage?.role]);

  const scrollRef = useRef<HTMLDivElement>(null);
  const createCommentRef = useRef<HTMLButtonElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [dealThreadMessages]);

  useEffect(() => {
    if (!activeDealAccount.data) return;
    if (!scrollRef.current) return;

    if (activeDealAccount.data.deal.activeDealAccount.dealThread) {
      dispatch(
        actions.setActiveDealThreadId(
          activeDealAccount.data.deal.activeDealAccount.dealThread.id
        )
      );

      for (const message of activeDealAccount.data.deal.activeDealAccount
        .dealThread.messages) {
        dispatch(
          actions.addDealThreadMessage({
            dealThreadId:
              activeDealAccount.data.deal.activeDealAccount.dealThread.id,
            message: message as DealThreadMessage,
          })
        );
      }

      scrollRef.current?.scrollIntoView({ behavior: "smooth" });

      return;
    }

    createDealThread.mutate(
      {
        input: {
          dealID: activeDealId ?? "",
        },
      },
      {
        onError: (e) => {
          console.log(e);
        },
        onSuccess: (d) => {
          queryClient.invalidateQueries({
            queryKey: ["ActiveDealAccount", { id: activeDealId ?? "" }],
          });

          dispatch(actions.setActiveDealThreadId(d.createDealThread.id));
        },
      }
    );
  }, [activeDealAccount.data, scrollRef.current]);

  useEffect(() => {
    if (!activeDealThreadId) {
      return;
    }

    subscribeToTopic(`deal_thread:${activeDealThreadId}`);

    return () => {
      unsubscribeFromTopic(`deal_thread:${activeDealThreadId}`);
    };
  }, [activeDealThreadId, subscribeToTopic, unsubscribeFromTopic]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (createCommentRef.current) {
        createCommentRef.current.click();
      }
    }
  };

  const transitions = useTransition(dealThreadMessages, {
    from: { transform: "translate3d(0,40px,0)", opacity: 0 },
    enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
    leave: { transform: "translate3d(0,-40px,0)", opacity: 0 },
    keys: (message) => (message as any).id,
  });

  return (
    <div className="flex h-full flex-col">
      <div className="flex-1 overflow-y-scroll bg-gray-50 px-4">
        {transitions((style, message) => {
          if (!message.content) {
            return null;
          }
          if (message.role === DealThreadMessageRole.User) {
            return (
              <animated.div
                style={style}
                key={message.id}
                className={classNames(
                  "flex items-center justify-end space-x-2",
                  message.status === DealThreadMessageStatus.Sending ||
                    message.status === DealThreadMessageStatus.Failed
                    ? "opacity-50"
                    : "opacity-1"
                )}
              >
                <div className="flex items-center gap-x-2">
                  {message.status === DealThreadMessageStatus.Failed ? (
                    <ExclamationTriangleIcon className="w-5 h-5 text-red-500" />
                  ) : null}
                  <div className="bg-persian-100/70 max-w-xs shadow rounded-md p-2 my-2">
                    <p className="text-sm text-gray-600">
                      <Markdown>{message.content.text.value}</Markdown>
                    </p>
                  </div>
                </div>
              </animated.div>
            );
          }

          return (
            <animated.div
              style={style}
              key={message.id}
              className="flex items-center justify-start space-x-2"
            >
              <div className="bg-gray-50 max-w-xs shadow rounded-md p-2 my-2">
                <p className="text-sm text-gray-600">
                  {message.status === DealThreadMessageStatus.Thinking ? (
                    <div className="flex">
                      <Loader fill="#6B7280" style={{ width: 30, height: 6 }} />
                    </div>
                  ) : (
                    <>
                      <Markdown>{message.content.text.value}</Markdown>
                    </>
                  )}
                </p>
                <div className="mt-2">
                  {message.content.quotes
                    ? message.content.quotes.map((quote) => {
                        return (
                          <div
                            className="p-3 bg-white border my-1 border-gray-200 rounded-md"
                            key={quote.quote}
                          >
                            <p className="text-sm text-gray-600">
                              {quote.quote}
                            </p>
                          </div>
                        );
                      })
                    : null}
                </div>
                {message.content.files && message.content.files.length > 0 ? (
                  <Pills>
                    {message.content.files.map((file) => {
                      return (
                        <FilePill
                          key={file.file.id}
                          id={file.file.id}
                          name={file.file.name}
                          type={file.file.fileType}
                          showDetailsCard={false}
                          pageIndex={
                            file.pageIndex ? file.pageIndex : undefined
                          }
                          rectsOnPage={
                            file.rectsOnPage ? file.rectsOnPage : undefined
                          }
                        />
                      );
                    })}
                  </Pills>
                ) : null}
                {message.files && message.files.length > 0 ? (
                  <Pills>
                    {message.files.map((file) => {
                      return (
                        <FilePill
                          key={file.id}
                          id={file.id}
                          name={file.name}
                          type={file.fileType}
                          showDetailsCard={false}
                        />
                      );
                    })}
                  </Pills>
                ) : null}
              </div>
            </animated.div>
          );
        })}
        <div ref={scrollRef} />
      </div>

      <div className="p-4 border-t bg-gray-50">
        <div className="relative flex-auto">
          <div
            className={classNames(
              chatDisabled ? "opacity-50 focus-within:ring-gray-300" : "",
              "bg-white overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600"
            )}
          >
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              ref={textAreaRef}
              rows={2}
              name="comment"
              value={newMessage}
              onKeyDown={(e) => {
                handleKeyDown(e);
              }}
              id="comment"
              onChange={(e) => {
                if (chatDisabled) {
                  return;
                }

                setNewMessage(e.currentTarget.value);
              }}
              className={classNames(
                chatDisabled ? "opacity-50" : "",
                "p-4 focus:outline-none block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              )}
              placeholder="Send a message..."
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5"></div>
            <button
              disabled={chatDisabled}
              ref={createCommentRef}
              onClick={() => {
                if (!newMessage) {
                  return;
                }

                const newId = `deal_thread_msg_${uuid()}`;
                const messageToAdd: Partial<DealThreadMessage> = {
                  id: newId,
                  role: DealThreadMessageRole.User,
                  content: {
                    text: {
                      value: newMessage,
                      annotations: [],
                    },
                    quotes: [],
                    files: [],
                    type: "text",
                  },
                  status: DealThreadMessageStatus.Sending,
                };

                dispatch(
                  actions.addDealThreadMessage({
                    dealThreadId: activeDealThreadId ?? "",
                    message: messageToAdd as DealThreadMessage,
                  })
                );

                setNewMessage("");

                createDealThreadMessage.mutate(
                  {
                    input: {
                      id: newId,
                      message: newMessage,
                      dealThreadID: activeDealThreadId ?? "",
                    },
                  },
                  {
                    onError: (e) => {
                      console.log(e);
                      dispatch(
                        actions.updateDealThreadMessageStatus({
                          dealThreadId: activeDealThreadId ?? "",
                          messageId: newId,
                          status: DealThreadMessageStatus.Failed,
                        })
                      );
                    },
                    onSuccess: () => {
                      dispatch(
                        actions.updateDealThreadMessageStatus({
                          dealThreadId: activeDealThreadId ?? "",
                          messageId: newId,
                          status: DealThreadMessageStatus.Sent,
                        })
                      );
                    },
                  }
                );
              }}
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
