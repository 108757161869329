import {
    DataRoomFileUploadStatus,
    useFileViewerQuery,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { Spinner } from "./icons/Spinner";
import Loading from "./Loading";
import { PdfViewerDocumentId } from "./PdfViewer";

interface Props {
  dataRoomFileId: string;
  dataRoomFileVersionId: string;
  pageIndex?: number;
  rectsOnPage?: number[][];
}

export function FileViewer(props: Props) {
  const client = useGqlClient();

  const fileViewerQuery = useFileViewerQuery(client, {
    dataRoomFileVersionId: props.dataRoomFileVersionId,
    dataRoomFileId: props.dataRoomFileId,
  });

  if (fileViewerQuery.isLoading || !fileViewerQuery.data) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (fileViewerQuery.error) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <p className="text-sm text-gray-500/80">
          Error loading file. Please try again.
        </p>
      </div>
    );
  }

  if (
    fileViewerQuery.data.dataRoomFile.uploadStatus !==
      DataRoomFileUploadStatus.Ready &&
    fileViewerQuery.data.dataRoomFile.uploadStatus !==
      DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (!fileViewerQuery.data.dataRoomFileVersion.pspdfkitDocumentId) {
    return <div>No PSPDFKit document ID</div>;
  }

  return (
    <PdfViewerDocumentId
      documentId={fileViewerQuery.data.dataRoomFileVersion.pspdfkitDocumentId}
      token={fileViewerQuery.data.dataRoomFileVersion.pspdfkitToken}
      rectsOnPage={props.rectsOnPage}
      pageIndex={props.pageIndex}
    />
  );
}
