import { useSelector } from "react-redux";
import {
  DealGroup,
  DealRunner,
  DealStage,
  DealStageStatus,
  Maybe,
  useUpdateDealGroupMutation,
} from "../graphql/generated";
import { authSelectors } from "../store/auth/selector";
import { useRef, useState } from "react";
import { Card } from "./Card";
import { classNames } from "../utils/cn";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { Button } from "./tailwind/Button";
import { formatEnum } from "../utils/enums";
import useGqlClient from "../hooks/useGqlClient";
import { useQueryClient } from "@tanstack/react-query";
import { PencilSquareIcon } from "@heroicons/react/20/solid";

interface Step {
  id: string;
  name: string;
  status: DealStageStatus;
  firmCount?: number;
}

const statusColors: { [key in DealStageStatus]: string } = {
  [DealStageStatus.Current]: "bg-green-300/90",
  [DealStageStatus.Overdue]: "bg-orange-400",
  [DealStageStatus.Upcoming]: "bg-gray-300/80",
  [DealStageStatus.Completed]: "bg-green-400",
};

export function DealProgress(props: {
  dealFirmGroupId?: string;
  dealStages?: Array<Pick<DealStage, "id" | "name" | "index">>;
  currentDealStage?: Maybe<Pick<DealStage, "id" | "index">>;
  currentDealStageStatus?: Maybe<DealStageStatus>;
  dealGroups?: Array<
    Pick<DealGroup, "id" | "name" | "currentDealStageStatus"> & {
      currentDealStage?: Maybe<
        { __typename?: "DealStage" } & Pick<DealStage, "id">
      >;
    }
  >;
  mode?: "compact" | "verbose" | "deal-verbose";
}) {
  const activeDeal = useSelector(authSelectors.activeDeal);
  const [selectedStep, setSelectedStep] = useState<Step | null>(null);

  if (
    !props.dealStages ||
    !props.currentDealStage ||
    !props.currentDealStageStatus
  ) {
    return null;
  }

  const currentDealStage = props.currentDealStage;
  const currentDealStageStatus = props.currentDealStageStatus;

  const steps: Step[] = props.dealStages
    .sort((a, b) => a.index - b.index)
    .map((stage) => {
      return {
        id: stage.id,
        name: stage.name,
        status:
          stage.index < currentDealStage.index
            ? DealStageStatus.Completed
            : stage.index === currentDealStage.index
            ? currentDealStageStatus
            : DealStageStatus.Upcoming,
        firmCount:
          props.mode === "deal-verbose" &&
          props.dealGroups &&
          props.dealGroups.length > 0
            ? props.dealGroups.filter(
                (group) =>
                  group.currentDealStage &&
                  group.currentDealStage.id === stage.id
              ).length
            : undefined,
      };
    });

  const runner = activeDeal
    ? activeDeal.runner === DealRunner.Buyer
      ? "seller"
      : "buyer"
    : "seller";

  if (props.mode === "compact") {
    return (
      <>
        {steps.map((step) => {
          return (
            <div key={step.id} className="flex-1 opacity-80">
              <div
                title={step.name}
                className={`h-1 flex-1 ${statusColors[step.status]}`}
              ></div>
            </div>
          );
        })}
      </>
    );
  }

  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {steps.map((step) => (
          <DealStep
            dealFirmGroupId={props.dealFirmGroupId}
            key={step.id}
            runner={runner}
            step={step}
            selectedStep={selectedStep}
            onSelectStep={(step) => {
              setSelectedStep(step);
            }}
            onMarkAsComplete={() => {}}
            onMarkAsOverdue={() => {}}
            nextStep={steps[steps.indexOf(step) + 1] || null}
          />
        ))}
      </ol>
    </nav>
  );
}

function getStatusText(status: DealStageStatus) {
  if (status === DealStageStatus.Overdue) {
    return "Sent";
  }

  if (status === DealStageStatus.Completed) {
    return "Signed";
  }

  return formatEnum(status);
}

function DealStep(props: {
  dealFirmGroupId?: string;
  step: Step;
  runner: string;
  selectedStep: Step | null;
  onSelectStep: (step: Step | null) => void;
  onMarkAsComplete: () => void;
  onMarkAsOverdue: () => void;
  nextStep: Step | null;
}) {
  const { step, runner, selectedStep, onSelectStep } = props;
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const updateDealGroup = useUpdateDealGroupMutation(client);

  const [action, setAction] = useState<"complete" | "overdue" | null>(null);

  const ref = useRef<HTMLLIElement>(null);
  useOutsideClick(ref, () => {
    if (selectedStep && selectedStep.id === step.id) {
      onSelectStep(null);
    }
  });

  return (
    <li ref={ref} className="md:flex-1 relative">
      <button
        className="w-full text-left"
        onClick={() => {
          if (selectedStep && selectedStep.id === step.id) {
            onSelectStep(null);
            return;
          }
          onSelectStep(step);
        }}
      >
        <DealStepStatus
          step={step}
          runner={runner}
          dealFirmGroupId={props.dealFirmGroupId}
        />
      </button>
      {props.dealFirmGroupId ? (
        <div
          className={classNames(
            selectedStep && selectedStep.id === step.id
              ? "opacity-100 scale-100"
              : "invisible scale-75 opacity-0",
            "transition-all absolute w-full duration-200 ease-in-out"
          )}
        >
          <Card margin="m 0 0 0" padding="m">
            <p className="font-semibold text-sm text-gray-500">{step.name}</p>
            <p className="text-xs text-gray-500">
              {getStatusText(step.status)}
            </p>
            <div className="mt-2 gap-x-1.5 flex">
              {step.status === DealStageStatus.Overdue ||
              step.status === DealStageStatus.Current ? (
                <>
                  <Button
                    size="s"
                    variant="neutral"
                    text="Sent"
                    isLoading={
                      updateDealGroup.isPending && action === "overdue"
                    }
                    onClick={() => {
                      if (!props.dealFirmGroupId) {
                        return;
                      }

                      setAction("overdue");

                      updateDealGroup.mutate(
                        {
                          input: {
                            id: props.dealFirmGroupId,
                            currentDealStage: {
                              dealStageId: step.id,
                              status: DealStageStatus.Overdue,
                            },
                          },
                        },
                        {
                          onSuccess: () => {
                            setAction(null);
                            queryClient.invalidateQueries({
                              queryKey: [
                                "DealGroup",
                                { id: props.dealFirmGroupId },
                              ],
                            });
                          },
                        }
                      );
                    }}
                  />
                  <Button
                    size="s"
                    variant="positive"
                    text="Signed"
                    isLoading={
                      updateDealGroup.isPending && action === "complete"
                    }
                    onClick={() => {
                      if (!props.dealFirmGroupId) {
                        return;
                      }

                      setAction("complete");

                      updateDealGroup.mutate(
                        {
                          input: {
                            id: props.dealFirmGroupId,
                            currentDealStage: {
                              dealStageId: props.nextStep
                                ? props.nextStep.id
                                : step.id,
                              status: props.nextStep
                                ? DealStageStatus.Current
                                : DealStageStatus.Completed,
                            },
                          },
                        },
                        {
                          onSuccess: () => {
                            setAction(null);
                            queryClient.invalidateQueries({
                              queryKey: [
                                "DealGroup",
                                { id: props.dealFirmGroupId },
                              ],
                            });

                            if (props.nextStep) {
                              props.onSelectStep(props.nextStep);
                            }
                          },
                        }
                      );
                    }}
                  />
                </>
              ) : null}
              {step.status === DealStageStatus.Upcoming ||
              step.status === DealStageStatus.Completed ? (
                <Button
                  size="s"
                  variant="neutral"
                  text="Set as current stage"
                  isLoading={updateDealGroup.isPending}
                  onClick={() => {
                    if (!props.dealFirmGroupId) {
                      return;
                    }

                    updateDealGroup.mutate(
                      {
                        input: {
                          id: props.dealFirmGroupId,
                          currentDealStage: {
                            dealStageId: step.id,
                            status: DealStageStatus.Current,
                          },
                        },
                      },
                      {
                        onSuccess: () => {
                          queryClient.invalidateQueries({
                            queryKey: [
                              "DealGroup",
                              { id: props.dealFirmGroupId },
                            ],
                          });
                        },
                      }
                    );
                  }}
                />
              ) : null}
            </div>
          </Card>
        </div>
      ) : null}
    </li>
  );
}

const dealStepStatusBorderColors: { [key in DealStageStatus]: string } = {
  [DealStageStatus.Completed]: "border-green-600 hover:border-green-800",
  [DealStageStatus.Overdue]: "border-orange-600 hover:border-orange-800",
  [DealStageStatus.Current]: "border-green-400/50",
  [DealStageStatus.Upcoming]: "border-gray-200 hover:border-gray-300",
};

const dealStepStatusTextColors: { [key in DealStageStatus]: string } = {
  [DealStageStatus.Completed]: "text-green-600 group-hover:text-green-800",
  [DealStageStatus.Overdue]: "text-orange-600",
  [DealStageStatus.Current]: "text-green-500",
  [DealStageStatus.Upcoming]: "text-gray-500/70 group-hover:text-gray-700",
};

function DealStepStatus(props: {
  step: Step;
  runner: string;
  dealFirmGroupId?: string;
}) {
  const { step, runner } = props;
  return (
    <>
      <div
        className={`w-full group flex flex-col border-l-4 ${
          dealStepStatusBorderColors[step.status]
        } py-2 pl-4  md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 ${
          !props.dealFirmGroupId
            ? "cursor-default opacity-80 "
            : "cursor-pointer"
        }`}
      >
        <>
          <div className="flex items-center justify-between">
            <span
              className={`text-sm truncate font-medium ${
                dealStepStatusTextColors[step.status]
              }`}
            >
              {step.name}
            </span>
            {props.dealFirmGroupId ? (
              <PencilSquareIcon className="w-4 h-4 group-hover:opacity-100 opacity-0 text-gray-500/80" />
            ) : null}
          </div>

          <span className="text-xs font-medium text-gray-500">
            {step.firmCount === 0
              ? `0 ${runner}s`
              : step.firmCount
              ? `${step.firmCount} ${runner}${step.firmCount > 1 ? "s" : ""}`
              : ""}
          </span>
        </>
      </div>
    </>
  );
}
