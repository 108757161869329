import { useState } from "react";
import Loading from "../../components/Loading";
import { TextInput } from "../../components/tailwind/TextInput";
import {
  AccountQuery,
  useAccountQuery,
  useUpdateAccountMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { Avatar } from "../../components/account/Avatar";
import { Button } from "../../components/tailwind/Button";
import { useQueryClient } from "@tanstack/react-query";
import { UserProfile } from "@clerk/clerk-react";
import { PageContent } from "../../components/PageContent";

export function Settings() {
  return (
    <div className="flex-1">
      <PageContent>
        <UserProfile
          appearance={{
            variables: {},
            elements: {
              card: "box-shadow: 0 0 #0000;",
            },
          }}
        />
      </PageContent>
    </div>
  );
}

function SettingsContent() {
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);

  if (accountQuery.error) {
    return <div>Something went wrong</div>;
  }

  if (!accountQuery.data || accountQuery.isPending) {
    return <Loading />;
  }

  return (
    <div>
      <p className="text-lg font-gray-800 font-semibold mb-3">Account</p>

      <Avatar account={accountQuery.data.account} size="xxl" />
      <UpdateAccountForm account={accountQuery.data.account} />
    </div>
  );
}

function UpdateAccountForm(props: { account: AccountQuery["account"] }) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const updateAccount = useUpdateAccountMutation(client);

  const [name, setName] = useState(props.account.name);
  const [email, setEmail] = useState(props.account.email);

  return (
    <form
      className="mt-4 w-full xl:w-1/4"
      onSubmit={(e) => {
        e.preventDefault();

        updateAccount.mutate(
          {
            input: {
              name,
              email,
            },
          },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries({
                queryKey: ["Account", {}],
              });
            },
          }
        );
      }}
    >
      <TextInput
        label="Name"
        value={name}
        onChange={(e) => {
          setName(e.currentTarget.value);
        }}
      />
      <TextInput
        disabled
        margin="m 0 0 0"
        label="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.currentTarget.value);
        }}
      />
      {props.account.email !== email ? (
        <p className="text-sm mt-0.5 text-gray-500">
          You will need to re-verify your email if you change it
        </p>
      ) : null}

      <div className="flex justify-end mt-6">
        <Button
          type="submit"
          text="Save"
          variant="positive"
          isLoading={updateAccount.isPending}
          loadingText="Saving..."
        />
      </div>
      {updateAccount.error ? (
        <div className="text-red-600">Failed to update account</div>
      ) : null}
    </form>
  );
}
