import { Route, Switch, useHistory } from "react-router-dom";
import { H3 } from "../../components/Heading";
import { CreateDeal } from "./CreateDeal";
import { DataRoomPermissions } from "./DataRoomPermissions";
import { People } from "./People";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { Card } from "../../components/Card";
import { Button } from "../../components/tailwind/Button";

export function CreateDealFlow() {
  const history = useHistory();
  const account = useSelector(authSelectors.account);
  if (!account) {
    return null;
  }

  return (
    <div className="flex-1">
      <div className="bg-white sticky z-20 top-0 px-8 py-6 w-full border-b shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <H3>Create new deal</H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
        </div>
      </div>
      <div className="p-8 flex-1">
        {!account.firm ? (
          <div className="justify-center flex items-center flex-1 ">
            <Card padding="m">
              <p className="font-semibold text-gray-800">
                Setup your firm to create a deal
              </p>

              <p className="text-sm font-normal text-gray-500">
                Setting up your firm allows you to add everyone in your team,
                oversee their deals and quickly add them to new ones.
              </p>

              <div className="mt-8">
                <Button
                  text="Setup my firm"
                  variant="neutral"
                  onClick={() => {
                    history.push("/firm");
                  }}
                />
              </div>
            </Card>
          </div>
        ) : (
          <Switch>
            <Route path="/create-deal/" exact component={CreateDeal} />
            <Route
              path="/create-deal/:dealId/data-room"
              component={DataRoomPermissions}
            />
            <Route path="/create-deal/:dealId/people" component={People} />
          </Switch>
        )}
      </div>
    </div>
  );
}
