import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";

export function TripleDotMenu(props: {
  children: React.ReactNode;
  width?: string;
}) {
  const width = props.width ? props.width : "w-32";
  return (
    <Menu as="div" className="relative flex-none ">
      <Menu.Button
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
        }}
        className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-900"
      >
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute right-0 z-50 mt-2 ${width} origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none `}
        >
          {props.children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
