import { NavLink, useParams } from "react-router-dom";
import {
  AnswerReference,
  DataRoomFile,
  FileType,
  QuestionQuery,
  SuggestedAnswerStatus,
  useCreateCommentMutation,
  useCreateQuestionAnswerMutation,
  useDataRoomFileQuery,
  useQuestionQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { H3, H4 } from "../../components/Heading";
import { Card } from "../../components/Card";
import { useEffect, useRef, useState } from "react";
import { AddFilePill } from "../../components/AddFilePill";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { FilePill } from "../../components/FilePill";
import { Avatar } from "../../components/account/Avatar";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "../../components/tailwind/Button";
import { Pills } from "../../components/Pills";
import { ActivityItem } from "../../components/activity/ActivityItem";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Markdown from "react-markdown";
import {
  adminRoles,
  DealRoleRestricted,
} from "../../components/DealRoleRestricted";
import { classNames } from "@/src/utils/cn";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import Loading from "../../components/Loading";
import {
  BotIcon,
  ExternalLinkIcon,
  FileStackIcon,
  InfoIcon,
  PanelRightCloseIcon,
} from "lucide-react";
import { FileIcon } from "@/src/components/FileIcon";
import { SlideOver } from "@/src/components/SlideOver";
import { FileViewer } from "@/src/components/FileViewer";
import { Spinner } from "@/src/components/icons/Spinner";

export function Question() {
  const { id } = useParams<{ id: string }>();

  const client = useGqlClient();
  const { data, isLoading, error } = useQuestionQuery(
    client,
    { id },
    {
      refetchInterval(query) {
        if (
          query.state.data &&
          query.state.data.question.suggestedAnswer &&
          query.state.data.question.suggestedAnswer.status ===
            SuggestedAnswerStatus.Complete
        ) {
          return false;
        }

        return 1000;
      },
    }
  );

  return (
    <div className="flex-1 mb-8 flex flex-col">
      <div className="bg-white fixed z-20 px-8 py-3 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/questions">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Questions
              </h4>
            </NavLink>
            {data && data.question.ddql ? (
              <>
                <p className="font-light text-gray-400">/</p>
                <NavLink to={`/questions/lists/${data.question.ddql.id}`}>
                  <h4 className="font-bold text-gray-400 hover:text-gray-700">
                    {!data || isLoading
                      ? "Loading..."
                      : data.question.ddql.name}
                  </h4>
                </NavLink>
              </>
            ) : null}
            <p className="font-light text-gray-400">/</p>
            <H4>{data ? data.question.title : "..."}</H4>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8 flex-1">
        <QuestionInner isLoading={isLoading} error={error} data={data} />
      </div>
    </div>
  );
}

function QuestionInner(props: {
  isLoading: boolean;
  data?: QuestionQuery;
  error: any;
}) {
  if (props.error) {
    return (
      <div className="flex-1 justify-center items-center">
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.isLoading || !props.data) {
    return (
      <div className="flex-1 justify-center items-center">
        <Loading />
      </div>
    );
  }

  return <QuestionContent question={props.data.question} />;
}

function QuestionContent(props: { question: QuestionQuery["question"] }) {
  const [answer, setAnswer] = useState("");
  const [answerFiles, setAnswerFiles] = useState<AnswerReference[]>([]);

  const [files, setFiles] = useState<DataRoomFile[]>([]);
  const account = useSelector(authSelectors.account);

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const createQuestionAnswer = useCreateQuestionAnswerMutation(client);

  const [selectedReference, setSelectedReference] = useState<
    AnswerReference | undefined
  >(undefined);

  if (!account) {
    return null;
  }

  return (
    <div className="p-8 flex-1">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <div className="flex space-x-2 mt-2">
            {props.question.createdBy ? (
              <div className="mt-2">
                <Avatar account={props.question.createdBy} />
              </div>
            ) : null}
            <div className="flex-1">
              <Card padding="m">
                <p className=" text-gray-600 font-semibold">
                  <Markdown>{props.question.title}</Markdown>
                </p>
                <p className="whitespace-pre-wrap text-sm text-gray-600 mt-2">
                  <Markdown>{props.question.body}</Markdown>
                </p>
                {props.question.dataRoomFile ? (
                  <Pills>
                    <FilePill
                      id={props.question.dataRoomFile.id}
                      name={props.question.dataRoomFile.name}
                      type={props.question.dataRoomFile.fileType}
                    />
                  </Pills>
                ) : null}
              </Card>
            </div>
          </div>
          <div className="mt-6">
            <H3>Answer</H3>
            <div className="mt-6 flex gap-x-3 ">
              {props.question.answer ? (
                <div className="flex space-x-2 w-full">
                  {props.question.answer.answeredBy ? (
                    <div className="mt-2">
                      <Avatar account={props.question.answer.answeredBy} />
                    </div>
                  ) : null}
                  <div className="flex-1 w-full">
                    <Card padding="s m">
                      <div className="flex items-center justify-between">
                        <p className="font-semibold text-gray-700 text-sm">
                          Answered by {props.question.answer.answeredBy.name}
                        </p>
                        <p className="text-xs text-gray-500/80">
                          {formatDistanceToNow(
                            fromUnixTime(props.question.answer.createdAt),
                            {
                              addSuffix: true,
                            }
                          )}
                        </p>
                      </div>
                      <p className="whitespace-pre-wrap text-sm text-gray-700 mt-2">
                        <Markdown>{props.question.answer.answer}</Markdown>
                      </p>
                      <Pills margin="l 0 0 0">
                        {props.question.answer.answerFiles.map((file) => {
                          return (
                            <div
                              key={file.file.id}
                              className="flex items-center"
                            >
                              <FilePill
                                id={file.file.id}
                                name={file.file.name}
                                type={file.file.fileType}
                                pageIndex={file.pageIndex ?? undefined}
                                rectsOnPage={file.rectsOnPage}
                                checkFileAccessAccountId={
                                  props.question.createdBy.id
                                }
                                noAccessMessage={`${props.question.createdBy.name} does not have access to this file`}
                                onRemove={() => {
                                  setFiles(
                                    files.filter((f) => f.id !== file.file.id)
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                      </Pills>
                    </Card>
                  </div>
                </div>
              ) : (
                <div className="flex space-x-2 w-full">
                  <Avatar account={account} />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();

                      if (!answer) {
                        return;
                      }

                      createQuestionAnswer.mutate(
                        {
                          questionId: props.question.id,
                          answer,
                          dataRoomFileIds: files.map((f) => f.id),
                          answerFiles: answerFiles.map((f) => {
                            return {
                              dataRoomFileID: f.file.id,
                              pageIndex: f.pageIndex,
                              rectsOnPage: f.rectsOnPage,
                            };
                          }),
                        },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries({
                              queryKey: ["Question", { id: props.question.id }],
                            });
                          },
                        }
                      );
                    }}
                    className="relative flex-auto"
                  >
                    <div className="overflow-hidden rounded-lg pb-12 focus-within:bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-1 focus-within:ring-indigo-600">
                      <label htmlFor="comment" className="sr-only">
                        Write your answer
                      </label>
                      <textarea
                        rows={6}
                        name="comment"
                        id="comment"
                        className="p-4 focus:outline-none block w-full no-scrollbar resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Write your answer..."
                        defaultValue={""}
                        value={answer}
                        onChange={(e) => {
                          setAnswer(e.currentTarget.value);
                        }}
                      />
                    </div>

                    <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                      <div className="flex items-center py-1 space-x-2 overflow-x-scroll no-scrollbar mr-2">
                        {answerFiles.map((file) => {
                          return (
                            <div
                              key={file.file.id}
                              className="flex items-center"
                            >
                              <FilePill
                                id={file.file.id}
                                name={file.file.name}
                                type={file.file.fileType}
                                pageIndex={file.pageIndex ?? undefined}
                                rectsOnPage={file.rectsOnPage}
                                checkFileAccessAccountId={
                                  props.question.createdBy.id
                                }
                                showDetailsCard={false}
                                noAccessMessage={`${props.question.createdBy.name} does not have access to this file`}
                                onRemove={() => {
                                  setFiles(
                                    files.filter((f) => f.id !== file.file.id)
                                  );
                                }}
                              />
                            </div>
                          );
                        })}
                        <AddFilePill
                          onFileSelected={(file) => {
                            if (files.find((f) => f.id === file.id)) {
                              return;
                            }

                            setFiles([...files, file]);
                          }}
                        />
                      </div>
                      <Button
                        isLoading={createQuestionAnswer.isPending}
                        loadingText="Answering..."
                        size="s"
                        variant="neutral"
                        type="submit"
                        text="Answer"
                      />
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
          <DealRoleRestricted roles={adminRoles}>
            <SuggestedAnswerWrapper
              question={props.question}
              setAnswer={setAnswer}
              setAnswerFiles={setAnswerFiles}
              setSelectedReference={setSelectedReference}
              selectedReference={selectedReference}
            />
          </DealRoleRestricted>
        </div>

        <div>
          <H3>Activity</H3>
          <Activity question={props.question} />
        </div>
      </div>
    </div>
  );
}

function Activity(props: { question: QuestionQuery["question"] }) {
  const account = useSelector(authSelectors.account);
  const [isFocused, setIsFocused] = useState(false);

  const queryClient = useQueryClient();
  const client = useGqlClient();

  const createComment = useCreateCommentMutation(client);

  const [files, setFiles] = useState<DataRoomFile[]>([]);
  const [comment, setComment] = useState("");

  const createCommentRef = useRef<HTMLButtonElement>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (createCommentRef.current) {
        createCommentRef.current.click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function clearForm() {
    setComment("");
    setFiles([]);
  }

  function onSubmit() {
    if (!comment) {
      return;
    }

    createComment.mutate(
      {
        input: {
          questionId: props.question.id,
          comment,
          files: files.map((f) => f.id),
          dataRoomFileId: "",
          dataRoomFolderId: "",
          dataRoomId: "",
          dataRoomFileErrorId: "",
        },
      },
      {
        onSuccess: () => {
          clearForm();
          queryClient.invalidateQueries({
            queryKey: ["Question", { id: props.question.id }],
          });
        },
      }
    );
  }

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    setTimeout(() => {
      if (
        !document.activeElement ||
        !document.activeElement.closest("#comment_form")
      ) {
        setIsFocused(false);
      }
    }, 0);
  };

  if (!account) {
    return null;
  }

  return (
    <>
      <ul role="list">
        <TransitionGroup className="space-y-6 mt-2">
          {props.question.activity
            .map((activity, i) => {
              return (
                <CSSTransition
                  key={activity.id}
                  timeout={300} // This controls the duration of the animation
                  classNames="fade-slide-down"
                  onEnter={(node: any) => node.offsetHeight} // Trigger reflow to enable animation
                >
                  <ActivityItem
                    key={activity.id}
                    activity={activity}
                    isFirst={i === props.question.activity.length - 1}
                    isLast={i === 0}
                  />
                </CSSTransition>
              );
            })
            .reverse()}
        </TransitionGroup>
      </ul>

      {/* New comment form */}
      <div className="mt-6 flex gap-x-3 " onBlur={handleBlur}>
        <Avatar account={account} />
        <form
          id="comment_form"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="relative flex-auto"
        >
          <div
            className={`overflow-hidden rounded-lg ${
              isFocused ? "pb-12" : "pb-1"
            } shadow-sm ring-1 ring-inset transition-all ease-in-out duration-300 ring-gray-300 focus-within:bg-white focus-within:ring-indigo-500 focus:ring-2`}
          >
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={isFocused ? 2 : 1}
              name="comment"
              id="comment"
              onFocus={handleFocus}
              onBlur={handleBlur}
              className="p-4 block ring-0 w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:border-0 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              value={comment}
              onChange={(e) => {
                setComment(e.currentTarget.value);
              }}
              defaultValue={""}
            />
          </div>

          {/* <div
            className={`ring-0  absolute top-0 right-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
              isFocused
                ? "opacity-100 visible duration-300 delay-100 "
                : " opacity-0 invisible duration-150 delay-0"
            }`}
          >
            <Dropdown
              options={[{ value: 1, label: "Visible to sell side" }]}
              defaultValue="Visible to sell side"
              dropdownType="text"
              onSelect={() => {}}
            />
          </div> */}

          <div
            className={`ring-0  absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2 transition-all   ease-in-out ${
              isFocused
                ? "opacity-100 visible duration-300 delay-100 "
                : " opacity-0 invisible duration-150 delay-0"
            }`}
          >
            <Pills>
              {files.map((file) => {
                return (
                  <FilePill
                    id={file.id}
                    name={file.name}
                    type={file.fileType}
                    checkFileAccessAccountId={props.question.createdBy.id}
                    noAccessMessage={`${props.question.createdBy.name} does not have access to this file`}
                    onRemove={() => {
                      setFiles(files.filter((f) => f.id !== file.id));
                    }}
                  />
                );
              })}
              <AddFilePill
                onFileSelected={(file) => {
                  if (files.find((f) => f.id === file.id)) {
                    return;
                  }

                  setFiles([...files, file]);
                }}
              />
            </Pills>
            <Button
              ref={createCommentRef}
              variant="neutral"
              size="s"
              text="Comment"
              isLoading={createComment.isPending}
              loadingText="Commenting..."
              type="submit"
            />
          </div>
        </form>
      </div>
    </>
  );
}

function SuggestedAnswerStatusText(props: { status: SuggestedAnswerStatus }) {
  let statusText = "";
  if (
    props.status === SuggestedAnswerStatus.Initial ||
    props.status === SuggestedAnswerStatus.FindingRelevantFiles
  ) {
    statusText = "Finding relevant files...";
  } else if (props.status === SuggestedAnswerStatus.SynthesizingAnswer) {
    statusText = "Generating answer...";
  } else if (props.status === SuggestedAnswerStatus.CheckingAnswer) {
    statusText = "Checking answer...";
  }

  return (
    <Card padding="m" margin="l 0 0 xxl">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <BotIcon className="w-4 h-4 text-persian-500" />
          <p className="text-sm text-persian-500 font-semibold">{statusText}</p>
        </div>
        <Spinner color="gray" size="s" />
      </div>
    </Card>
  );
}

function SuggestedAnswerWrapper(props: {
  question: QuestionQuery["question"];
  setAnswer: (answer: string) => void;
  setAnswerFiles: (files: AnswerReference[]) => void;
  setSelectedReference: (ref: AnswerReference | undefined) => void;
  selectedReference: AnswerReference | undefined;
}) {
  if (
    props.question.suggestedAnswer &&
    props.question.suggestedAnswer.status !== SuggestedAnswerStatus.Complete
  ) {
    return (
      <SuggestedAnswerStatusText
        status={props.question.suggestedAnswer.status}
      />
    );
  }

  return (
    <div>
      <SuggestedAnswer
        question={props.question}
        onSelected={(answer, files) => {
          props.setAnswer(answer);
          props.setAnswerFiles(files);
        }}
        onReferenceSelected={(ref) => {
          props.setSelectedReference(ref);
        }}
      />
      <OtherAnswers
        question={props.question}
        onReferenceSelected={(ref) => {
          props.setSelectedReference(ref);
        }}
      />
      <References
        question={props.question}
        onReferenceSelected={(ref) => {
          props.setSelectedReference(ref);
        }}
      />
      <Reference
        reference={props.selectedReference}
        open={!!props.selectedReference}
        onClose={() => props.setSelectedReference(undefined)}
      />
    </div>
  );
}

function OtherAnswers(props: {
  question: QuestionQuery["question"];
  onReferenceSelected: (reference: AnswerReference) => void;
}) {
  const question = props.question;
  const account = useSelector(authSelectors.account);

  const [expanded, setExpanded] = useState(true);

  if (question.answer) {
    return null;
  }

  if (!question.suggestedAnswer) {
    return null;
  }

  return (
    <>
      <div className="ml-8 mt-3 transition-all duration-300 ease-in-out p-3 rounded-md shadow bg-white">
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {expanded ? (
                <ChevronDownIcon
                  onClick={() => setExpanded(false)}
                  className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
                />
              ) : (
                <ChevronRightIcon
                  onClick={() => setExpanded(true)}
                  className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
                />
              )}
              <BotIcon className="w-4 h-4 text-persian-500" />
              <p className="text-persian-500 font-semibold text-sm">
                Other answers
              </p>
            </div>
          </div>
        </div>

        {expanded ? (
          <div>
            <div
              className={classNames(
                "w-full bg-gray-200 h-px mt-3",
                expanded ? "mb-3" : ""
              )}
            ></div>
            <div className="flex gap-x-4 overflow-x-scroll no-scrollbar pb-2">
              {question.suggestedAnswer.otherAnswers.map((otherAnswer) => {
                return (
                  <div className="shadow-sm hover:shadow-md p-3 rounded-md bg-white border border-gray-200">
                    <p className="text-sm ml-1 text-gray-600">
                      <Markdown>{otherAnswer.answer ?? ""}</Markdown>
                    </p>
                    <Pills>
                      {otherAnswer.references.map((ref) => {
                        return (
                          <ReferencePill
                            key={ref.file.id}
                            reference={ref as AnswerReference}
                            onReferenceSelected={(ref) => {
                              props.onReferenceSelected(ref);
                            }}
                          />
                        );
                      })}
                    </Pills>
                  </div>
                );
              })}
              <div className="">
                {/* <div className="mt-2">
                {question.suggestedAnswer.quotes
                  ? question.suggestedAnswer.quotes.map((quote) => {
                      return (
                        <div
                          className="p-3 bg-white border my-1 border-gray-200 rounded-md"
                          key={quote.quote}
                        >
                          <p className="text-sm text-gray-600">{quote.quote}</p>
                        </div>
                      );
                    })
                  : null}
              </div> */}

                <div className="mt-3 flex justify-end"></div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

function SuggestedAnswer(props: {
  question: QuestionQuery["question"];
  onSelected: (answer: string, files: AnswerReference[]) => void;
  onReferenceSelected: (reference: AnswerReference) => void;
}) {
  const question = props.question;
  const account = useSelector(authSelectors.account);

  const [expanded, setExpanded] = useState(true);

  if (question.answer) {
    return null;
  }

  if (!question.suggestedAnswer) {
    return null;
  }

  return (
    <>
      <div className="ml-8 mt-3 transition-all duration-300 ease-in-out p-3 rounded-md shadow bg-white">
        <div>
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {expanded ? (
                <ChevronDownIcon
                  onClick={() => setExpanded(false)}
                  className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
                />
              ) : (
                <ChevronRightIcon
                  onClick={() => setExpanded(true)}
                  className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
                />
              )}
              <BotIcon className="w-4 h-4 text-persian-500" />
              <p className="text-persian-500 font-semibold text-sm">
                Suggested Answer
              </p>
            </div>
          </div>
          {question.suggestedAnswer.synthesizedAnswer &&
          question.suggestedAnswer.synthesizedAnswer.reason &&
          question.suggestedAnswer.otherAnswers.length > 0 ? (
            <div className="p-4 bg-persian-50 rounded-md mt-3">
              <div className="flex items-center space-x-2">
                <InfoIcon className="w-4 h-4 text-persian-900" />
                <p className="text-xs text-persian-900 font-semibold">
                  Reason for picking this answer
                </p>
              </div>
              <p className="text-xs text-persian-900 mt-2">
                {question.suggestedAnswer.synthesizedAnswer.reason}
              </p>
            </div>
          ) : null}
        </div>

        {expanded ? (
          <div>
            <div
              className={classNames(
                "w-full bg-gray-200 h-px mt-3",
                expanded ? "mb-3" : ""
              )}
            ></div>
            <div className="flex gap-x-4">
              <div className="">
                <p className="text-sm ml-1 text-gray-600">
                  <Markdown>
                    {question.suggestedAnswer.synthesizedAnswer?.answer ?? ""}
                  </Markdown>
                </p>
                {/* <div className="mt-2">
                {question.suggestedAnswer.quotes
                  ? question.suggestedAnswer.quotes.map((quote) => {
                      return (
                        <div
                          className="p-3 bg-white border my-1 border-gray-200 rounded-md"
                          key={quote.quote}
                        >
                          <p className="text-sm text-gray-600">{quote.quote}</p>
                        </div>
                      );
                    })
                  : null}
              </div> */}
                {question.suggestedAnswer.synthesizedAnswer &&
                question.suggestedAnswer.synthesizedAnswer.references.length >
                  0 ? (
                  <Pills>
                    {question.suggestedAnswer.synthesizedAnswer.references.map(
                      (ref) => {
                        return (
                          <ReferencePill
                            key={ref.file.id}
                            reference={ref as AnswerReference}
                            onReferenceSelected={(ref) => {
                              props.onReferenceSelected(ref);
                            }}
                          />
                        );
                      }
                    )}
                  </Pills>
                ) : null}

                <div className="mt-3 flex justify-end">
                  <button
                    type="submit"
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => {
                      if (!question.suggestedAnswer) {
                        return;
                      }
                      props.onSelected(
                        question.suggestedAnswer.synthesizedAnswer?.answer ??
                          "",
                        (question.suggestedAnswer.synthesizedAnswer
                          ?.references as AnswerReference[]) ?? []
                      );
                    }}
                  >
                    Use answer
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

function ReferencePill(props: {
  reference: AnswerReference;
  onReferenceSelected: (reference: AnswerReference) => void;
}) {
  return (
    <button
      onClick={() => {
        props.onReferenceSelected(props.reference);
      }}
      className="flex flex-wrap group justify-center text-left relative"
    >
      <div className="cursor-pointer hover:border-gray-400 hover:shadow-sm rounded-2xl px-2 py-1 bg-white border border-gray-200 flex items-center space-x-2">
        <FileIcon size="s" fileType={props.reference.file.fileType} />
        <p className="text-xs ml-1 text-gray-800 truncate">
          {props.reference.file.name}
        </p>
        {props.reference.pageIndex !== undefined ? (
          <p className="text-xs truncate text-gray-500/80 ml-1 inline">
            Page {props.reference.pageIndex + 1}
          </p>
        ) : null}
      </div>
      {props.reference.quote ? (
        <span
          className={`transition-all p-3 z-20 delay-300  absolute ${"bottom-8"} rounded-md shadow scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 border border-gray-200 bg-white w-64`}
        >
          <div className="p-3 rounded-md border border-gray-200">
            <p className="text-xs font-semibold text-gray-700">
              {props.reference.quote}
            </p>
          </div>
        </span>
      ) : null}
    </button>
  );
}

function Reference(props: {
  reference?: AnswerReference;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <SlideOver
      open={props.open}
      onClose={props.onClose}
      title="Reference"
      maxWidth="max-w-3xl"
    >
      <div>
        <div className="border-b border-gray-200 shadow-sm p-4 flex justify-between space-x-2">
          <div className="flex items-center space-x-2">
            <PanelRightCloseIcon
              onClick={() => props.onClose()}
              className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
            />
            <FileIcon
              fileType={props.reference?.file.fileType ?? FileType.Other}
            />
            <p className="text-sm text-gray-800 font-semibold">
              {props.reference?.file.name}
            </p>
          </div>
          <div>
            <Button
              variant="neutral"
              size="s"
              text="Go to document"
              icon={ExternalLinkIcon}
            />
          </div>
        </div>
        <div className="h-full flex flex-col">
          {props.reference ? (
            <ReferenceFileView reference={props.reference} />
          ) : null}
        </div>
      </div>
    </SlideOver>
  );
}

function ReferenceFileView(props: { reference: AnswerReference }) {
  const client = useGqlClient();
  const dataRoomFile = useDataRoomFileQuery(client, {
    id: props.reference.file.id,
  });

  if (dataRoomFile.isLoading || !dataRoomFile.data) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  if (dataRoomFile.error) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <p className="text-sm text-gray-500/80">
          Error loading file. Please try again.
        </p>
      </div>
    );
  }

  const file = dataRoomFile.data.dataRoomFile;
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <FileViewer
        dataRoomFileId={file.id}
        dataRoomFileVersionId={file.currentLiveVersion.id}
        pageIndex={props.reference.pageIndex}
        rectsOnPage={props.reference.rectsOnPage}
      />
    </div>
  );
}

type GroupedReference = {
  file: Pick<DataRoomFile, "id" | "name" | "fileType">;
  quotes: AnswerReference[];
};

function References(props: {
  question: QuestionQuery["question"];
  onReferenceSelected: (ref: AnswerReference) => void;
}) {
  const question = props.question;
  const account = useSelector(authSelectors.account);

  const [expanded, setExpanded] = useState(false);

  const [groupedReferences, setGroupedReferences] = useState<
    GroupedReference[]
  >([]);

  useEffect(() => {
    if (!question.suggestedAnswer) {
      return;
    }

    const groupedReferences: GroupedReference[] = [];

    const references = [
      ...(question.suggestedAnswer.synthesizedAnswer?.references ?? []),
      ...question.suggestedAnswer.otherAnswers.flatMap((a) => a.references),
    ];
    references.forEach((ref) => {
      const existingGroup = groupedReferences.find(
        (g) => g.file.id === ref.file.id
      );
      if (existingGroup) {
        existingGroup.quotes.push({
          pageIndex: ref.pageIndex,
          rectsOnPage: ref.rectsOnPage,
          quote: ref.quote,
          file: ref.file as DataRoomFile,
        });
      } else {
        groupedReferences.push({
          file: ref.file,
          quotes: [
            {
              pageIndex: ref.pageIndex,
              rectsOnPage: ref.rectsOnPage,
              quote: ref.quote,
              file: ref.file as DataRoomFile,
            },
          ],
        });
      }
    });

    setGroupedReferences(groupedReferences);
  }, [question.suggestedAnswer]);

  if (question.answer) {
    return null;
  }

  if (!question.suggestedAnswer) {
    return null;
  }

  return (
    <>
      <div className="ml-8 mt-3 transition-all duration-300 ease-in-out p-3 rounded-md shadow bg-white">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2">
            {expanded ? (
              <ChevronDownIcon
                onClick={() => setExpanded(false)}
                className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
              />
            ) : (
              <ChevronRightIcon
                onClick={() => setExpanded(true)}
                className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer"
              />
            )}
            <FileStackIcon className="w-4 h-4 text-persian-500" />
            <p className="text-persian-500 font-semibold text-sm">
              All References
            </p>
          </div>
        </div>

        {expanded ? (
          <div>
            <div
              className={classNames(
                "w-full bg-gray-200 h-px mt-3",
                expanded ? "mb-3" : ""
              )}
            ></div>
            <div className="flex gap-4">
              <div className="flex gap-4 flex-col">
                {groupedReferences.map((group) => {
                  return (
                    <div key={group.file.id}>
                      <div className="flex items-center space-x-2 mb-2">
                        <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
                          <FileIcon size="s" fileType={group.file.fileType} />
                          <p className="text-xs ml-1 text-gray-800 truncate">
                            {group.file.name}
                          </p>
                        </div>
                      </div>
                      <div className="ml-3 ">
                        {group.quotes.map((quote) => {
                          return (
                            <div
                              key={quote.quote}
                              className="p-3 hover:border-gray-300 cursor-pointer bg-white border my-1 border-gray-200 rounded-md"
                              onClick={() => {
                                props.onReferenceSelected(quote);
                              }}
                            >
                              <p className="text-xs text-gray-500/80">
                                Page {quote.pageIndex + 1}
                              </p>
                              <p className="text-sm text-gray-600">
                                {quote.quote}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
