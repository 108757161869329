import { useQueryClient } from "@tanstack/react-query";
import {
  AccessQuery,
  DealGroupQuery,
  useUpdateDealGroupMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { useEffect, useState } from "react";
import {
  GranularAccess,
  granularAccessToFileInput,
  granularAccessToFolderInput,
} from ".";
import { AnimatedModal } from "../../components/AnimatedModal";
import { EditDataRoomPermissions } from "./EditDataRoomPermissions";
import { Button } from "../../components/tailwind/Button";
import { H4 } from "../../components/Heading";
import { CloseIcon } from "../../components/CloseIcon";
import { TextInput } from "../../components/tailwind/TextInput";
import { classNames } from "../../utils/cn";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { toasts } from "@/src/components/toasts/toasts";

export function EditDealGroup(props: {
  dealGroup: DealGroupQuery["dealGroup"];
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  open: boolean;
  onClose: () => void;
  dealId: string;
}) {
  const [name, setName] = useState(props.dealGroup ? props.dealGroup.name : "");
  const [nameError, setNameError] = useState("");
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const updateDealGroup = useUpdateDealGroupMutation(client);

  const [overrideDataRoomAccess, setOverrideDataRoomAccess] = useState(false);

  const [granularAccess, setGranularAccess] = useState<GranularAccess>({
    files: {},
    folders: {},
  });

  const [selectedDataRoomPermission, setSelectedDataRoomPermission] = useState<
    DealGroupQuery["dealGroup"]["dataRoomPermission"]
  >(
    props.dealGroup ? props.dealGroup.dataRoomPermission : props.permissions[0]
  );

  useEffect(() => {
    if (!props.dealGroup) {
      return;
    }

    setName(props.dealGroup.name);
    setOverrideDataRoomAccess(props.dealGroup.overrideDataRoomAccess);
    setSelectedDataRoomPermission(props.dealGroup.dataRoomPermission);
  }, [props.dealGroup]);

  if (!props.dealGroup) {
    return null;
  }

  return (
    <AnimatedModal
      key={props.dealGroup.id}
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      size="xxl"
    >
      <div>
        <div className="flex items-center justify-between">
          <H4 margin="0">Edit {props.dealGroup.name}</H4>
          <CloseIcon onClose={props.onClose} />
        </div>
        <div>
          <div className="">
            <div className="mt-3 w-2/3">
              <TextInput
                margin="0"
                label="Name"
                value={name}
                onChange={(e) => {
                  setNameError("");
                  setName(e.currentTarget.value);
                }}
                error={nameError}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="font-semibold text-gray-600 text-sm">
            Permission level
          </p>
          <div className="mt-2 flex gap-x-3">
            {props.permissions.map((permission) => {
              return (
                <button
                  key={permission.id}
                  onClick={() => {
                    setSelectedDataRoomPermission(permission);
                  }}
                  className={classNames(
                    "p-2 rounded-md border-2 relative  group",
                    overrideDataRoomAccess ? "border-gray-300 opacity-60" : "",
                    !overrideDataRoomAccess &&
                      permission.id === selectedDataRoomPermission.id
                      ? "border-indigo-500"
                      : "",
                    !overrideDataRoomAccess &&
                      permission.accessLevel <
                        selectedDataRoomPermission.accessLevel
                      ? "border-indigo-500 opacity-60"
                      : "",
                    !overrideDataRoomAccess &&
                      permission.accessLevel >
                        selectedDataRoomPermission.accessLevel
                      ? "opacity-50 "
                      : ""
                  )}
                >
                  <div className="flex items-center justify-between">
                    <p className="text-sm font-semibold text-gray-800">
                      {permission.name}
                    </p>
                    {permission.id === selectedDataRoomPermission.id ||
                    permission.accessLevel <
                      selectedDataRoomPermission.accessLevel ? (
                      <CheckCircleIcon
                        className={`w-4 h-4 ${
                          overrideDataRoomAccess
                            ? "text-gray-500"
                            : "text-indigo-500"
                        }`}
                      />
                    ) : null}
                  </div>
                  <p className="leading-tight mt-1 text-sm text-gray-500">
                    {permission.description}
                  </p>
                </button>
              );
            })}
          </div>
        </div>
        <EditDataRoomPermissions
          dealGroupId={props.dealGroup.id}
          showPermissions={false}
          initialDataRoomPermission={props.dealGroup.dataRoomPermission}
          initialGranularAccess={props.dealGroup.granularAccess}
          dataRoomPermissions={props.permissions}
          overrideDataRoomAccess={overrideDataRoomAccess}
          onOverrideDataRoomAccessChange={(checked) => {
            setOverrideDataRoomAccess(checked);
          }}
          granularAccess={granularAccess}
          onGranularAccessChange={(granularAccess) => {
            setGranularAccess(granularAccess);
          }}
        />
        <div className="w-full h-px bg-gray-200 my-4"></div>
        <div className="flex justify-end mt-5">
          <Button
            text="Save"
            variant="positive"
            isLoading={updateDealGroup.isPending}
            loadingText="Saving..."
            onClick={() => {
              if (!name) {
                setNameError("Name is required");
                return;
              }

              updateDealGroup.mutate(
                {
                  input: {
                    id: props.dealGroup.id,
                    filePermissions: granularAccessToFileInput(granularAccess),
                    folderPermissions:
                      granularAccessToFolderInput(granularAccess),
                    name,
                    overrideDataRoomAccess,
                    dataRoomPermissionId: selectedDataRoomPermission.id,
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: ["DealGroup", { id: props.dealGroup.id }],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ["Access", { dealId: props.dealId }],
                    });
                    props.onClose();
                  },
                  onError: (err) => {
                    toasts.error("Failed to update group");
                    console.log(err);
                  },
                }
              );
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}
