import { useHistory, useParams } from "react-router-dom";
import {
  DataRoomPermissionInput,
  DealRunner,
  useDataRoomQuery,
  useDeleteDataRoomPermissionMutation,
  useUpdateDataRoomMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { Steps } from "./CreateDeal";
import Loading from "../../components/Loading";
import { useEffect, useState } from "react";
import { TextInput } from "../../components/tailwind/TextInput";
import { PlusIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Card } from "../../components/Card";
import { Button } from "../../components/tailwind/Button";
import { useQueryClient } from "@tanstack/react-query";

type DataRoomPermissionItem = { id: string } & DataRoomPermissionInput;

export function DataRoomPermissions() {
  const { dealId } = useParams<{ dealId: string }>();
  const history = useHistory();

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const dataRoomQuery = useDataRoomQuery(client, {
    dealId,
  });

  const updateDataRoom = useUpdateDataRoomMutation(client);
  const deletePermission = useDeleteDataRoomPermissionMutation(client);

  const [dataRoomPermissions, setDataRoomPermissions] = useState<
    DataRoomPermissionItem[]
  >([]);

  useEffect(() => {
    if (dataRoomQuery.data) {
      setDataRoomPermissions(
        dataRoomQuery.data.deal.dataRoom.permissions.map((permission) => {
          return {
            id: permission.id,
            name: permission.name,
            accessLevel: permission.accessLevel,
            description: permission.description,
          };
        })
      );
    }
  }, [dataRoomQuery.data, dataRoomQuery.isRefetching]);

  if (dataRoomQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (dataRoomQuery.isPending || !dataRoomQuery.data) {
    return <Loading />;
  }

  return (
    <div className="flex-1">
      <div className="flex justify-center">
        <div className="hidden xl:block">
          <Steps currentStep={2} />
        </div>
        <div className="w-full xl:w-1/3">
          <p className="text-lg font-semibold">Data Room permissions</p>
          {dataRoomQuery.data.deal.runner === DealRunner.Seller ? (
            <p className="text-sm text-gray-500 font-light">
              We default to 3 different permission levels for controlling access
              to Data Room files and folders. You can edit the
              names/descriptions below and add more permission levels if needed.
            </p>
          ) : (
            <p className="text-sm text-gray-500 font-light">
              We default to 1 permission level for buy side deals. You can add
              more permission levels if needed.
            </p>
          )}

          {dataRoomPermissions.map((permission, index) => {
            return (
              <div key={index} className="mt-4">
                <Card padding="m">
                  <div className="py-2 flex items-center justify-between">
                    <p className="text-sm font-semibold">
                      Permission level {permission.accessLevel}
                    </p>

                    {index !== 0 &&
                      index === dataRoomPermissions.length - 1 && (
                        <XCircleIcon
                          onClick={() => {
                            if (permission.id === "") {
                              setDataRoomPermissions((permissions) => {
                                permissions.splice(index, 1);
                                return [...permissions];
                              });
                              return;
                            }

                            if (deletePermission.isPending) {
                              return;
                            }

                            deletePermission.mutate(
                              {
                                id: permission.id,
                              },
                              {
                                onSuccess: () => {
                                  queryClient.invalidateQueries({
                                    queryKey: ["DataRoom", { dealId: dealId }],
                                  });
                                },
                              }
                            );
                          }}
                          className="w-5 h-5 ml-2 text-gray-400 hover:text-gray-500 cursor-pointer"
                        />
                      )}
                  </div>
                  <TextInput
                    label="Name"
                    value={permission.name}
                    onChange={(e) => {
                      setDataRoomPermissions((permissions) => {
                        permissions[index].name = e.target.value;
                        return [...permissions];
                      });
                    }}
                  />

                  <TextInput
                    margin="m 0 0 0"
                    name="description"
                    label="Description"
                    value={permission.description}
                    onChange={(e) => {
                      setDataRoomPermissions((permissions) => {
                        permissions[index].description = e.target.value;
                        return [...permissions];
                      });
                    }}
                  />
                </Card>
              </div>
            );
          })}
          <div>
            <button
              onClick={() => {
                setDataRoomPermissions((permissions) => {
                  return [
                    ...permissions,
                    {
                      id: "",
                      name: "",
                      description: "",
                      accessLevel: permissions.length + 1,
                    },
                  ];
                });
              }}
              className="font-semibold text-sm text-persian-500 hover:text-persian-700"
            >
              <div className="flex items-center mt-3">
                <PlusIcon className="w-5 h-5 text-persian-500" />
                <p className="mt-0.5">Add permission level</p>
              </div>
            </button>
          </div>
          <div className="flex justify-end mt-8 gap-x-3">
            <Button
              onClick={() => {
                updateDataRoom.mutate(
                  {
                    input: {
                      id: dataRoomQuery.data.deal.dataRoom.id,
                      permissions: dataRoomPermissions,
                    },
                  },
                  {
                    onSuccess: () => {
                      history.push(`/create-deal/${dealId}/people`);
                    },
                  }
                );
              }}
              isLoading={updateDataRoom.isPending}
              loadingText="Saving..."
              variant="positive"
              text="Continue"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
