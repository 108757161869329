import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { MOBILE_NAV_BREAKPOINT } from "../config";
import { useColorScheme } from "../hooks/useColorScheme";
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";
import { useWindowSize } from "../hooks/useWindowSize";
import { authSelectors } from "../store/auth/selector";
import { WithMarginProp } from "../styles/withMargin";
import { actions } from "../store/auth/slice";
import { AccountFragmentFragment, AccountQuery } from "../graphql/generated";

import { H3 } from "./Heading";
import {
  ArrowLeftOnRectangleIcon,
  ArrowRightIcon,
  Bars3Icon,
  HomeIcon,
  ListBulletIcon,
  PlusIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import Logo from "./Logo";
import { useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { useAuth, useSession } from "@clerk/clerk-react";
import { Avatar } from "./account/Avatar";
import { classNames } from "../utils/cn";
import { AppState } from "../store";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import React from "react";
import { HardDriveIcon } from "lucide-react";
import { SignupSource } from "../routes/Signup";

interface MobileNavProps {
  menuToggle: () => void;
  showMenu: boolean;
}

const MobileHeader = (props: MobileNavProps) => {
  return (
    <header className="bg-persian-950 py-3 px-2 shadow-xl">
      <div className="flex justify-between items-center shadow-xl">
        <Logo width={105} height={35} fill="#fff" />
        <Bars3Icon className="text-white w-6 h-6" />
      </div>
    </header>
  );
};

export function Navigation() {
  const window = useWindowSize();
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const colorScheme = useColorScheme();

  const { signOut } = useAuth();
  const { session } = useSession();

  const dealsLastSeen = useSelector(authSelectors.dealsLastSeen);

  const account = useSelector(authSelectors.account);
  const wrapperRef = useRef(null);
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const history = useHistory();

  const posthog = usePostHog();

  const sortedDeals = React.useMemo(() => {
    if (!account || !account.deals) return [];

    return [...account.deals].sort((a, b) => {
      const aLastSeen = dealsLastSeen[a.id] || a.createdAt;
      const bLastSeen = dealsLastSeen[b.id] || b.createdAt;
      return bLastSeen - aLastSeen;
    });
  }, [account, dealsLastSeen]);

  const [dealsSearch, setDealsSearch] = useState("");

  useOutsideAlerter(wrapperRef, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  const signupSource =
    (session?.user.unsafeMetadata?.signupSource as SignupSource) ??
    SignupSource.signupPage;

  return (
    <>
      {window.width !== undefined && window.width < MOBILE_NAV_BREAKPOINT && (
        <MobileHeader
          showMenu={showMenu}
          menuToggle={() => setShowMenu((s) => !s)}
        />
      )}
      <Nav colorScheme={colorScheme} showMenu={showMenu}>
        <div className="flex flex-col mt-3  mx-1.5">
          <div className="mx-4 mb-5 flex items-center justify-between">
            <Logo width={105} height={35} />

            {/* <button className="relative p-2 border border-persian-50/30 hover:border-persian-50/50 rounded-md bg-persian-950/80 hover:bg-persian-950/90">
              <BellIcon className="w-4 h-4 text-white" />
              <div className="absolute top-1 right-1.5 w-2 h-2 bg-orange-500 rounded-full"></div>
            </button> */}
          </div>

          <H3 mode="dark" margin="m m m m">
            {account && account.firm ? account.firm.name : ""}
          </H3>

          <FirmNavigation account={account} signupSource={signupSource} />
          <div className=" my-4 h-px w-auto bg-gray-200/80"></div>

          <div className="flex items-center justify-between">
            <H3 mode="dark" margin=" m m m">
              Live Deals
            </H3>
            {account && account.deals.length > 0 ? (
              <NavLink
                className={`text-persian-50 font-semibold bg-persian-400/30 hover:bg-persian-500/40 px-2.5 border border-persian-400 hover:border-persian-500  py-1 rounded-full text-sm flex items-center`}
                to="/create-deal"
                onClick={() => {
                  dispatch(actions.setActiveDealId({ id: undefined }));
                }}
              >
                <PlusIcon className="w-4 h-4" />
                New deal
              </NavLink>
            ) : null}
          </div>

          {account && account.deals && account.deals.length > 0 ? (
            <div className="overflow-y-scroll    max-h-96 no-scrollbar px-1">
              <input
                value={dealsSearch}
                onChange={(e) => setDealsSearch(e.currentTarget.value)}
                placeholder="Search deals..."
                className="focus:outline-none focus:border-persian-100 focus:border  focus:ring-0  outline-none bg-persian-300/30 placeholder:text-white mb-3 text-sm text-white border-transparent  ring-none rounded-md w-full"
              />
              {account && account.deals
                ? sortedDeals
                    .filter((deal) => {
                      return dealsSearch.length > 2
                        ? deal.company.name
                            .toLowerCase()
                            .includes(dealsSearch.toLowerCase())
                        : true;
                    })
                    .map((deal) => {
                      return <DealListItem key={deal.id} deal={deal} />;
                    })
                : null}
            </div>
          ) : (
            <div className="p-3 bg-persian-950 border-persian-50/30 mx-2 border rounded-md">
              <p className="text-white text-sm font-semibold">No deals</p>
              <p className="text-gray-200 text-sm">
                All your deals will appear here
              </p>

              {account && account.firm ? (
                <NavLink
                  to="/create-deal"
                  className={
                    "text-white font-semibold text-sm  mt-3 group flex items-center"
                  }
                >
                  Create my first deal
                  <ArrowRightIcon className="text-white w-4 h-4 group-hover:ml-2 ml-0.5 transition-all" />
                </NavLink>
              ) : null}
            </div>
          )}
        </div>

        <div className="mt-auto  mb-8">
          <>
            <div className="w-full h-px bg-gray-200/80 mb-4"></div>
            <Link
              size="s"
              to="/settings"
              mode="secondary"
              onClick={() => {
                dispatch(actions.setActiveDealId({ id: undefined }));
              }}
            >
              <div className="flex items-center gap-x-3">
                {account ? (
                  <Avatar size="xs" account={account} />
                ) : (
                  <UserCircleIcon className="w-4 h-4 " />
                )}
                <span className="">Account</span>
              </div>
            </Link>
            <Link
              mode="secondary"
              size="s"
              to="/login"
              onClick={async () => {
                await signOut();
                posthog?.reset();

                dispatch(actions.logout());
                queryClient.removeQueries();
                queryClient.invalidateQueries();
                queryClient.invalidateQueries({
                  queryKey: ["Account", {}],
                });
                history.push("/login");
              }}
            >
              <div className="flex items-center gap-x-3">
                <ArrowLeftOnRectangleIcon className="w-4 h-4 " />
                <span className="">Logout</span>
              </div>
            </Link>
          </>
        </div>
      </Nav>
    </>
  );
}

function FirmNavigation(props: {
  account: AccountFragmentFragment | undefined;
  signupSource: SignupSource;
}) {
  const dispatch = useDispatch();

  if (!props.account) {
    return null;
  }

  if (props.account.firm) {
    return (
      <div>
        <Link
          margin="xl 0 0 0"
          to="/home"
          exact
          onClick={() => {
            dispatch(actions.setActiveDealId({ id: undefined }));
          }}
        >
          <div className="flex items-center space-x-3">
            <HomeIcon className="w-4 h-4" />
            <span className="text-sm font-semibold">Home</span>
          </div>
        </Link>
        <Link
          to="/tasks"
          onClick={() => {
            dispatch(actions.setActiveDealId({ id: undefined }));
          }}
        >
          <div className="flex items-center space-x-3">
            <ListBulletIcon className="w-4 h-4" />
            <span className="text-sm font-semibold">Tasks</span>
          </div>
        </Link>
        <Link
          to="/firm/drive"
          onClick={() => {
            dispatch(actions.setActiveDealId({ id: undefined }));
          }}
        >
          <div className="flex items-center space-x-3">
            <HardDriveIcon className="w-4 h-4" />
            <span className="text-sm font-semibold">Drive</span>
          </div>
        </Link>
        <Link
          size="s"
          to="/firm"
          exact
          onClick={() => {
            dispatch(actions.setActiveDealId({ id: undefined }));
          }}
        >
          <div className="flex items-center gap-x-3">
            <UsersIcon className="w-4 h-4 " />
            <span className="text-sm">Firm</span>
          </div>
        </Link>
      </div>
    );
  }

  if (props.signupSource === SignupSource.externalInvite) {
    return (
      <div className="p-3 rounded-md bg-persian-950/90  mt-3 border border-persian-50/30">
        <p className="text-md text-gray-50 font-semibold">Sign up to Liquid</p>
        <p className="text-sm text-gray-50">
          Find out why more and more firms are switching to Liquid to run their
          deals
        </p>
        <a
          href={`mailto:hello@liquidacquire.com?subject=${encodeURIComponent(
            "I'm interested in Liquid"
          )}`}
          target="_blank"
          rel="noreferrer"
        >
          <p className="text-white font-semibold text-sm  mt-3 group flex items-center">
            Talk to us
            <ArrowRightIcon className="text-white w-4 h-4 group-hover:ml-2 ml-0.5 transition-all" />
          </p>
        </a>
        {/* <NavLink
          to="/"
          className={
            "text-white font-semibold text-sm  mt-3 group flex items-center"
          }
        >
          Find out more
          <ArrowRightIcon className="text-white w-4 h-4 group-hover:ml-2 ml-0.5 transition-all" />
        </NavLink> */}
      </div>
    );
  }

  return <div></div>;
}

function DealListItem(props: { deal: AccountQuery["account"]["deals"][0] }) {
  const deal = props.deal;
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const dealLastSeen = useSelector((state: AppState) =>
    authSelectors.dealLastSeen(state, deal.id)
  );

  const lastSeen = dealLastSeen ? dealLastSeen : deal.createdAt;
  const activeDealId = useSelector(authSelectors.activeDealId);

  return (
    <div
      className={`py-2 px-2 text-secondary-text mb-2 last:mb-0 rounded-md cursor-pointer active:bg-persian-950/40 hover:bg-persian-800/40 ${
        activeDealId === deal.id ? "bg-persian-300/50" : ""
      }`}
      key={deal.id}
      onClick={() => {
        dispatch(actions.setActiveDealId({ id: deal.id }));
        queryClient.invalidateQueries();
        history.push("/deal/dashboard");
      }}
    >
      <div className="flex flex-row items-center gap-x-3">
        <div className="flex flex-col">
          <p className="text-sm font-semibold text-white">
            {deal.company.name}
          </p>
          <p className="text-xs text-gray-50">
            Last viewed{" "}
            {formatDistanceToNowStrict(fromUnixTime(lastSeen), {
              addSuffix: true,
            })}
          </p>
        </div>
      </div>
    </div>
  );
}

const Nav = ({
  showMenu,
  colorScheme,
  children,
}: {
  showMenu: boolean;
  colorScheme: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={`  w-[280px] from-persian-950/90 to-persian-950/85 bg-gradient-to-br flex h-screen sticky top-0 z-30 select-none pt-2 flex-col  `}
    >
      {children}
    </div>
  );
};

// md:absolute md:left-0 md:rounded-md md:m-4 md:h-auto md:w-full md:box-border md:border-r-0 md:transition md:duration-200 md:ease-out md:shadow-md

const Link = ({
  children,
  size,
  onClick,
  mode,
  ...props
}: {
  children: React.ReactNode;
  size?: string;
  mode?: "secondary" | "primary";
  onClick?: () => void;
} & WithMarginProp & {
    to: string;
    exact?: boolean;
  }) => {
  if (mode && mode === "secondary") {
    return (
      <NavLink
        {...props}
        onClick={onClick}
        key={props.to}
        className={(isActive) => {
          return classNames(
            size === "s" ? "text-sm px-0" : "text-base",
            "gap-x-2 px-3 py-1.5 my-1.5 text-white font-semibold rounded-md flex items-center",
            isActive ? " text-persian-300" : "text-white hover:text-persian-300"
          );
        }}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <NavLink
      {...props}
      key={props.to}
      onClick={onClick}
      className={(isActive) => {
        return classNames(
          size === "s" ? "text-sm px-0" : "text-base",
          "gap-x-2 px-3 py-1.5 my-1.5 hover:bg-white font-semibold rounded-md flex items-center",
          isActive
            ? "bg-white text-persian-600/90"
            : "text-white hover:text-persian-600/90"
        );
      }}
    >
      {children}
    </NavLink>
  );
};
