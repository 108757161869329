import { Redirect, Route, Switch } from "react-router-dom";
import { ErrorCheckingSettings } from "./error_checking";
import { DealExports } from "./exports";

export function DealSettings() {
  return (
    <div className="flex-1">
      <div className="p-8 pt-4">
        <Switch>
          <Route path="/deal/settings" exact>
            <Redirect to="/deal/settings/exports" />
          </Route>
          <Route path="/deal/settings/error-checking">
            <ErrorCheckingSettings />
          </Route>
          <Route path="/deal/settings/exports">
            <DealExports />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
