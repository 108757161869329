import Logo from "@/src/components/Logo";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { CreateFirm } from "./CreateFirm";
import { InviteFirmMembers } from "./InviteFirmMembers";
import { CreateDeal } from "./CreateDeal";
import { Avatar } from "@/src/components/account/Avatar";
import { authSelectors } from "@/src/store/auth/selector";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";
import { useQueryClient } from "@tanstack/react-query";
import { actions } from "@/src/store/auth/slice";

function getCurrentStep(pathname: string) {
  if (pathname === "/onboarding/create-firm") {
    return 0;
  }

  if (pathname === "/onboarding/invite-firm-members") {
    return 1;
  }

  return 2;
}

export function Onboarding() {
  const location = useLocation();
  const account = useSelector(authSelectors.account);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { signOut } = useAuth();
  const posthog = usePostHog();
  const queryClient = useQueryClient();

  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col flex-1 items-center  ">
      <div className="shadow-2xl flex items-center justify-center bg-gradient-to-br from-persian-950/90 to-persian-950/85 w-full p-8 pb-2 pt-4">
        <div className="w-full lg:w-1/2 px-4">
          <div className="flex justify-between items-center">
            <Logo width={105} height={35} />
            {account ? (
              <div className="relative">
                <button
                  className="flex items-center"
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <Avatar size="m" account={account} />
                </button>
                {isMenuOpen ? (
                  <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" ref={menuRef}>
                      <button
                        className="block w-full font-semibold text-left px-4 py-1.5 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        onClick={async () => {
                          await signOut();
                          posthog?.reset();

                          dispatch(actions.logout());
                          queryClient.removeQueries();
                          queryClient.invalidateQueries();
                          queryClient.invalidateQueries({
                            queryKey: ["Account", {}],
                          });
                          history.push("/login");
                        }}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          <Steps currentStep={getCurrentStep(location.pathname)} />
        </div>
      </div>
      <div className="flex flex-1 flex-col  p-8 w-full lg:w-1/2">
        <Switch>
          <Route path="/onboarding/create-firm" exact>
            <CreateFirm />
          </Route>
          <Route path="/onboarding/invite-firm-members" exact>
            <InviteFirmMembers />
          </Route>
          <Route path="/onboarding/create-deal" exact>
            <CreateDeal />
          </Route>
        </Switch>
      </div>
      <div className="flex flex-col items-center justify-center pb-8">
        <p className=" text-md text-gray-500/80">
          Having trouble?{" "}
          <a
            href="mailto:support@liquid.com"
            className="font-semibold underline"
          >
            Contact us
          </a>
        </p>
      </div>
    </div>
  );
}

export function OnboardingBody(props: { children: React.ReactNode }) {
  return (
    <div className="flex-1 flex py-8 mb-20 h-96">
      <div className="flex-1 flex flex-col ">{props.children}</div>
    </div>
  );
}

const steps = [
  { id: "01", name: "Create a firm" },
  { id: "02", name: "Invite firm members" },
  { id: "03", name: "Create your first deal" },
];

function Steps(props: { currentStep: number }) {
  const clampedProgress = Math.min(
    Math.max((props.currentStep / 2) * 100, 16.66),
    100
  );
  return (
    <div aria-hidden="true" className="mt-6 w-full ">
      <div className="overflow-hidden rounded-full bg-persian-500/50">
        <div
          style={{ width: `${clampedProgress}%` }}
          className="transition-all duration-300 ease-in-out h-2 rounded-full bg-persian-500"
        />
      </div>
      <div className="mt-3 grid-cols-3 text-sm font-medium text-gray-600 grid">
        {steps.map((step, i) => (
          <div
            key={step.id}
            className={`text-center ${
              i <= props.currentStep
                ? "text-persian-200"
                : "text-persian-300/70"
            }`}
          >
            {step.name}
          </div>
        ))}
      </div>
    </div>
  );
}
