import { AnimatedModal } from "@/src/components/AnimatedModal";
import { CloseIcon } from "@/src/components/CloseIcon";
import { Button } from "@/src/components/tailwind/Button";
import { toasts } from "@/src/components/toasts/toasts";
import {
  DataRoomFileFragmentFragment,
  DeletionType,
  useDeleteDataRoomFileVersionMutation,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useQueryClient } from "@tanstack/react-query";
export function DeleteVersionModal(props: {
  open: boolean;
  onClose: () => void;
  dataRoomFile: DataRoomFileFragmentFragment;
  versionId: string;
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const deleteVersion = useDeleteDataRoomFileVersionMutation(client);
  const versionToDelete = props.dataRoomFile.versions.find(
    (v) => v.id === props.versionId
  );
  if (!versionToDelete) {
    return null;
  }

  return (
    <AnimatedModal open={props.open} onClose={props.onClose} size="lg">
      <div>
        <div className="flex items-center justify-between">
          <p className="font-semibold text-gray-700">
            Delete version {versionToDelete.versionNumber}?
          </p>
          <CloseIcon onClose={props.onClose} />
        </div>
        <p className="mt-3 text-sm text-gray-500">
          Are you sure you want to delete this version? This action cannot be
          undone.
        </p>

        {props.dataRoomFile.versions.length === 1 ? (
          <p className="mt-3 font-semibold text-orange-700">
            You cannot delete the only version of a file.
          </p>
        ) : null}

        <div className="mt-8 flex justify-end">
          <Button
            variant="danger"
            text="Delete version"
            isLoading={deleteVersion.isPending}
            loadingText="Deleting version..."
            isDisabled={props.dataRoomFile.versions.length === 1}
            onClick={() => {
              if (props.dataRoomFile.versions.length === 1) {
                return;
              }

              deleteVersion.mutate(
                {
                  input: {
                    id: versionToDelete.id,
                    deletionType: DeletionType.Hard,
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: [
                        "DataRoomFileView",
                        { id: props.dataRoomFile.id },
                      ],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ["DataRoomFile", { id: props.dataRoomFile.id }],
                    });
                    toasts.success(
                      `Version ${versionToDelete.versionNumber} deleted`
                    );
                    props.onClose();
                  },
                }
              );
            }}
          />
        </div>
      </div>
    </AnimatedModal>
  );
}
