import { withMarginTw } from "../styles/withMargin";
import { classNames } from "../utils/cn";

type AlertType = "success" | "error" | "warning" | "info";
interface Props {
  type: AlertType;
  text: string;
  margin?: string;
}

export function Alert(props: Props) {
  const m = withMarginTw({ margin: props.margin });
  return (
    <div
      className={classNames(
        "border-l-4 border-solid border-orange-400 rounded-md bg-orange-400/30 p-2",
        m
      )}
    >
      <p className="m-0 text-sm font-semibold text-orange-700">{props.text}</p>
    </div>
  );
}
