interface ProgressBarProps {
  progress: number;
  height?: string;
  backgroundColor?: string;
  progressColor?: string;
}

export function ProgressBar({
  progress,
  height = "h-2.5",
  backgroundColor = "bg-gray-200",
  progressColor = "bg-persian-600",
}: ProgressBarProps) {
  // Ensure progress is between 0 and 100
  const clampedProgress = Math.min(Math.max(progress, 0), 100);

  return (
    <div className={`w-full ${backgroundColor} rounded-full ${height}`}>
      <div
        className={`${progressColor} ${height} rounded-full transition-all duration-300 ease-in-out`}
        style={{ width: `${clampedProgress}%` }}
      ></div>
    </div>
  );
}
