import { Avatar } from "@/src/components/account/Avatar";
import { FileIcon } from "@/src/components/FileIcon";
import Loading from "@/src/components/Loading";
import {
  AccessMatrixQuery,
  DealRole,
  useAccessMatrixQuery,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { FolderIcon } from "@heroicons/react/20/solid";

export type FolderWrapper = {
  folder: AccessMatrixQuery["dataRoom"]["allFolders"][0];
  folders: FolderWrapper[];
  files: AccessMatrixQuery["dataRoom"]["allFolders"][0]["files"][0][];
};

type AccessMatrixMap = {
  [key: string]: { [key: string]: boolean };
};

export const buildFolderHierarchy = (
  folders: AccessMatrixQuery["dataRoom"]["allFolders"]
): FolderWrapper[] => {
  // Create map of all folders by ID for quick lookup
  const parentFoldersMap: {
    [key: string]: AccessMatrixQuery["dataRoom"]["allFolders"][0][];
  } = {};

  folders.forEach((folder) => {
    if (folder.parentFolders.length > 0) {
      if (
        !parentFoldersMap[
          folder.parentFolders[folder.parentFolders.length - 1].id
        ]
      ) {
        parentFoldersMap[
          folder.parentFolders[folder.parentFolders.length - 1].id
        ] = [];
      }

      parentFoldersMap[
        folder.parentFolders[folder.parentFolders.length - 1].id
      ].push(folder);
    }
  });

  function buildFolderWrapper(
    folder: AccessMatrixQuery["dataRoom"]["allFolders"][0]
  ): FolderWrapper {
    return {
      folder: folder,
      folders: parentFoldersMap[folder.id]
        ? parentFoldersMap[folder.id].map((f) => buildFolderWrapper(f))
        : [],
      files: folder.files,
    } as FolderWrapper;
  }

  // Build array of folder wrappers
  const rootFolders = folders
    .filter((f) => f.parentFolders.length === 0)
    .map((f) => buildFolderWrapper(f));
  return rootFolders;
};

export function AccessMatrix(props: {
  dataRoomId: string;
  dealId: string;
  accountSearch: string;
}) {
  const client = useGqlClient();
  const accessMatrixQuery = useAccessMatrixQuery(client, {
    dataRoomID: props.dataRoomId,
    dealID: props.dealId,
  });

  if (accessMatrixQuery.error) {
    return <div>Something went wrong</div>;
  }

  if (!accessMatrixQuery.data || accessMatrixQuery.isLoading) {
    return (
      <div className="min-h-96 flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  // Convert access matrix array into hashmap for efficient lookups
  const accessMatrixMap: AccessMatrixMap =
    accessMatrixQuery.data.accessMatrix.reduce(
      (acc, { entityKey, accountID, hasAccess }) => {
        if (!acc[entityKey]) {
          acc[entityKey] = {};
        }

        acc[entityKey][accountID] = hasAccess;
        return acc;
      },
      {} as { [key: string]: { [key: string]: boolean } }
    );

  // Convert flat folder list into hierarchical structure

  const folderHierarchy = buildFolderHierarchy(
    accessMatrixQuery.data.dataRoom.allFolders
  );

  const dealAccounts = accessMatrixQuery.data.deal.dealAccounts.filter(
    (da) => ![DealRole.DealOwner, DealRole.DealAdmin].includes(da.role)
  );

  return (
    <div className="flex flex-col shadow rounded-lg">
      <div className="overflow-x-auto rounded-lg">
        <div className="inline-block min-w-full rounded-lg  align-middle">
          <div className="overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
            <table className="rounded-lg min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Files
                  </th>
                  {dealAccounts.map((account) => (
                    <th
                      key={account.account.id}
                      scope="col"
                      className="px-3 border-x border-gray-200 py-3.5 text-center text-sm font-semibold text-gray-900"
                    >
                      <span className="inline-flex items-center gap-x-1.5">
                        <Avatar account={account.account} size="xs" />
                        <span className="text-xs">{account.account.name}</span>
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {folderHierarchy.map((folder) => (
                  <FolderRow
                    folder={folder}
                    depth={0}
                    path={""}
                    accessMatrixMap={accessMatrixMap}
                    dealAccounts={dealAccounts}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function FolderRow(props: {
  folder: FolderWrapper;
  depth: number;
  path: string;
  accessMatrixMap: AccessMatrixMap;
  dealAccounts: AccessMatrixQuery["deal"]["dealAccounts"];
}) {
  return (
    <>
      <tr className="hover:bg-gray-50" key={props.folder.folder.id}>
        <td className="whitespace-nowrap py-0.5 pl-4 pr-3 text-sm font-medium text-gray-900">
          <div
            className="flex items-center gap-x-1.5"
            style={{ paddingLeft: `${props.depth * 0.8}rem` }}
          >
            <FolderIcon className="h-5 w-5 text-blue-700/70" />
            {props.folder.folder.name ? props.folder.folder.name : "Home"}
          </div>
        </td>
        {props.dealAccounts.map((account) => (
          <td
            key={account.account.id}
            className="whitespace-nowrap border-x border-gray-200 px-3 py-3 text-sm text-center"
          >
            <input
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-persian-600 focus:ring-persian-600"
              readOnly
              checked={
                props.accessMatrixMap[props.folder.folder.id][
                  account.account.id
                ]
              }
            />
          </td>
        ))}
      </tr>

      {/* Recursively render child folders */}
      {props.folder.folders.map((child) => {
        return (
          <FolderRow
            folder={child}
            depth={props.depth + 1}
            path={props.path + props.folder.folder.name + "/"}
            accessMatrixMap={props.accessMatrixMap}
            dealAccounts={props.dealAccounts}
          />
        );
      })}

      {/* Render files in current folder */}
      {props.folder.files.map((file) => (
        <tr
          className="hover:bg-gray-50 pl-3"
          key={props.path + props.folder.folder.name + "-file"}
        >
          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900">
            <div
              className="flex items-center gap-x-1.5"
              style={{ paddingLeft: `${(props.depth + 1) * 0.8}rem` }}
            >
              <FileIcon fileType={file.fileType} />
              {file.name}
            </div>
          </td>
          {props.dealAccounts.map((account) => (
            <td
              key={account.account.id}
              className="whitespace-nowrap px-2 border-x border-gray-200 py-3 text-sm text-center"
            >
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-persian-600 focus:ring-persian-600"
                readOnly
                checked={props.accessMatrixMap[file.id][account.account.id]}
              />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
