import { TextDiff, TextDiffType } from "../../graphql/generated";

export function TextDiffs(props: { diffs: TextDiff[] }) {
  if (!props.diffs) {
    return null;
  }

  const diffs = props.diffs;
  return (
    <p className="text-xs">
      {diffs.map((diff, index) => {
        let className = "";
        switch (diff.type) {
          case TextDiffType.Delete:
            className = "text-red-700 bg-red-100  line-through";
            break;
          case TextDiffType.Insert:
            className = "bg-green-100 text-green-700 ";
            break;
          case TextDiffType.Equal:
            className = "text-gray-500";
            break;
        }
        return (
          <span key={index} className={` ${className}`}>
            {diff.text}
          </span>
        );
      })}
    </p>
  );
}
