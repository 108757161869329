import { classNames } from "../utils/cn";

import { RadioGroup } from "@headlessui/react";
import { DealRunner } from "../graphql/generated";

export function DealSideRadio({
  dealRunner,
  setDealRunner,
}: {
  dealRunner: DealRunner;
  setDealRunner: (dealRunner: DealRunner) => void;
}) {
  return (
    <RadioGroup value={dealRunner} onChange={setDealRunner}>
      <RadioGroup.Label className="sr-only">Deal Type</RadioGroup.Label>
      <div className="-space-y-px rounded-md bg-white">
        <RadioGroup.Option
          value={DealRunner.Seller}
          className={({ checked }) =>
            classNames(
              "rounded-tl-md rounded-tr-md",
              checked
                ? "z-10 border-persian-200 bg-persian-50"
                : "border-gray-200",
              "relative flex cursor-pointer border p-4 focus:outline-none"
            )
          }
        >
          {({ active, checked }) => (
            <>
              <span
                className={classNames(
                  checked
                    ? "bg-persian-600 border-transparent"
                    : "bg-white border-gray-300",
                  active ? "ring-2 ring-offset-2 ring-persian-600" : "",
                  "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                )}
                aria-hidden="true"
              >
                <span className="rounded-full bg-white w-1.5 h-1.5" />
              </span>
              <span className="ml-3 flex flex-col">
                <RadioGroup.Label
                  as="span"
                  className={classNames(
                    checked ? "text-persian-900" : "text-gray-900",
                    "block text-sm font-medium"
                  )}
                >
                  Sell Side
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="span"
                  className={classNames(
                    checked ? "text-persian-700" : "text-gray-500",
                    "block text-sm"
                  )}
                >
                  You are selling a company and will invite potential buyers to
                  the deal.
                </RadioGroup.Description>
              </span>
            </>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option
          value={DealRunner.Buyer}
          className={({ checked }) =>
            classNames(
              "rounded-bl-md rounded-br-md",
              checked
                ? "z-10 border-persian-200 bg-persian-50"
                : "border-gray-200",
              "relative flex cursor-pointer border p-4 focus:outline-none"
            )
          }
        >
          {({ active, checked }) => (
            <>
              <span
                className={classNames(
                  checked
                    ? "bg-persian-600 border-transparent"
                    : "bg-white border-gray-300",
                  active ? "ring-2 ring-offset-2 ring-persian-600" : "",
                  "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                )}
                aria-hidden="true"
              >
                <span className="rounded-full bg-white w-1.5 h-1.5" />
              </span>
              <span className="ml-3 flex flex-col">
                <RadioGroup.Label
                  as="span"
                  className={classNames(
                    checked ? "text-persian-900" : "text-gray-900",
                    "block text-sm font-medium"
                  )}
                >
                  Buy side
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="span"
                  className={classNames(
                    checked ? "text-persian-700" : "text-gray-500",
                    "block text-sm"
                  )}
                >
                  You are buying a company and will invite the seller to the
                  deal.{" "}
                </RadioGroup.Description>
              </span>
            </>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
}
