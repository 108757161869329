import { useSelector } from "react-redux";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import {
  DealActivityInput,
  useDealActivityQuery,
} from "../../graphql/generated";
import Loading from "../Loading";
import { ActivityItems } from "./ActivityItems";
import { ActivityItemMode } from "./ActivityItem";

interface ActivityProps {
  input: DealActivityInput;
  mode?: ActivityItemMode;
}

export function Activity(props: ActivityProps) {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const { data, isLoading, error } = useDealActivityQuery(client, {
    dealId: activeDealId ?? "",
    input: props.input,
  });

  if (error) {
    return <div>Error</div>;
  }

  if (isLoading || !data) {
    return <Loading />;
  }

  if (data.dealActivity.activity.length === 0) {
    return (
      <div>
        <p className="text-gray-500 text-sm">No recent activity</p>
      </div>
    );
  }

  return (
    <div>
      <ActivityItems
        margin="l 0 0 0"
        items={data.dealActivity.activity}
        mode={props.mode}
      />
    </div>
  );
}
