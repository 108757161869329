import { useSelector } from "react-redux";
import { H3 } from "../../components/Heading";
import {
  Account,
  Deal,
  Task,
  TaskActivityType,
  TasksFilters,
  TasksQuery,
  TaskStatus,
  UpdateTaskInput,
  useTasksQuery,
  useUpdateTasksMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import Loading from "../../components/Loading";
import { PlusIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { Button } from "../../components/tailwind/Button";
import { NewTaskModal } from "./NewTaskModal";
import { useEffect, useState } from "react";
import { Card } from "../../components/Card";
import { NavLink } from "react-router-dom";
import {
  format,
  formatDistanceToNowStrict,
  fromUnixTime,
  isBefore,
} from "date-fns";
import { Avatar } from "../../components/account/Avatar";
import {
  CalendarDaysIcon,
  CheckCircleIcon,
  UserCircleIcon,
  ChatBubbleLeftIcon,
} from "@heroicons/react/24/outline";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import CompanyLogo from "../../components/CompanyLogo";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import { TaskPriorityIcon } from "../../components/TaskPriorityPill";
import { classNames } from "@/src/utils/cn";
import { CircleDashedIcon, CircleIcon } from "lucide-react";
import { formatEnum } from "@/src/utils/enums";
import { ProgressBar } from "@/src/components/ProgressBar";

export function Tasks() {
  const account = useSelector(authSelectors.account);
  const [openModal, setOpenModal] = useState<"new_task" | "">("");
  const [taskModal, setTaskModal] = useState<{
    dealId?: string;
    accountId?: string;
  } | null>(null);
  const [filters, setFilters] = useState<TasksFilters>({});
  const client = useGqlClient();

  const tasksQuery = useTasksQuery(client, {
    firmID: account && account.firm ? account.firm.id : "",
    filters: filters,
  });

  const [dealOptions, setDealOptions] = useState<Option[]>([]);
  const [accountOptions, setAccountOptions] = useState<Option[]>([]);

  const [selectedDeal, setSelectedDeal] = useState<Option | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Option | null>(null);

  useEffect(() => {
    if (!tasksQuery.data || tasksQuery.isLoading) {
      return;
    }

    const accountOptions: Option[] = tasksQuery.data.firm.accounts.map(
      (acc) => {
        return {
          label: acc.name,
          value: acc.id,
          icon: <Avatar account={acc} size="xs" />,
        };
      }
    );

    setAccountOptions([
      {
        label: "All accounts",
        value: null,
      },
      ...accountOptions,
    ]);

    if (account && account.deals.length > 0) {
      const dealOptions: Option[] = account.deals.map((deal) => {
        return {
          label: deal.company?.name ?? "",
          value: deal.id,
          icon: (
            <CompanyLogo
              logo={deal.company ? deal.company.logo : ""}
              withShadow={false}
              borderSize={"3"}
              size={20}
              name={deal.company ? deal.company.name : ""}
              bgColor={deal.company ? deal.company.logoColor : "#000"}
            />
          ),
        };
      });

      setDealOptions([
        {
          label: "All deals",
          value: null,
        },
        ...dealOptions,
      ]);
    }
  }, [tasksQuery.data, account, tasksQuery.isLoading]);

  return (
    <div className="flex flex-col flex-1">
      <div className="bg-white sticky top-0 z-20 px-8 py-3 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>Tasks</H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
          <div className="flex items-center gap-x-3">
            {account && account.deals.length > 0 ? (
              <Dropdown
                size="s"
                options={dealOptions}
                selectedOption={selectedDeal ?? undefined}
                defaultOption={{
                  label: "All deals",
                  value: null,
                }}
                onSelect={(o) => {
                  setSelectedDeal(o);
                  setFilters({ ...filters, dealID: o.value });
                }}
              />
            ) : null}
            {accountOptions.length > 0 ? (
              <Dropdown
                size="s"
                options={accountOptions}
                selectedOption={selectedAccount ?? undefined}
                defaultOption={{
                  label: "All accounts",
                  value: null,
                }}
                onSelect={(o) => {
                  setSelectedAccount(o);
                  setFilters({ ...filters, assignedTo: o.value });
                }}
              />
            ) : null}
            <Button
              text="New task"
              icon={PlusIcon}
              variant="positive"
              onClick={() => {
                setTaskModal({});
              }}
              size="s"
            />
          </div>
        </div>
      </div>
      <div className="p-8 flex-1 flex flex-col">
        {tasksQuery.data && account && account.firm ? (
          <div className="flex gap-x-4">
            {/* <div className="w-1/5 p-2 rounded-md bg-concrete-100 max-h-[calc(100vh-10rem)]">
              <TasksNavigation firm={tasksQuery.data.firm} />
            </div> */}
            <TasksContent
              onNewTask={(dealId, accountId) => {
                setTaskModal({ dealId, accountId });
              }}
              query={tasksQuery}
              filters={filters}
            />
          </div>
        ) : null}
      </div>
      {account && account.firm ? (
        <NewTaskModal
          open={taskModal !== null}
          onClose={() => {
            setTaskModal(null);
          }}
          firmID={account.firm.id}
          deal={
            tasksQuery.data && taskModal?.dealId
              ? (tasksQuery.data.firm.deals.find(
                  (d) => d.id === taskModal.dealId
                ) as Deal)
              : undefined
          }
          account={
            tasksQuery.data && taskModal?.accountId
              ? (tasksQuery.data.firm.accounts.find(
                  (a) => a.id === taskModal.accountId
                ) as Account)
              : undefined
          }
        />
      ) : null}
    </div>
  );
}

function TaskNavigationLink(props: {
  to: string;
  icon: React.ReactNode;
  text: string;
}) {
  return (
    <NavLink
      className="px-2 py-1.5 text-concrete-600 flex items-center gap-x-2 text-md hover:bg-concrete-200/80 hover:text-concrete-900 rounded-md"
      activeClassName="bg-concrete-200/70 text-concrete-900 rounded-md"
      to={props.to}
    >
      {props.icon}
      {props.text}
    </NavLink>
  );
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (
  sourceList: any[],
  destinationList: any[],
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(sourceList);
  const destClone = Array.from(destinationList);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: Record<string, Task[]> = {};

  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

type DroppableKey = {
  dealId: string;
  assigneeId: string;
  status: TaskStatus;
};

function getDroppableKey(droppableId: string): DroppableKey {
  const [dealId, assigneeId, status] = droppableId.split("-");
  return { dealId, assigneeId, status: status as TaskStatus };
}

function TasksContent(props: {
  onNewTask: (dealId?: string, accountId?: string) => void;
  query: UseQueryResult<TasksQuery, unknown>;
  filters: TasksFilters;
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const updateTasks = useUpdateTasksMutation(client);

  const tasksQuery = props.query;

  const [groupedTasks, setGroupedTasks] = useState<GroupedTasks>({});

  useEffect(() => {
    if (!tasksQuery.data) {
      return;
    }

    const deals = tasksQuery.data.firm.deals.filter((d) => {
      return props.filters.dealID ? d.id === props.filters.dealID : true;
    });

    const accounts = tasksQuery.data.firm.accounts.filter((a) => {
      return props.filters.assignedTo
        ? a.id === props.filters.assignedTo
        : true;
    });

    const groupedTasks = groupTasks(
      tasksQuery.data.tasks as Task[],
      deals as Deal[],
      accounts as Account[]
    );
    setGroupedTasks(groupedTasks);
  }, [tasksQuery.data, props.filters]);

  function handleUpdateTasks(tasks: Task[]) {
    const inputs: UpdateTaskInput[] = tasks.map((task) => {
      return {
        id: task.id,
        sortOrder: task.sortOrder,
        status: task.status,
        assignedTo: task.assignedTo ? task.assignedTo.id : "",
        dealID: task.deal ? task.deal.id : "",
      };
    });

    updateTasks.mutate(
      {
        input: {
          taskInputs: inputs,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["Tasks"] });
        },
      }
    );
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.droppableId === result.source.droppableId) {
      const { dealId, assigneeId, status } = getDroppableKey(
        result.source.droppableId
      );

      const newTasks = reorder(
        groupedTasks[dealId][assigneeId][status],
        result.source.index,
        result.destination.index
      ).map((t, i) => ({
        ...t,
        sortOrder: i,
      }));

      setGroupedTasks({
        ...groupedTasks,
        [dealId]: {
          ...groupedTasks[dealId],
          [assigneeId]: {
            ...groupedTasks[dealId][assigneeId],
            [status]: newTasks,
          },
        },
      });

      handleUpdateTasks(newTasks);
    }

    if (result.destination.droppableId !== result.source.droppableId) {
      const source = getDroppableKey(result.source.droppableId);
      const destination = getDroppableKey(result.destination.droppableId);

      const movedTasks = move(
        groupedTasks[source.dealId][source.assigneeId][source.status],
        groupedTasks[destination.dealId][destination.assigneeId][
          destination.status
        ],
        result.source,
        result.destination
      );

      let newGroupedTasks = { ...groupedTasks };

      console.log("New grouped takss beofre", newGroupedTasks);

      const newSourceTasks: Task[] = movedTasks[result.source.droppableId].map(
        (t, i) => ({
          ...t,
          sortOrder: i,
          status: source.status,
          deal:
            source.dealId === "nodeal"
              ? null
              : (tasksQuery.data?.firm.deals.find(
                  (d) => d.id === source.dealId
                ) as Deal),
          assignedTo:
            source.assigneeId === "unassigned"
              ? null
              : (tasksQuery.data?.firm.accounts.find(
                  (a) => a.id === source.assigneeId
                ) as Account),
        })
      ) as Task[];

      const newDestinationTasks = movedTasks[
        result.destination.droppableId
      ].map((t, i) => ({
        ...t,
        sortOrder: i,
        status: destination.status,
        deal:
          destination.dealId === "nodeal"
            ? null
            : (tasksQuery.data?.firm.deals.find(
                (d) => d.id === destination.dealId
              ) as Deal),
        assignedTo:
          destination.assigneeId === "unassigned"
            ? null
            : (tasksQuery.data?.firm.accounts.find(
                (a) => a.id === destination.assigneeId
              ) as Account),
      }));

      newGroupedTasks[destination.dealId][destination.assigneeId][
        destination.status
      ] = newDestinationTasks;
      newGroupedTasks[source.dealId][source.assigneeId][source.status] =
        newSourceTasks;

      setGroupedTasks(newGroupedTasks);

      handleUpdateTasks([...newSourceTasks, ...newDestinationTasks]);
    }
  }

  if (tasksQuery.isLoading || !tasksQuery.data) {
    return <Loading />;
  }

  if (tasksQuery.error) {
    return <div>Error</div>;
  }

  return (
    <div className="md:w-2/3">
      <DragDropContext
        onDragEnd={(result: DropResult) => {
          console.log(result);
          onDragEnd(result);
        }}
      >
        {Object.keys(groupedTasks).length > 0 ? (
          <div className="">
            <H3>All tasks</H3>
            {Object.keys(groupedTasks).map((dealId) => (
              <div key={dealId}>
                {dealId ? (
                  <DealTasks
                    firm={tasksQuery.data.firm}
                    dealId={dealId}
                    groupedTasks={groupedTasks}
                    filters={props.filters}
                    tasks={tasksQuery.data.tasks}
                    onNewTask={props.onNewTask}
                  />
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </DragDropContext>
    </div>
  );
}

function DealTasks(props: {
  firm: TasksQuery["firm"];
  tasks: TasksQuery["tasks"];
  groupedTasks: GroupedTasks;
  dealId: string;
  filters: TasksFilters;
  onNewTask: (dealId?: string, accountId?: string) => void;
}) {
  const dealId = props.dealId;
  const deal = props.firm.deals.find((d) => d.id === dealId);
  const tasks = props.tasks.filter((t) =>
    dealId === "nodeal" ? !t.deal : t.deal && t.deal.id === dealId
  );

  const progress =
    (tasks.filter((t) => t.status === TaskStatus.Completed).length /
      tasks.length) *
    100;

  if (props.filters.dealID && dealId !== props.filters.dealID) {
    return null;
  }

  if (props.filters.assignedTo) {
    const assignedTasks = props.groupedTasks[dealId][props.filters.assignedTo];
    if (Object.values(assignedTasks).every((tasks) => tasks.length === 0)) {
      return null;
    }
  }

  return (
    <div className="my-2 mb-3">
      <Card>
        <div className="flex justify-between items-center p-3">
          <div className="flex items-center gap-x-2">
            <CompanyLogo
              logo={deal?.company?.logo}
              withShadow={false}
              borderSize={"2"}
              size={28}
              name={deal?.company?.name ?? ""}
              bgColor={deal?.company?.logoColor ?? "#000"}
            />
            <p className="text-gray-700 font-semibold">
              {deal?.company?.name ?? "No deal"}
            </p>
            <div className="ml-3 w-[100px]">
              <ProgressBar
                progressColor="bg-green-400/80"
                backgroundColor="bg-green-100"
                progress={progress}
                height="h-1.5"
              />
            </div>
          </div>
          <div>
            <button
              onClick={() => props.onNewTask(dealId)}
              className="group p-1 rounded-md hover:bg-gray-100"
            >
              <PlusIcon className="w-4 h-4 text-gray-500" />
            </button>
          </div>
        </div>
        <div className="">
          {Object.keys(props.groupedTasks[dealId]).map((assigneeId) => (
            <div key={assigneeId}>
              <AssigneeTasks
                groupedTasks={props.groupedTasks}
                dealId={props.dealId}
                firm={props.firm}
                assigneeId={assigneeId}
                filters={props.filters}
                onNewTask={props.onNewTask}
              />
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
}

function AssigneeTasks(props: {
  groupedTasks: GroupedTasks;
  dealId: string;
  assigneeId: string;
  firm: TasksQuery["firm"];
  filters: TasksFilters;
  onNewTask: (dealId?: string, accountId?: string) => void;
}) {
  const assignee = props.firm.accounts.find((a) => a.id === props.assigneeId);

  if (
    props.filters.assignedTo &&
    props.assigneeId !== props.filters.assignedTo
  ) {
    return null;
  }

  const statuses = [
    TaskStatus.InProgress,
    TaskStatus.Todo,
    TaskStatus.Completed,
  ];

  let assigneeBody = assignee ? (
    <div className="flex items-center gap-x-2">
      <Avatar account={assignee} size="s" />
      <p className="text-gray-600 text-md font-semibold">{assignee.name}</p>
    </div>
  ) : (
    <div className="flex items-center gap-x-2">
      <UserCircleIcon className="w-5 h-5 text-gray-400" />
      <p className="text-gray-600 text-md font-semibold">Unassigned</p>
    </div>
  );

  return (
    <div>
      <div className="flex justify-between items-center w-full shadow-md border-y border-concrete-300 bg-concrete-100 px-3 py-2">
        <div>
          <p className="text-gray-700 font-semibold">{assigneeBody}</p>
        </div>
        {assignee ? (
          <div>
            <button
              onClick={() => props.onNewTask(props.dealId, props.assigneeId)}
              className="group p-1 rounded-md hover:bg-gray-200"
            >
              <PlusIcon className="w-4 h-4 text-gray-500" />
            </button>
          </div>
        ) : null}
      </div>
      <div>
        {statuses.map((status) => (
          <StatusTasks
            key={status}
            tasks={
              props.groupedTasks[props.dealId ?? "nodeal"]?.[
                props.assigneeId ?? "unassigned"
              ]?.[status] ?? []
            }
            status={status as TaskStatus}
            dealId={props.dealId}
            assignedId={props.assigneeId}
          />
        ))}
      </div>
    </div>
  );
}

function StatusIcon(props: { status: TaskStatus }) {
  switch (props.status) {
    case TaskStatus.Todo:
      return <CircleDashedIcon className="w-4 h-4 text-gray-400" />;
    case TaskStatus.InProgress:
      return <CircleIcon className="w-4 h-4 text-gray-400" />;
    case TaskStatus.Completed:
      return <CheckCircleIcon className="w-4 h-4 text-green-400" />;
    default:
      return null;
  }
}

function StatusHeader(props: { status: TaskStatus }) {
  return (
    <div className="w-full border-y border-concrete-100 bg-concrete-50 px-6 py-2 flex items-center gap-x-2">
      <StatusIcon status={props.status} />
      <p className="text-gray-700 text-sm font-semibold">
        {formatEnum(props.status)}
      </p>
    </div>
  );
}

function StatusTasks(props: {
  tasks: Task[];
  status: TaskStatus;
  dealId: string;
  assignedId: string;
}) {
  const tasks = props.tasks;
  return (
    <div>
      <StatusHeader status={props.status} />
      <div className="">
        <Droppable
          droppableId={`${props.dealId}-${props.assignedId}-${props.status}`}
        >
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {tasks.length === 0 ? (
                <div className="flex items-center flex-col py-3 justify-center">
                  <p className="text-gray-500 text-sm font-normal">
                    No tasks in {formatEnum(props.status)}
                  </p>
                </div>
              ) : null}
              {tasks
                .sort((a, b) => {
                  return a.sortOrder - b.sortOrder;
                })
                .map((task, i) => (
                  <Draggable key={task.id} draggableId={task.id} index={i}>
                    {(provided, snapshot) => (
                      <div
                        key={task.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classNames(
                          "hover:bg-concrete-50 px-6 py-2",
                          snapshot.isDragging
                            ? "bg-concrete-50 shadow-sm border border-concrete-100"
                            : ""
                        )}
                      >
                        <TaskListItem task={task} />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  );
}

function TaskListItem(props: { task: Task }) {
  const task = props.task;
  return (
    <NavLink key={task.id} to={`/tasks/${task.id}`}>
      <div key={task.id}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <TaskPriorityIcon priority={task.priority ?? undefined} />
            <div>
              <p className="font-semibold text-sm text-gray-700">
                {task.title}
              </p>
              <p className="text-xs text-gray-500/80">
                Created by {task.createdBy.firstName}{" "}
                {formatDistanceToNowStrict(fromUnixTime(task.createdAt), {
                  addSuffix: true,
                })}
              </p>
            </div>
          </div>
          <div className="flex gap-x-2">
            <>
              <DueDatePill dueDate={task.dueDate ?? undefined} />
            </>
            <div className="border border-concrete-100 rounded-2xl px-2 flex items-center gap-x-1.5">
              <ChatBubbleLeftIcon className=" w-4 h-4 text-gray-400" />
              <p className="text-gray-500 text-sm">
                {
                  task.activity.filter(
                    (a) => a.type === TaskActivityType.Comment
                  ).length
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
}

type GroupedTasks = {
  [dealId: string]: {
    [assignedTo: string]: {
      [status in TaskStatus]: Task[];
    };
  };
};

function groupTasks(
  tasks: Task[],
  deals: Deal[],
  accounts: Account[]
): GroupedTasks {
  const grouped: GroupedTasks = {};
  grouped["nodeal"] = {};
  for (const d of deals) {
    grouped[d.id] = {};
    for (const a of accounts) {
      grouped[d.id][a.id] = {
        [TaskStatus.InProgress]: [],
        [TaskStatus.Todo]: [],
        [TaskStatus.Completed]: [],
        [TaskStatus.Cancelled]: [],
      };

      const filteredTasks = tasks.filter(
        (t) => t.deal?.id === d.id && t.assignedTo?.id === a.id
      );
      for (const status of Object.values(TaskStatus)) {
        grouped[d.id][a.id][status] = filteredTasks.filter(
          (t) => t.status === status
        );
      }
    }

    const unassignedTasks = tasks.filter(
      (t) => t.deal?.id === d.id && !t.assignedTo
    );
    grouped[d.id]["unassigned"] = {
      [TaskStatus.Cancelled]: unassignedTasks.filter(
        (t) => t.status === TaskStatus.Cancelled
      ),
      [TaskStatus.Completed]: unassignedTasks.filter(
        (t) => t.status === TaskStatus.Completed
      ),
      [TaskStatus.InProgress]: unassignedTasks.filter(
        (t) => t.status === TaskStatus.InProgress
      ),
      [TaskStatus.Todo]: unassignedTasks.filter(
        (t) => t.status === TaskStatus.Todo
      ),
    };
  }

  const dealLessTasks = tasks.filter((t) => !t.deal);
  for (const a of accounts) {
    grouped["nodeal"][a.id] = {
      [TaskStatus.InProgress]: [],
      [TaskStatus.Todo]: [],
      [TaskStatus.Completed]: [],
      [TaskStatus.Cancelled]: [],
    };

    const filteredTasks = dealLessTasks.filter(
      (t) => t.assignedTo?.id === a.id
    );
    for (const status of Object.values(TaskStatus)) {
      grouped["nodeal"][a.id][status] = filteredTasks.filter(
        (t) => t.status === status
      );
    }
  }

  const unassignedTasks = dealLessTasks.filter((t) => !t.assignedTo);
  grouped["nodeal"]["unassigned"] = {
    [TaskStatus.Cancelled]: unassignedTasks.filter(
      (t) => t.status === TaskStatus.Cancelled
    ),
    [TaskStatus.Completed]: unassignedTasks.filter(
      (t) => t.status === TaskStatus.Completed
    ),
    [TaskStatus.InProgress]: unassignedTasks.filter(
      (t) => t.status === TaskStatus.InProgress
    ),
    [TaskStatus.Todo]: unassignedTasks.filter(
      (t) => t.status === TaskStatus.Todo
    ),
  };

  return grouped;
}

function DealPillCustom(props: { deal: Partial<Deal>; onRemove?: () => void }) {
  const deal = props.deal;
  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="cursor-pointer relative flex items-center rounded-2xl border border-gray-100 px-2 py-1">
        <CompanyLogo
          logo={deal.company?.logo}
          withShadow={false}
          borderSize={"3"}
          size={16}
          name={deal.company ? deal.company.name : ""}
          bgColor={deal.company ? deal.company.logoColor : "#000"}
        />
        <p className="text-xs ml-2 truncate">
          {props.deal.company ? props.deal.company.name : ""}
        </p>
      </div>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
    </div>
  );
}

function DueDatePill(props: { dueDate?: number }) {
  if (!props.dueDate) {
    return (
      <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
        <CalendarDaysIcon className="w-4 h-4 text-gray-400" />
        <p className="ml-1 text-xs text-gray-500">No due date</p>
      </div>
    );
  }

  const dd = fromUnixTime(props.dueDate);

  if (isBefore(dd, new Date())) {
    return (
      <div className="relative cursor-pointer border border-orange-200 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
        <CalendarDaysIcon className="w-4 h-4 text-orange-400" />
        <p className="ml-1 text-xs text-orange-600">
          Due {format(dd, "dd MMM")}
        </p>
      </div>
    );
  }

  return (
    <div className="relative cursor-pointer border border-concrete-100 rounded-2xl px-1.5  flex items-center rounded-2xlpx-2 py-1">
      <CalendarDaysIcon className="w-4 h-4 text-gray-400" />
      <p className="ml-1 text-xs text-gray-500">Due {format(dd, "dd MMM")}</p>
    </div>
  );
}

function UserPillInfo(props: {
  account: Pick<Account, "id" | "name" | "avatar" | "avatarColor">;
  onRemove?: () => void;
}) {
  return (
    <div className="flex flex-wrap group justify-center relative">
      <div className="cursor-pointer   relative flex items-center rounded-2xl border border-concrete-100  px-2 py-1">
        <Avatar account={props.account} size="xs" />
        <p className="text-xs ml-2 mt-0.5 truncate">{props.account.name}</p>
      </div>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
    </div>
  );
}

function groupBy(tasks: Task[], arg1: (task: any) => any) {
  return tasks.reduce((acc, task) => {
    const key = arg1(task);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(task);
    return acc;
  }, {} as Record<string, Task[]>);
}
