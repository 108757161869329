import { useSelector } from "react-redux";
import { DealRole, useActiveDealAccountQuery } from "../graphql/generated";
import { authSelectors } from "../store/auth/selector";
import useGqlClient from "../hooks/useGqlClient";
import { Option } from "./tailwind/Dropdown";
import { formatDealRole } from "../utils/enums";
import { AppState } from "../store";

export const adminRoles = [DealRole.DealOwner, DealRole.DealAdmin];

export const guestRoles = [DealRole.GuestAdmin, DealRole.Guest];

export const allRoles = [
  DealRole.DealOwner,
  DealRole.DealAdmin,
  DealRole.GuestAdmin,
  DealRole.Guest,
];

export function DealRoleRestricted(props: {
  roles: DealRole[];
  children: React.ReactNode;
  dealId?: string;
}) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const activeDeal = useSelector((state: AppState) =>
    authSelectors.deal(
      state,
      props.dealId ? props.dealId : activeDealId ? activeDealId : ""
    )
  );
  const client = useGqlClient();
  const activeDealAccount = useActiveDealAccountQuery(client, {
    id: props.dealId ? props.dealId : activeDealId ? activeDealId : "",
  });

  if (!activeDeal) {
    return null;
  }

  if (activeDealAccount.data) {
    const activeRole = activeDealAccount.data.deal.activeDealAccount.role;
    if (!props.roles.includes(activeRole)) {
      return null;
    }

    return <>{props.children}</>;
  }

  return null;
}

export const dealRoleOptions: Option[] = [
  {
    value: DealRole.DealAdmin,
    label: formatDealRole(DealRole.DealAdmin),
    description: "Complete control over the deal",
  },
  {
    value: DealRole.DealOwner,
    label: formatDealRole(DealRole.DealOwner),
    description: "Complete control over the deal.",
  },
];

export const guestRoleOptions: Option[] = [
  {
    value: DealRole.GuestAdmin,
    label: formatDealRole(DealRole.GuestAdmin),
    description: "Can invite other users to their group",
  },
  {
    value: DealRole.Guest,
    label: formatDealRole(DealRole.Guest),
    description: "Can view documents and ask questions",
  },
];

export const allRoleOptions = [...dealRoleOptions, ...guestRoleOptions];
