import { H3, H4 } from "../../components/Heading";
import useGqlClient from "../../hooks/useGqlClient";
import {
  Question as GqlQuestion,
  DataRoomFile,
  useCreateQuestionMutation,
  QuestionNodeType,
  DealActivityType,
  GroupQuestionsQuery,
  useDealQuery,
  useDealGroupQuestionsQuery,
  useQuestionsQuery,
} from "../../graphql/generated";
import Loading from "../../components/Loading";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { Link, NavLink, useHistory } from "react-router-dom";
import { PageContent } from "../../components/PageContent";
import { Button } from "../../components/tailwind/Button";
import {
  ArrowDownTrayIcon,
  ClipboardDocumentListIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { AnimatedModal } from "../../components/AnimatedModal";
import { TextInput } from "../../components/tailwind/TextInput";
import { TextArea } from "../../components/tailwind/TextArea";
import { CloseIcon } from "../../components/CloseIcon";
import { Pills } from "../../components/Pills";
import { FilePill } from "../../components/FilePill";
import { useQueryClient } from "@tanstack/react-query";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { Avatar } from "../../components/account/Avatar";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { Card } from "../../components/Card";
import {
  DealRoleRestricted,
  adminRoles,
  guestRoles,
} from "../../components/DealRoleRestricted";
import { classNames } from "../../utils/cn";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { StatusPill } from "./Group";
import { NewDDQLModal } from "./NewDDQLModal";
import { Option } from "../../components/tailwind/Dropdown";
import { ListPlusIcon } from "lucide-react";

export function Questions() {
  return (
    <div className="flex-1 flex">
      <DealRoleRestricted roles={adminRoles}>
        <DealRunnerQuestions />
      </DealRoleRestricted>
      <DealRoleRestricted roles={guestRoles}>
        <GuestQuestions />
      </DealRoleRestricted>
    </div>
  );
}

function DealRunnerQuestions() {
  const client = useGqlClient();
  const history = useHistory();
  const queryClient = useQueryClient();

  const activeDealId = useSelector(authSelectors.activeDealId);
  const questions = useQuestionsQuery(client, {
    dealId: activeDealId ?? "",
    input: {},
  });

  const [openModal, setOpenModal] = useState<
    "" | "create_ddql" | "new_question"
  >("");

  if (questions.error) {
    return (
      <div className="flex flex-1 flex-col justify-center items-center">
        <p className="text-gray-500/80 text-sm font-semibold">
          Something went wrong, please try again later
        </p>
      </div>
    );
  }

  if (questions.isLoading || !questions.data) {
    return <Loading />;
  }

  return (
    <div className="flex-1 flex-col flex">
      <div className="bg-white px-8 py-2 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H4>Questions</H4>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
          <div className="flex items-center gap-x-2">
            <Button
              size="s"
              variant="neutral"
              icon={PlusIcon}
              text="New question"
              onClick={() => {
                setOpenModal("new_question");
              }}
            />
            <Button
              icon={ListPlusIcon}
              variant="positive"
              size="s"
              text="New list"
              onClick={() => {
                setOpenModal("create_ddql");
              }}
            />
          </div>
        </div>
      </div>
      <div className="p-8 pt-4 flex-1 flex">
        <div className="w-1/2">
          <H3>Lists</H3>
          <p className="text-sm text-gray-500">
            Use lists to group questions e.g. for DDQLs
          </p>
          <Card margin="s 0 0 0">
            {questions.data.ddqls.length === 0 ? (
              <div className="flex flex-col justify-center items-center flex-1">
                <p className="text-gray-500/80 text-sm font-semibold">
                  No lists yet
                </p>
              </div>
            ) : (
              questions.data.ddqls.map((ddql, i) => {
                return (
                  <NavLink key={i} to={`/questions/lists/${ddql.id}`}>
                    <div
                      className={classNames(
                        "flex items-center justify-between hover:bg-gray-100 px-3 py-2",
                        i === 0 ? "rounded-t-md" : "",
                        i === questions.data.ddqls.length - 1
                          ? "rounded-b-md"
                          : ""
                      )}
                    >
                      <div className="">
                        <p className="text-sm font-bold text-gray-700">
                          {ddql.name}
                        </p>
                        <p className="text-xs text-gray-500/80">
                          Created{" "}
                          {formatDistanceToNow(fromUnixTime(ddql.createdAt), {
                            addSuffix: true,
                          })}
                        </p>
                        {/* <div className="">
                          <Pills>
                            <StatusPill mode="compact" ddql={ddql} />
                          </Pills>
                        </div> */}
                      </div>
                      <div className="w-52 flex items-center gap-x-2">
                        <ProgressBar
                          progress={
                            ddql.questions.length === 0
                              ? 0
                              : (ddql.questions.filter((q) => q.answer).length /
                                  ddql.questions.length) *
                                100
                          }
                        />
                        <p className="text-xs text-gray-500/80">
                          {ddql.questions.filter((q) => q.answer).length}/
                          {ddql.questions.length}
                        </p>
                      </div>
                    </div>
                  </NavLink>
                );
              })
            )}
          </Card>
          <H3 margin="l 0 0 0">Questions</H3>
          <Card margin="s 0 0 0">
            {questions.data.questions.questions.map((question, i) => {
              return (
                <Question
                  isFirst={i === 0}
                  isLast={i === questions.data.questions.questions.length - 1}
                  key={question.id}
                  question={question as GqlQuestion}
                />
              );
            })}
          </Card>
        </div>
      </div>

      <NewQuestionModal
        open={openModal === "new_question"}
        onClose={() => {
          setOpenModal("");
        }}
      />
      <NewDDQLModal
        open={openModal === "create_ddql"}
        onClose={() => {
          setOpenModal("");
        }}
        dealFirmGroupId={
          questions.data.deal.activeDealAccount.dealGroup?.id ?? ""
        }
      />
    </div>
  );
}

function GuestQuestions() {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const activeDealAccount = useSelector(authSelectors.activeDealAccount);

  const dealGroupQuestions = useDealGroupQuestionsQuery(client, {
    id:
      activeDealAccount && activeDealAccount.dealGroup
        ? activeDealAccount.dealGroup.id
        : "",
  });

  const [newQuestionModalOpen, setNewQuestionModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState("");

  const queryClient = useQueryClient();
  const actor = useSelector(authSelectors.actor);

  if (dealGroupQuestions.error) {
    return <div>Something went wrong</div>;
  }

  if (dealGroupQuestions.isLoading || !dealGroupQuestions.data) {
    return <Loading />;
  }

  const questions = dealGroupQuestions.data.dealGroup.questions;
  const ddqls = dealGroupQuestions.data.dealGroup.ddqls;

  return (
    <div className="flex-1 flex-col flex ">
      <div className="bg-white px-8 py-3 w-full border-b border-gray-300/80 shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <H4>Questions</H4>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
          <Button
            variant="neutral"
            size="s"
            text="New question"
            icon={PlusIcon}
            isDisabled={actor !== undefined}
            onClick={() => {
              setNewQuestionModalOpen(true);
            }}
          />
        </div>
      </div>

      {questions.length === 0 ? (
        <div className="flex justify-center items-center flex-1 ">
          <div>
            <p className="text-center font-semibold text-gray-800">
              No questions yet
            </p>
            <p className="text-gray-500/80 text-center text-sm">
              Any questions your team ask will appear here
            </p>
          </div>
        </div>
      ) : (
        <PageContent>
          <div className=" w-1/2">
            <H4>Questions</H4>
            <Card margin="s 0 0 0">
              {questions.map((question, i) => {
                return (
                  <>
                    <Question
                      key={question.id}
                      question={question as GqlQuestion}
                      isFirst={i === 0}
                      isLast={i === questions.length - 1}
                    />
                    {i !== questions.length - 1 ? (
                      <div className="border-b border-gray-200" />
                    ) : null}
                  </>
                );
              })}
            </Card>
            <div className="mt-12 flex justify-between">
              <H4>Lists</H4>
              <button
                onClick={() => {
                  setOpenModal("create_ddql");
                }}
                className="text-persian-600 text-sm font-semibold flex items-center gap-x-1 hover:text-persian-800"
              >
                <PlusIcon className="w-4 h-4 " />
                Import question list
              </button>
            </div>
            {ddqls.length === 0 ? (
              <Card margin="s 0 0 0" padding="m">
                <div className="flex flex-col justify-center items-center flex-1 ">
                  <ClipboardDocumentListIcon className="h-8 w-8 text-gray-400" />
                  <p className="text-sm text-center font-semibold text-gray-800 ">
                    No imports yet
                  </p>
                  <p className="text-gray-500 text-xs text-center">
                    Import a new question list below
                  </p>
                  <Button
                    variant="neutral"
                    icon={ArrowDownTrayIcon}
                    text="Import question list"
                    size="s"
                    margin="m 0 0 0"
                    onClick={() => {
                      setOpenModal("create_ddql");
                    }}
                  />
                </div>
              </Card>
            ) : (
              <Card margin="s 0 0 0">
                {ddqls.map((ddql, i) => {
                  return (
                    <NavLink
                      key={i}
                      to={`/questions/group/${dealGroupQuestions.data.dealGroup.id}/ddql/${ddql.id}`}
                    >
                      <div
                        className={classNames(
                          "flex items-center justify-between hover:bg-gray-100 p-3",
                          i === 0 ? "rounded-t-md" : "",
                          i === ddqls.length - 1 ? "rounded-b-md" : ""
                        )}
                      >
                        <div className="">
                          <p className="text-sm font-bold text-gray-700">
                            {ddql.name}
                          </p>
                          <p className="text-xs text-gray-500/80">
                            Created{" "}
                            {formatDistanceToNow(fromUnixTime(ddql.createdAt), {
                              addSuffix: true,
                            })}
                          </p>
                          <div className="mt-2">
                            <Pills>
                              <StatusPill mode="compact" ddql={ddql} />
                            </Pills>
                          </div>
                        </div>
                        <div className="w-52 flex items-center gap-x-2">
                          <ProgressBar
                            progress={
                              ddql.questions.length === 0
                                ? 0
                                : (ddql.questions.filter((q) => q.answer)
                                    .length /
                                    ddql.questions.length) *
                                  100
                            }
                          />
                          <p className="text-xs text-gray-500/80">
                            {ddql.questions.filter((q) => q.answer).length}/
                            {ddql.questions.length}
                          </p>
                        </div>
                      </div>
                    </NavLink>
                  );
                })}
              </Card>
            )}
            <div className="mt-12 w-full h-px bg-gray-200 " />
            <div className="mt-3 bg-yellow-50 p-2 border-yellow-300 border rounded-md">
              <div className="flex justify-between items-center">
                <H4>Questions for {dealGroupQuestions.data.dealGroup.name}</H4>
                <DealRoleRestricted roles={adminRoles}>
                  <button
                    onClick={() => {
                      setOpenModal("new_question");
                    }}
                    className="text-sm font-semibold text-persian-600 hover:text-persian-800 flex items-center"
                  >
                    <PlusIcon className="w-4 h-4 " />
                    Add question
                  </button>
                </DealRoleRestricted>
              </div>
              {dealGroupQuestions.data.dealGroup.questionsForGroup.length ===
              0 ? (
                <Card padding="m" margin="s 0 0 0">
                  <p className="font-semibold text-gray-600 text-sm text-center">
                    {dealGroupQuestions.data.dealGroup.name} hasn't been asked
                    any questions yet
                  </p>
                </Card>
              ) : (
                <Card margin="s 0 0 0">
                  {dealGroupQuestions.data.dealGroup.questionsForGroup
                    .sort((a, b) => {
                      if (a.answer && !b.answer) {
                        return 1;
                      }

                      if (!a.answer && b.answer) {
                        return -1;
                      }

                      return 0;
                    })
                    .map((question, i) => {
                      return (
                        <Question
                          isFirst={i === 0}
                          isLast={
                            i ===
                            dealGroupQuestions.data.dealGroup.questionsForGroup
                              .length -
                              1
                          }
                          key={question.id}
                          question={question as GqlQuestion}
                        />
                      );
                    })}
                </Card>
              )}
            </div>
          </div>
        </PageContent>
      )}

      <NewQuestionModal
        open={newQuestionModalOpen}
        onClose={() => {
          setNewQuestionModalOpen(false);
        }}
      />
      <NewDDQLModal
        open={openModal === "create_ddql"}
        onClose={() => {
          queryClient.invalidateQueries({
            queryKey: [
              "DealGroupQuestions",
              { id: dealGroupQuestions.data.dealGroup.id },
            ],
          });
          setOpenModal("");
        }}
        dealFirmGroupId={dealGroupQuestions.data.dealGroup.id}
      />
    </div>
  );
}

export function NewQuestionModal(props: {
  open: boolean;
  onClose: () => void;
  ddqlId?: string;
  forDealFirmGroupId?: string;
}) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const [files, setFiles] = useState<DataRoomFile[]>([]);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [details, setDetails] = useState("");
  const [selectedDfg, setSelectedDfg] = useState<Option | null>(null);

  const [error, setError] = useState("");

  const createButtonRef = useRef<HTMLButtonElement>(null);

  const client = useGqlClient();
  const queryClient = useQueryClient();

  const dealQuery = useDealQuery(client, {
    id: activeDealId ?? "",
  });

  const createQuestion = useCreateQuestionMutation(client);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.metaKey || event.ctrlKey) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (createButtonRef.current) {
          createButtonRef.current.click();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function clearForm() {
    setFiles([]);
    setTitle("");
    setDetails("");
    setTitleError("");
    setError("");
  }

  return (
    <AnimatedModal
      size="lg"
      position="center"
      open={props.open}
      onClose={() => {
        clearForm();
        props.onClose();
      }}
    >
      <div>
        <div className="justify-between flex items-center">
          <H3>New question</H3>
          <CloseIcon onClose={props.onClose} />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (!title) {
              setTitleError("Title is required");
              return;
            }

            let questionForDealFirmGroupId = props.forDealFirmGroupId;
            if (
              !questionForDealFirmGroupId &&
              selectedDfg &&
              selectedDfg.value
            ) {
              questionForDealFirmGroupId = selectedDfg.value;
            }

            createQuestion.mutate(
              {
                title,
                body: details,
                nodeType: QuestionNodeType.DataRoomFile,
                nodeId: files.length > 0 ? files[0].id : "",
                ddqlID: props.ddqlId,
                questionForDealGroupID: questionForDealFirmGroupId,
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["Questions", { input: {} }],
                  });

                  if (props.ddqlId) {
                    queryClient.invalidateQueries({
                      queryKey: ["Ddql", { id: props.ddqlId }],
                    });
                  }

                  if (props.forDealFirmGroupId) {
                    queryClient.invalidateQueries({
                      queryKey: [
                        "DealFirmGroupQuestions",
                        { id: props.forDealFirmGroupId },
                      ],
                    });
                  }

                  props.onClose();
                  clearForm();
                },
                onError: (e) => {
                  setError("Failed to create question");
                },
              }
            );

            props.onClose();
          }}
        >
          <div className="mt-4">
            <TextInput
              label="Title"
              placeholder="Title"
              value={title}
              error={titleError}
              onChange={(e) => {
                setTitle(e.currentTarget.value);
                setTitleError("");
              }}
            />

            <label
              htmlFor="details"
              className="block mt-3 text-sm font-medium leading-6 text-gray-900"
            >
              Details
            </label>
            <TextArea
              rows={3}
              name="details"
              value={details}
              onChange={(e) => {
                setDetails(e.currentTarget.value);
              }}
              placeholder="Any extra context/detail..."
            />

            {/* <div className="flex gap-x-8">
              <div>
                <label
                  htmlFor="files"
                  className="block mt-3 text-sm font-medium leading-6 text-gray-900"
                >
                  Related files
                </label>
                <Pills>
                  {files.map((file) => {
                    return (
                      <FilePill
                        id={file.id}
                        onRemove={() => {
                          setFiles(files.filter((f) => f.id !== file.id));
                        }}
                        showDetailsCard={false}
                        type={file.fileType}
                        name={file.name}
                      />
                    );
                  })}
                  {files.length === 0 ? (
                    <AddFilePill
                      onFileSelected={(file) => {
                        if (files.find((f) => f.id === file.id)) {
                          return;
                        }

                        setFiles([...files, file]);
                      }}
                    />
                  ) : null}
                </Pills>
              </div>
              <DealRoleRestricted roles={adminRoles}>
                {!props.forDealFirmGroupId && dealQuery.data ? (
                  <div>
                    <label
                      htmlFor="files"
                      className="block mt-3 text-sm font-medium leading-6 text-gray-900"
                    >
                      Question for
                      <span className="text-xs text-gray-500/80 ml-1">
                        Optional
                      </span>
                    </label>
                    <Dropdown
                      options={dealQuery.data.deal.guestGroups.map((b) => {
                        return {
                          label: b.name,
                          value: b.id,
                        };
                      })}
                      selectedOption={selectedDfg ?? undefined}
                      onSelect={(value) => {
                        setSelectedDfg(value);
                      }}
                    />
                  </div>
                ) : null}
              </DealRoleRestricted>
            </div> */}
            <div className="flex justify-end mt-6 z-0">
              <Button
                ref={createButtonRef}
                isLoading={createQuestion.isPending}
                loadingText="Posting..."
                text="Post question"
                variant="positive"
                type="submit"
              />
            </div>
            {error ? <div className="mt-4  text-red-500">{error}</div> : null}
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}

interface QuestionProps {
  question: GqlQuestion;
  isFirst: boolean;
  isLast: boolean;
}

export function Question(props: QuestionProps) {
  return (
    <div
      key={props.question.id}
      className={classNames(
        "w-full 3xl:w-4/5 hover:bg-gray-200/70 cursor-pointer",
        props.question.answer ? "bg-gray-100/90" : "",
        props.isFirst ? "rounded-t-md" : "",
        props.isLast ? "rounded-b-md" : ""
      )}
    >
      <Link to={`/questions/${props.question.id}`}>
        <div className="flex px-3 py-2 items-center space-x-3">
          <Avatar account={props.question.createdBy} />
          <div className="flex-1">
            <p className="text-gray-600 text-sm font-bold">
              {props.question.title}
            </p>
            <p className="text-xs text-gray-500/80 leading-tight text-ellipsis">
              Created{" "}
              {formatDistanceToNow(fromUnixTime(props.question.createdAt), {
                addSuffix: true,
              })}
            </p>
          </div>
          {props.question.dataRoomFile ? (
            <FilePill
              id={props.question.dataRoomFile.id}
              name={props.question.dataRoomFile.name}
              type={props.question.dataRoomFile.fileType}
            />
          ) : null}
          <div className="flex gap-x-2 items-center">
            <ChatBubbleLeftIcon className="mt-0.5 w-4 h-4 text-gray-500" />
            <p className="text-gray-600 text-sm">
              {
                props.question.activity.filter(
                  (a) => a.type === DealActivityType.Comment
                ).length
              }
            </p>
            <CheckCircleIcon
              className={classNames(
                "mt-0.5 w-4 h-4",
                props.question.answer ? "text-green-400" : "text-gray-300 "
              )}
            />
          </div>
        </div>
      </Link>
    </div>
  );
}

interface GroupProps {
  group: GroupQuestionsQuery["deal"]["allGroups"][0];
  isFirst: boolean;
  isLast: boolean;
}

function Group(props: GroupProps) {
  return (
    <div
      key={props.group.id}
      className={`w-full 3xl:w-4/5 hover:bg-gray-200/70 cursor-pointer ${
        props.isFirst ? "rounded-t-md" : ""
      } ${props.isLast ? "rounded-b-md" : ""}`}
    >
      <Link to={`/questions/group/${props.group.id}`}>
        <div className="flex px-3 py-2 items-center justify-between space-x-3">
          <div className="flex-1">
            <p className="text-gray-600 text-sm font-bold">
              {props.group.name}
            </p>
            <p className="text-xs text-gray-500/80 leading-tight text-ellipsis">
              {props.group.questions.filter((q) => !q.answer).length} unanswered
            </p>
          </div>

          <div className="w-52">
            <ProgressBar
              progress={
                props.group.questions.length === 0
                  ? 0
                  : (props.group.questions.filter((q) => q.answer).length /
                      props.group.questions.length) *
                    100
              }
            />
          </div>
        </div>
      </Link>
    </div>
  );
}

function ProgressBar(props: { progress: number }) {
  return (
    <div className="h-1 w-full bg-green-500/20 rounded-md">
      <div
        className="h-1 bg-green-500 rounded-md transition-all duration-300 ease-in-out"
        style={{ width: `${props.progress}%` }}
      ></div>
    </div>
  );
}
