import { DataRoomFolderFragmentFragment } from "@/src/graphql/generated";
import { classNames } from "@/src/utils/cn";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

interface BreadcrumbProps {
  folders: DataRoomFolderFragmentFragment["parentFolders"];
  isLoading: boolean;
  root: string;
  error?: unknown;
}

export function Breadcrumbs(props: BreadcrumbProps) {
  const location = useLocation();

  const [parentFolders, setParentFolders] = useState<
    DataRoomFolderFragmentFragment["parentFolders"]
  >([]);

  useEffect(() => {
    if (props.isLoading || props.error) {
      return;
    }

    setParentFolders(props.folders);
  }, [props.folders, props.isLoading, props.error]);

  const crumbs: {
    name: string;
    link: string;
  }[] = [
    {
      name: "Home",
      link: `/${props.root}`,
    },
    ...parentFolders
      .slice(0, parentFolders.length - 1)
      .map((folder) => {
        return {
          name: folder.name,
          link: `/${props.root}/folder/${folder.id}`,
        };
      })
      .reverse(),
  ];

  return (
    <div className="flex py-1 gap-x-1">
      {crumbs.map((folder, index) => {
        return (
          <NavLink to={folder.link}>
            <div className="flex gap-x-1" key={folder.link}>
              <p
                className={classNames(
                  "m-0 font-semibold text-sm ",
                  location.pathname === folder.link
                    ? "text-purple-500"
                    : "text-gray-500"
                )}
              >
                {folder.name}
              </p>
              {index !== props.folders.length - 1 ? (
                <p className="text-sm text-gray-400">/</p>
              ) : null}
            </div>
          </NavLink>
        );
      })}
    </div>
  );
}
