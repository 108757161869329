import useGqlClient from "../../hooks/useGqlClient";
import {
  AccessType,
  DataRoomPermission,
  DealGroupType,
  FileAccessInput,
  FolderAccessInput,
  useAccessQuery,
} from "../../graphql/generated";
import Loading from "../../components/Loading";
import { useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/auth/selector";
import { Option } from "../../components/tailwind/Dropdown";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Button } from "../../components/tailwind/Button";
import { TextInput } from "../../components/tailwind/TextInput";
import {
  DealRoleRestricted,
  adminRoles,
} from "../../components/DealRoleRestricted";
import { AccessMatrix } from "./AccessMatrix";
import { InviteRequests } from "./InviteRequests";
import Tabs from "@/src/components/Tabs";
import {
  Grid2x2CheckIcon,
  ListCheckIcon,
  UserPlus2Icon,
  PlusIcon,
} from "lucide-react";
import { AccessList } from "./AccessList";
import { InviteGuestModal } from "./InviteGuestModal";
import { DealAccountDetails } from "./DealAccount";
import { CreateGroupModal } from "./CreateGroupModal";
import { InternalAccess } from "./internal";

export function Access() {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const accessQuery = useAccessQuery(client, {
    dealId: activeDealId ? activeDealId : "",
  });

  const [accountSearch, setAccountSearch] = useState("");

  const [openModal, setOpenModal] = useState<
    "" | "firm" | "create_group" | "invite" | "invite_more"
  >("");

  const history = useHistory();

  if (accessQuery.error) {
    return <div>Something went wrong</div>;
  }

  if (accessQuery.isPending || !accessQuery.data) {
    return <Loading />;
  }

  return (
    <div className="flex-1 flex flex-col">
      <div className="p-8 pt-4 px-8 flex-1 flex flex-col ">
        <Switch>
          <Route path="/deal/access" exact>
            <Redirect to="/deal/access/internal" />
          </Route>
          <Route path={`/deal/access/internal`}>
            <InternalAccess />
          </Route>
          <Route path={`/deal/access/guest/account/:dealAccountId`} exact>
            <div className={`flex-1 flex flex-col`}>
              <DealAccountDetails />
            </div>
          </Route>
          <Route path={`/deal/access/guest/`}>
            <div
              className={`flex flex-col flex-1 min-h-full xl:flex-row  xl:gap-x-7`}
            >
              <DealRoleRestricted roles={adminRoles}>
                <div className=" w-full flex-1 flex flex-col xl:gap-y-4">
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold text-gray-900">
                        Guests
                      </h1>
                      <p className="text-sm text-gray-700">
                        These are all the people outside of your firm who have
                        access to this deal.
                      </p>
                    </div>
                    <div className="mt-4 gap-x-2 flex items-center sm:ml-16 sm:mt-0 sm:flex-none">
                      <Button
                        text="Create Group"
                        variant="neutral"
                        size="s"
                        icon={PlusIcon}
                        onClick={() => {
                          setOpenModal("create_group");
                        }}
                      />
                      <Button
                        text="Invite guests"
                        variant="positive"
                        size="s"
                        icon={UserPlus2Icon}
                        onClick={() => {
                          setOpenModal("invite");
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-full xl:w-1/3">
                    <p className="font-semibold text-gray-600 text-sm">
                      Search
                    </p>
                    <TextInput
                      placeholder="Search for people via name or email..."
                      value={accountSearch}
                      onChange={(e) => setAccountSearch(e.target.value)}
                    />
                  </div>
                  <Tabs
                    type="pill"
                    tabs={[
                      {
                        label: "List",
                        content: (
                          <div className="mt-4">
                            <AccessList
                              dealId={accessQuery.data.deal.id}
                              dataRoomId={accessQuery.data.deal.dataRoom.id}
                              permissions={
                                accessQuery.data.deal.dataRoom.permissions
                              }
                              activeDealAccount={
                                accessQuery.data.deal.activeDealAccount
                              }
                              groups={accessQuery.data.deal.guestGroups}
                              invites={accessQuery.data.deal.invites}
                              otherGuests={accessQuery.data.deal.otherGuests}
                              accountSearch={accountSearch}
                            />
                          </div>
                        ),
                        icon: <ListCheckIcon className="w-4 h-4" />,
                      },
                      {
                        label: "Matrix",
                        icon: <Grid2x2CheckIcon className="w-4 h-4" />,
                        content: (
                          <div className="mt-4 flex-1 flex flex-col">
                            <AccessMatrix
                              dealId={accessQuery.data.deal.id}
                              dataRoomId={accessQuery.data.deal.dataRoom.id}
                              accountSearch={accountSearch}
                            />
                          </div>
                        ),
                      },
                    ]}
                  />
                </div>
              </DealRoleRestricted>
            </div>
          </Route>
          <Route path={`/deal/access/requests`}>
            <div className="flex-1">
              <InviteRequests />
            </div>
          </Route>
        </Switch>
      </div>
      <CreateGroupModal
        permissions={accessQuery.data.deal.dataRoom.permissions}
        dealId={activeDealId ? activeDealId : ""}
        type={DealGroupType.Guest}
        open={openModal === "create_group"}
        onClose={() => {
          setOpenModal("");
        }}
        onCreate={(id) => {
          setOpenModal("");
          accessQuery.refetch();
        }}
      />
      <InviteGuestModal
        open={openModal === "invite"}
        onClose={() => {
          setOpenModal("");
        }}
        dealId={activeDealId ? activeDealId : ""}
        permissions={accessQuery.data.deal.dataRoom.permissions}
      />
    </div>
  );
}

export interface GranularAccess {
  folders: {
    [key: string]: {
      accessType: AccessType;
      parentFolders: string[];
    };
  };

  files: {
    [key: string]: {
      accessType: AccessType;
      dataRoomPermission: Pick<
        DataRoomPermission,
        "id" | "name" | "description"
      >;
      folderId: string;
    };
  };
}

export function accessTypeToOption(accessType: AccessType): Option {
  switch (accessType) {
    case AccessType.NoAccess:
      return { label: "No access", value: AccessType.NoAccess };
    case AccessType.View:
      return { label: "View", value: AccessType.View };
    case AccessType.Download:
      return { label: "Download", value: AccessType.Download };
    case AccessType.Write:
      return { label: "Edit", value: AccessType.Write };
  }
}

export function granularAccessToFileInput(
  granularAccess: GranularAccess
): Array<FileAccessInput> {
  // recurse through all child folders and append to array
  let files: FileAccessInput[] = [];

  Object.keys(granularAccess.files).forEach((fileId) => {
    files.push({
      fileId,
      type: granularAccess.files[fileId].accessType,
    });
  });

  return files;
}

export function granularAccessToFolderInput(
  granularAccess: GranularAccess
): Array<FolderAccessInput> {
  let folders: FolderAccessInput[] = [];

  Object.keys(granularAccess.folders).forEach((folderId) => {
    folders.push({
      folderId,
      type: granularAccess.folders[folderId].accessType,
    });
  });

  return folders;
}
