import { NavLink, useHistory, useParams } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { FilePill } from "../../../components/FilePill";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { classNames } from "../../../utils/cn";
import Loading from "../../../components/Loading";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import {
  AllDataRoomFoldersQuery,
  DataRoomFileUploadStatus,
  DeepSearchesQuery,
  DeepSearchFileInput,
  DeepSearchQuery,
  FileType,
  useAllDataRoomFoldersQuery,
  useCreateDeepSearchMutation,
  useDataRoomFileVersionQuery,
  useDeepSearchesQuery,
  useDeepSearchQuery,
  useFileVersionDownloadUrlQuery,
  useUpdateDeepSearchMutation,
} from "../../../graphql/generated";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { Button } from "../../../components/tailwind/Button";
import { useDispatch, useSelector } from "react-redux";
import { authSelectors } from "../../../store/auth/selector";
import useGqlClient from "../../../hooks/useGqlClient";
import { toasts } from "../../../components/toasts/toasts";
import { useEffect, useState } from "react";
import { EditableText } from "../../../components/EditableText";
import { Pills } from "../../../components/Pills";
import { AddFilesContent } from "../error-checking";
import { PdfViewerDocumentId } from "../../../components/PdfViewer";
import { Spinner } from "../../../components/icons/Spinner";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React from "react";
import { AppState } from "../../../store";
import { deepSearchSelectors } from "../../../store/deep-search/selector";
import { actions } from "../../../store/deep-search/slice";

export function DeepSearch() {
  const { id } = useParams<{ id: string }>();
  const showSidebar = useSelector((state: AppState) =>
    deepSearchSelectors.showSidebar(state, id)
  );

  console.log("showSidebar", showSidebar);

  return (
    <div className="flex-1 flex flex-col ">
      <div className="flex-1 flex flex-col h-full w-full ">
        <div className="lg:hidden flex  items-center justify-center flex-1">
          <p className="font-semibold text-gray-600">Not supported on mobile</p>
        </div>
        <div className="hidden lg:flex flex-1 ">
          <div className="flex flex-1 relative ">
            {showSidebar ? <Sidebar /> : null}
            <DeepSearchContent id={id} />
          </div>
        </div>
      </div>
    </div>
  );
}

function DeepSearchContent(props: { id: string | undefined }) {
  if (!props.id) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <p className="text-xs text-gray-500">No search selected</p>
      </div>
    );
  }

  return <DeepSearchContentInner id={props.id} />;
}

function DeepSearchContentInner(props: { id: string }) {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const deepSearch = useDeepSearchQuery(client, { id: props.id });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const updateDeepSearch = useUpdateDeepSearchMutation(client);
  const [showFileDetails, setShowFileDetails] = useState(false);
  const [showAddFiles, setShowAddFiles] = useState(false);
  const dispatch = useDispatch();
  const showSidebar = useSelector((state: AppState) =>
    deepSearchSelectors.showSidebar(state, props.id)
  );

  if (deepSearch.error) {
    return (
      <div className="flex-1 flex items-center justify-center h-full">
        <p className="text-xs text-gray-500">Something went wrong</p>
      </div>
    );
  }

  if (deepSearch.isLoading || !deepSearch.data) {
    return (
      <div className="flex-1 flex items-center justify-center h-full">
        <Loading />
      </div>
    );
  }

  if (deepSearch.data.deepSearch.files.length === 0) {
    return <AddFiles deepSearchId={props.id} dealId={activeDealId ?? ""} />;
  }

  return (
    <div className="w-full">
      <div className="w-full px-3 justify-between h-12 bg-white flex items-center border-b border-gray-200">
        {/* <p className="font-semibold text-sm text-gray-700">
          {errorCheck.data.errorCheck.name
            ? errorCheck.data.errorCheck.name
            : "New error check"}
        </p> */}
        <EditableText
          initialText={
            deepSearch.data.deepSearch.name
              ? deepSearch.data.deepSearch.name
              : "New search"
          }
          onSave={(text) => {
            const fileUpdate: DeepSearchFileInput[] =
              deepSearch.data.deepSearch.files.map((f) => {
                return {
                  dataRoomFileID: f.file.id,
                  dataRoomFileVersionID: f.currentVersion.id,
                };
              });

            updateDeepSearch.mutate(
              {
                input: {
                  id: props.id,
                  name: text,
                  files: fileUpdate,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["DeepSearch", { id: props.id }],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["DeepSearches", { dealId: activeDealId ?? "" }],
                  });
                },
                onError: () => {
                  toasts.error("Failed to update");
                },
              }
            );
          }}
          onCancel={() => {}}
        />
        <div className="w-1/3">
          <input
            className="h-8 hidden xl:flex cursor-text items-center w-full border-0 bg-gray-100 rounded-md shadow-sm pl-2 pr-3 text-gray-900 placeholder:text-gray-400  sm:text-sm focus:outline-none"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </div>
        <div className="flex items-center gap-x-2">
          <Button
            size="s"
            variant="neutral"
            text={showSidebar ? "Expand" : "Collapse"}
            icon={showSidebar ? ArrowsPointingOutIcon : ArrowsPointingInIcon}
            onClick={() => {
              dispatch(
                actions.setShowSidebar({
                  deepSearchId: props.id,
                  show: !showSidebar,
                })
              );
            }}
          />
        </div>
      </div>
      <div className="w-full flex-1 flex overflow-x-scroll">
        <DeepSearchFiles
          deepSearch={deepSearch.data.deepSearch}
          search={searchTerm}
        />
      </div>
      {/* <SlideOver
        open={showFileDetails}
        onClose={() => setShowFileDetails(false)}
        errorCheck={errorCheck.data.errorCheck}
      /> */}
      {/* <AddFilesModal
        open={showAddFiles}
        onClose={() => setShowAddFiles(false)}
        dealId={activeDealId ?? ""}
        errorCheckId={props.id}
      /> */}
    </div>
  );
}

function DeepSearchFiles(props: {
  deepSearch: DeepSearchQuery["deepSearch"];
  search: string;
}) {
  return (
    <div className="flex flex-1 w-[1000px] overflow-x-auto">
      {props.deepSearch.files.map((file) => {
        return (
          <FileViewWrapper
            key={`${file.file.id}-${props.search}`}
            file={file}
            search={props.search}
          />
        );
      })}
    </div>
  );
}

function FileViewPspdfkit(props: {
  file: DeepSearchQuery["deepSearch"]["files"][0];
  search: string;
}) {
  const client = useGqlClient();

  const dataRoomFileVersion = useDataRoomFileVersionQuery(client, {
    id: props.file.currentVersion.id,
  });

  if (
    dataRoomFileVersion.error ||
    (dataRoomFileVersion.data &&
      !dataRoomFileVersion.data.dataRoomFileVersion.pspdfkitDocumentId)
  ) {
    return <FileViewContent file={props.file} />;
  }

  if (dataRoomFileVersion.isLoading || !dataRoomFileVersion.data) {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  const drfv = dataRoomFileVersion.data.dataRoomFileVersion;

  return (
    <div style={{ height: "100vh", width: "100%", minWidth: 600 }}>
      <PdfViewerDocumentId
        documentId={drfv.pspdfkitDocumentId}
        token={drfv.pspdfkitToken}
        showToolbar={false}
        search={props.search}
      />
    </div>
  );
}

export function FileViewWrapper(props: {
  file: DeepSearchQuery["deepSearch"]["files"][0];
  search: string;
}) {
  if (
    props.file.file.uploadStatus !== DataRoomFileUploadStatus.Ready &&
    props.file.file.uploadStatus !== DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (
    props.file.file.fileType === FileType.Pdf ||
    props.file.file.fileType === FileType.Docx ||
    props.file.file.fileType === FileType.Pptx
  ) {
    return <FileViewPspdfkit file={props.file} search={props.search} />;
  }

  return <FileViewContent file={props.file} />;
}

function FileViewContent(props: {
  file: DeepSearchQuery["deepSearch"]["files"][0];
}) {
  const client = useGqlClient();
  const [url, setUrl] = React.useState<string>("");
  const [fileDownloadError, setFileDownloadError] = React.useState<string>("");

  const fileDownloadUrl = useFileVersionDownloadUrlQuery(
    client,
    {
      id: props.file.currentVersion.id,
    },
    {
      refetchOnWindowFocus(query) {
        if (query.isStaleByTime(1000 * 60 * 5)) {
          return true;
        }

        return false;
      },
    }
  );

  useEffect(() => {
    if (
      fileDownloadUrl.data &&
      fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl !== url
    ) {
      setFileDownloadError("");
      setUrl(fileDownloadUrl.data.fileVersionDownloadUrl.viewUrl);
      return;
    }

    if (fileDownloadUrl.error) {
      setFileDownloadError("Unable to load file");
      return;
    }
  }, [
    fileDownloadUrl.data,
    fileDownloadUrl.isRefetching,
    fileDownloadUrl.isPending,
    fileDownloadUrl.error,
    url,
  ]);

  if (
    props.file.file.uploadStatus !== DataRoomFileUploadStatus.Ready &&
    props.file.file.uploadStatus !== DataRoomFileUploadStatus.Uploaded
  ) {
    return (
      <div style={{ height: 800 }} className="flex items-center justify-center">
        <Spinner color="gray" size="s" />
        <p className="font-semibold text-gray-700 text-sm">Preparing file...</p>
      </div>
    );
  }

  if (url === "") {
    return (
      <div style={{ height: 800 }}>
        <Loading />
      </div>
    );
  }

  if (fileDownloadError) {
    return (
      <div className="flex items-center justify-center">
        <p>Failed to load file</p>
        <Button
          variant="neutral"
          text="Retry"
          isLoading={fileDownloadUrl.isPending || fileDownloadUrl.isRefetching}
          loadingText="Retrying..."
          onClick={() => {
            fileDownloadUrl.refetch();
          }}
        />
      </div>
    );
  }

  return (
    <DocViewer
      key={url}
      config={{
        pdfVerticalScrollByDefault: true,
        header: {
          disableFileName: true,
          disableHeader: true,
        },
      }}
      prefetchMethod="GET"
      style={{ height: 800, width: "100%", overflowY: "scroll" }}
      documents={[
        {
          uri: url,
        },
      ]}
      pluginRenderers={DocViewerRenderers}
    />
  );
}
function Sidebar() {
  const activeDealId = useSelector(authSelectors.activeDealId);
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const createDeepSearch = useCreateDeepSearchMutation(client);

  const deepSearches = useDeepSearchesQuery(client, {
    dealId: activeDealId ?? "",
  });

  useEffect(() => {
    if (id) {
      return;
    }

    if (deepSearches.data && deepSearches.data.deepSearches.length > 0) {
      history.push(
        `/toolkit/deep-search/${deepSearches.data.deepSearches[0].id}`
      );
    }
  }, [deepSearches.data, id]);

  function createDeepSearchFn() {
    if (!activeDealId) return;
    createDeepSearch.mutate(
      {
        input: {
          dataRoomFileIDs: [],
          dealID: activeDealId ?? "",
        },
      },
      {
        onSuccess: (dat) => {
          queryClient.invalidateQueries({
            queryKey: ["DeepSearches", { dealId: activeDealId ?? "" }],
          });
          history.push(
            `/toolkit/document-search-and-comparison/${dat.createDeepSearch.id}`
          );
        },
        onError: () => {
          toasts.error("Failed to create document search & comparison");
        },
      }
    );
  }

  return (
    <div className="min-w-80 bg-white border-r border-gray-200 border-b">
      <div className="flex justify-between items-center p-3 py-2.5 ">
        <p className="font-semibold text-sm text-gray-600">Recent searches</p>
        <button
          onClick={() => {
            createDeepSearchFn();
          }}
          className="flex items-center p-0.5 border rounded-md border-gray-300 hover:border-gray-500 hover:bg-gray-50"
        >
          <PlusIcon className="h-5 w-5 " />
        </button>
      </div>
      <SidebarList
        query={deepSearches}
        onCreate={() => {
          createDeepSearchFn();
        }}
      />
    </div>
  );
}

function SidebarList(props: {
  query: UseQueryResult<DeepSearchesQuery, unknown>;
  onCreate: () => void;
}) {
  if (props.query.error) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-xs text-gray-500">Something went wrong</p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loading />
      </div>
    );
  }

  if (props.query.data.deepSearches.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <div>
          <p className=" text-center text-sm text-gray-500">No searches</p>
          <Button
            onClick={props.onCreate}
            margin="s 0 0 0"
            icon={PlusIcon}
            variant="neutral"
            text="New search"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "calc(100vh)",
      }}
      className="overflow-y-scroll "
    >
      <TransitionGroup>
        {props.query.data.deepSearches.map((ds) => {
          return (
            <CSSTransition
              key={ds.id}
              timeout={300} // This controls the duration of the animation
              classNames="fade-slide-down"
              onEnter={(node: any) => node.offsetHeight} // Trigger reflow to enable animation
            >
              <NavLink
                key={ds.id}
                to={`/toolkit/document-search-and-comparison/${ds.id}`}
                className={(isActive) => {
                  return classNames(
                    "px-3 py-3 hover:bg-gray-100 font-semibold text-gray-600 flex items-center"
                  );
                }}
                activeClassName="bg-gray-100 text-gray-500"
              >
                <div className="cursor-pointer ">
                  <p className="text-sm text-gray-500 font-semibold">
                    {ds.name ? ds.name : "New search"}
                  </p>
                  <p className="text-xs text-gray-500 font-light">
                    {formatDistanceToNowStrict(fromUnixTime(ds.createdAt), {
                      addSuffix: true,
                    })}
                  </p>
                  <div className="overflow-x-scroll flex flex-wrap gap-2 mt-2 w-72 scrollbar-none">
                    {ds.files.map((file) => {
                      return (
                        <FilePill
                          key={file.file.id}
                          id={file.file.id}
                          name={file.file.name}
                          type={file.file.fileType}
                          showDetailsCard={false}
                        />
                      );
                    })}
                  </div>
                </div>
              </NavLink>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
}

interface SelectedFiles {
  files: {
    [key: string]: {
      selected: boolean;
      file: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0]["files"][0];
    };
  };
}

function AddFiles(props: { deepSearchId: string; dealId: string }) {
  const client = useGqlClient();
  const queryClient = useQueryClient();

  const allFoldersQuery = useAllDataRoomFoldersQuery(client, {
    dealId: props.dealId,
  });

  const deepSearch = useDeepSearchQuery(client, { id: props.deepSearchId });
  const updateDeepSearch = useUpdateDeepSearchMutation(client);

  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedFiles, setSelectedFiles] = useState<SelectedFiles>({
    files: {},
  });

  useEffect(() => {
    if (!allFoldersQuery.data || !deepSearch.data) {
      return;
    }

    const selectedFiles: SelectedFiles = {
      files: {},
    };

    allFoldersQuery.data.deal.dataRoom.allFolders.forEach((folder) => {
      folder.files.forEach((file) => {
        selectedFiles.files[file.id] = {
          selected: deepSearch.data.deepSearch.files.find(
            (f) => f.file.id === file.id
          )
            ? true
            : false,
          file: file,
        };
      });
    });

    setSelectedFiles(selectedFiles);
  }, [allFoldersQuery.data, deepSearch.data]);

  function handleFileToggle(fileId: string, selected: boolean) {
    const newSelectedFiles = { ...selectedFiles };

    newSelectedFiles.files[fileId].selected = selected;
    setSelectedFiles(newSelectedFiles);
  }

  return (
    <div className="flex-1 mt-12 flex-col items-center flex">
      <div className="justify-start flex flex-col  w-2/3">
        <p className="font-semibold text-sm text-gray-700">No files</p>
        <p className="text-xs text-gray-500">Select files you want to search</p>
      </div>
      <div className="mt-4 w-2/3 rounded-md border-gray-200 border overflow-y-hidden">
        <div className="w-full">
          <input
            type="text"
            className="px-3 py-1.5 text-sm focus:ring-0 border-b border-x-0 border-t-0 border-gray-200 focus:border-gray-200"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value);
            }}
          />
        </div>

        <AddFilesContent
          query={allFoldersQuery}
          onFileSelected={(fileId) => {
            handleFileToggle(fileId, !selectedFiles.files[fileId].selected);
          }}
          searchTerm={searchTerm}
          selectedFiles={selectedFiles}
        />
      </div>

      <div className="w-2/3 flex mt-3 justify-between ">
        {Object.keys(selectedFiles.files).filter(
          (fileId) => selectedFiles.files[fileId].selected
        ).length === 0 ? (
          <p className="text-xs text-gray-500">No files selected</p>
        ) : (
          <Pills>
            {Object.keys(selectedFiles.files).map((fileId) => {
              if (!selectedFiles.files[fileId].selected) {
                return null;
              }
              return (
                <FilePill
                  key={fileId}
                  id={fileId}
                  name={selectedFiles.files[fileId].file.name}
                  type={selectedFiles.files[fileId].file.fileType}
                />
              );
            })}
          </Pills>
        )}

        <div className=" justify-end flex-shrink-0 items-start">
          <Button
            variant="positive"
            text="Add files"
            isLoading={updateDeepSearch.isPending}
            loadingText="Adding files..."
            isDisabled={
              Object.keys(selectedFiles.files).filter(
                (fileId) => selectedFiles.files[fileId].selected
              ).length === 0
            }
            onClick={() => {
              if (
                Object.keys(selectedFiles.files).filter(
                  (fileId) => selectedFiles.files[fileId].selected
                ).length === 0
              ) {
                return;
              }

              if (updateDeepSearch.isPending) {
                return;
              }

              const files: DeepSearchFileInput[] = Object.keys(
                selectedFiles.files
              )
                .filter((fileId) => selectedFiles.files[fileId].selected)
                .map((fileId) => {
                  return {
                    dataRoomFileID: selectedFiles.files[fileId].file.id,
                    dataRoomFileVersionID:
                      selectedFiles.files[fileId].file.latestVersion.id,
                  };
                });
              updateDeepSearch.mutate(
                {
                  input: {
                    id: props.deepSearchId,
                    files,
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: ["DeepSearch", { id: props.deepSearchId }],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ["DeepSearches", { dealId: props.dealId }],
                    });
                  },
                  onError: () => {
                    toasts.error("Failed to add files");
                  },
                }
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
