import theme from "./theme";

export interface WithPaddingProp {
  padding?: string;
}

interface SizeMap {
  [K: string]: string;
}

const sizes: SizeMap = theme.spacing;

export default function withPadding({ padding = "" }: WithPaddingProp) {
  const trimmedPadding = padding.trim();

  if (!trimmedPadding) {
    return "";
  }

  const paddings: string[] = trimmedPadding
    .split(" ")
    .filter(Boolean)
    .map((sizeStr: string) => {
      if (Object.keys(sizes).includes(sizeStr)) {
        return sizes[sizeStr];
      } else if (sizeStr === "0") {
        return "0";
      } else {
        return "";
      }
    });

  switch (paddings.slice(0, 4).length) {
    case 1:
      return `
        padding: ${paddings[0]};
      `;
    case 2:
      return `
        padding: ${paddings[0]} ${paddings[1]};
      `;
    case 3:
      return `
        padding: ${paddings[0]} ${paddings[1]} ${paddings[2]};
      `;
    case 4:
      return `
        padding: ${paddings[0]} ${paddings[1]} ${paddings[2]} ${paddings[3]};
      `;
    default:
      return `
        padding: 0;
      `;
  }
}

const twSizes: SizeMap = {
  xs: "1",
  s: "2",
  m: "3",
  l: "4",
  xl: "6",
  xxl: "8",
  xxxl: "10",
};

const paddings: SizeMap = {
  "0": "p-0",
  xs: "p-1",
  s: "p-2",
  m: "p-3",
  l: "p-4",
  xl: "p-6",
  xxl: "p-8",
  xxxl: "p-10",
};

const paddingXs: SizeMap = {
  "0": "px-0",
  xs: "px-1",
  s: "px-2",
  m: "px-3",
  l: "px-4",
  xl: "px-6",
  xxl: "px-8",
  xxxl: "px-10",
};

const paddingYs: SizeMap = {
  "0": "py-0",
  xs: "py-1",
  s: "py-2",
  m: "py-3",
  l: "py-4",
  xl: "py-6",
  xxl: "py-8",
  xxxl: "py-10",
};

const paddingRs: SizeMap = {
  "0": "pr-0",
  xs: "pr-1",
  s: "pr-2",
  m: "pr-3",
  l: "pr-4",
  xl: "pr-6",
  xxl: "pr-8",
  xxxl: "pr-10",
};

const paddingLs: SizeMap = {
  "0": "pl-0",
  xs: "pl-1",
  s: "pl-2",
  m: "pl-3",
  l: "pl-4",
  xl: "pl-6",
  xxl: "pl-8",
  xxxl: "pl-10",
};

const paddingTops: SizeMap = {
  "0": "pt-0",
  xs: "pt-1",
  s: "pt-2",
  m: "pt-3",
  l: "pt-4",
  xl: "pt-6",
  xxl: "pt-8",
  xxxl: "pt-10",
};

const paddingBottoms: SizeMap = {
  "0": "pb-0",
  xs: "pb-1",
  s: "pb-2",
  m: "pb-3",
  l: "pb-4",
  xl: "pb-6",
  xxl: "pb-8",
  xxxl: "pb-10",
};

export function withPaddingTw({ padding = "" }: WithPaddingProp) {
  const trimmedPadding = padding.trim();

  if (!trimmedPadding) {
    return "";
  }

  const trimmedPaddings: string[] = trimmedPadding
    .split(" ")
    .filter(Boolean)
    .map((sizeStr: string) => {
      if (Object.keys(twSizes).includes(sizeStr)) {
        return sizeStr;
      } else if (sizeStr === "0") {
        return "0";
      } else {
        return "";
      }
    });

  switch (trimmedPaddings.slice(0, 4).length) {
    case 1:
      const m = `${paddings[trimmedPaddings[0]]}`;
      return m;
    case 2:
      const m2 = `${paddingYs[trimmedPaddings[0]]} ${
        paddingXs[trimmedPaddings[1]]
      }`;
      return m2;
    case 3:
      const m3 = `${paddingTops[trimmedPaddings[0]]} ${
        paddingXs[trimmedPaddings[1]]
      } ${paddingBottoms[trimmedPaddings[2]]}`;
      return m3;
    case 4:
      const m4 = `${paddingTops[trimmedPaddings[0]]} ${
        paddingRs[trimmedPaddings[1]]
      } ${paddingBottoms[trimmedPaddings[2]]} ${paddingLs[trimmedPaddings[3]]}`;
      return m4;
    default:
      return "p-0";
  }
}
