import { classNames } from "../utils/cn";

interface Props {
  logo?: string | null;
  name: string;
  bgColor: string;
  size?: number;
  withShadow?: boolean;
  borderSize?: string;
  borderColor?: string;
}

const CompanyLogoBg = ({
  logo,
  name,
  bgColor,
  size = 36,
  withShadow = true,
  borderSize = "2",
  borderColor = "#ffffff",
}: Props) => {
  const containerClasses = classNames(
    "border-white flex justify-center items-center rounded-full cursor-pointer select-none flex-shrink-0",
    withShadow ? "shadow-lg" : "",
    size < 24 ? "border-none" : `border-${borderSize}`
  );

  const style = {
    width: size,
    height: size,
    backgroundColor: logo ? "transparent" : `${bgColor}CC`,
    backgroundImage: logo ? `url(${logo})` : "none",
    backgroundSize: "cover",
    borderColor: borderColor,
  };

  return (
    <div className={containerClasses} style={style}>
      {!logo && (
        <p
          className={`font-semibold text-white ${
            size < 24 ? "text-xs" : "text-sm"
          }`}
        >
          {name.length > 2 ? name.substring(0, 2) : name}
        </p>
      )}
    </div>
  );
};

const Avatar = ({
  url,
  size = 36,
  withShadow = true,
  isBlurry = false,
  borderSize = "2",
  borderColor = "#ffffff",
}: {
  url?: string | null;
  size?: number;
  withShadow?: boolean;
  isBlurry?: boolean;
  borderSize?: string;
  borderColor?: string;
}) => {
  const containerClasses = [
    "rounded-full cursor-pointer select-none flex-shrink-0",
    withShadow ? "shadow-lg" : "",
    isBlurry ? "filter blur" : "",
    `border-${borderSize}`,
  ].join(" ");

  const style = {
    width: size,
    height: size,
    backgroundImage: url ? `url(${url})` : "none",
    backgroundSize: "cover",
    borderColor: borderColor,
  };

  return <div className={containerClasses} style={style}></div>;
};

export default function CompanyLogo(props: Props) {
  if (props.logo) {
    return (
      <Avatar
        url={props.logo}
        size={props.size}
        withShadow={props.withShadow}
        borderSize={props.borderSize}
        borderColor={props.borderColor}
      />
    );
  }
  return (
    <CompanyLogoBg
      logo={props.logo}
      name={props.name}
      bgColor={props.bgColor}
      size={props.size}
      withShadow={props.withShadow}
      borderColor={props.borderColor}
      borderSize={props.borderSize}
    />
  );
}
