import { H3 } from "@/src/components/Heading";

import {
  FirmAccountType,
  useAccountQuery,
  useCreateFirmInvitesMutation,
} from "@/src/graphql/generated";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { OnboardingBody } from ".";
import useGqlClient from "@/src/hooks/useGqlClient";
import { Button } from "@/src/components/tailwind/Button";
import { TextInput } from "@/src/components/tailwind/TextInput";
import Dropdown from "@/src/components/tailwind/Dropdown";
import { PlusIcon, TrashIcon } from "lucide-react";
import { toasts } from "@/src/components/toasts/toasts";

interface MemberToInvite {
  email: string;
  role: FirmAccountType;
}

export function InviteFirmMembers() {
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);
  const dispatch = useDispatch();
  const history = useHistory();
  const createFirmInvites = useCreateFirmInvitesMutation(client);
  const [membersToInvite, setMembersToInvite] = useState<MemberToInvite[]>([
    {
      email: "",
      role: FirmAccountType.Admin,
    },
    {
      email: "",
      role: FirmAccountType.Admin,
    },
    {
      email: "",
      role: FirmAccountType.Admin,
    },
  ]);

  return (
    <div className="flex flex-1 flex-col">
      <OnboardingBody>
        <div className="flex flex-col  text-left">
          <H3 margin="0">Invite firm members</H3>
          <p className="text-sm text-gray-500/80">
            Invite your colleagues to your firm so you can start working on
            deals together.
          </p>
        </div>

        <div className="w-full  flex flex-col mt-3 border border-concrete-200 shadow-lg p-4 rounded-md bg-white">
          <div className=" flex flex-col flex-1">
            <form
              className="mt-3 flex flex-col flex-1"
              onSubmit={(e) => {
                e.preventDefault();

                if (!accountQuery.data || !accountQuery.data.account.firm) {
                  return;
                }

                createFirmInvites.mutate(
                  {
                    input: {
                      firmID: accountQuery.data.account.firm.id,
                      invites: membersToInvite.map((mti) => {
                        return {
                          firmID: accountQuery.data.account.firm!.id,
                          email: mti.email,
                          role: mti.role,
                          message: "",
                        };
                      }),
                    },
                  },
                  {
                    onSuccess: (res) => {
                      toasts.success("Invites sent");
                      history.push("/onboarding/create-deal");
                    },
                    onError: () => {
                      toasts.error("Failed to send invites");
                    },
                  }
                );
              }}
            >
              <p className="text-sm font-semibold text-gray-900 mb-2">
                Invites
              </p>
              <div className="flex h-96 overflow-y-auto flex-col gap-y-1.5">
                {membersToInvite.map((mti, i) => {
                  return (
                    <FirmMemberInvite
                      index={i}
                      invite={mti}
                      onMemberInviteChange={(index, invite) => {
                        const invites = [...membersToInvite];
                        invites[index] = invite;
                        setMembersToInvite(invites);
                      }}
                      onRemove={(index) => {
                        const invites = [...membersToInvite];
                        invites.splice(index, 1);
                        setMembersToInvite(invites);
                      }}
                    />
                  );
                })}
                <button
                  onClick={() => {
                    const invites = [...membersToInvite];
                    invites.push({
                      email: "",
                      role: FirmAccountType.Admin,
                    });
                    setMembersToInvite(invites);
                  }}
                  className="text-persian-700 hover:text-persian-900 font-semibold flex items-center text-sm gap-x-1.5 mt-2"
                >
                  <PlusIcon className="w-4 h-4" />
                  Add more
                </button>
              </div>

              <div className=" mt-6 flex gap-x-2 justify-end">
                <Button
                  onClick={() => {
                    history.push("/onboarding/create-deal");
                  }}
                  type="button"
                  variant="neutral"
                  text="Skip"
                  size="s"
                />
                <Button
                  size="s"
                  isLoading={createFirmInvites.isPending}
                  variant="positive"
                  text="Send invites"
                  loadingText="Sending..."
                  type="submit"
                  isDisabled={
                    membersToInvite.filter((mti) => mti.email).length === 0
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </OnboardingBody>
    </div>
  );
}

function FirmMemberInvite(props: {
  invite: MemberToInvite;
  index: number;
  onMemberInviteChange: (index: number, invite: MemberToInvite) => void;
  onRemove: (index: number) => void;
}) {
  const [emailError, setEmailError] = useState<string>("");
  return (
    <div key={props.index} className="group flex items-center gap-x-3">
      <div className="flex-1 lg:max-w-md">
        <TextInput
          value={props.invite.email}
          type="email"
          placeholder="Email address..."
          error={emailError}
          onBlur={() => {
            setEmailError("");
            if (props.invite.email && !props.invite.email.includes("@")) {
              setEmailError("Invalid email");
            }
          }}
          onChange={(e) => {
            setEmailError("");
            props.onMemberInviteChange(props.index, {
              ...props.invite,
              email: e.target.value,
            });
          }}
        />
      </div>
      <Dropdown
        variant="light"
        margin={emailError ? "xs 0 xl 0" : "xs 0 0 0"}
        size="s"
        selectedOption={
          props.invite.role === FirmAccountType.Admin
            ? {
                label: "Admin",
                value: FirmAccountType.Admin,
              }
            : {
                label: "Staff",
                value: FirmAccountType.Staff,
              }
        }
        options={[
          {
            value: FirmAccountType.Admin,
            label: "Admin",
          },
          {
            value: FirmAccountType.Staff,
            label: "Staff",
          },
        ]}
        onSelect={(o) => {
          props.onMemberInviteChange(props.index, {
            ...props.invite,
            role: o.value as FirmAccountType,
          });
        }}
      />
      <button
        onClick={() => {
          props.onRemove(props.index);
        }}
      >
        <TrashIcon className=" mt-1 w-4 h-4 text-gray-500/80 hover:text-gray-700 cursor-pointer opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out" />
      </button>
    </div>
  );
}
