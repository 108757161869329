import { H3 } from "@/src/components/Heading";
import { OnboardingBody } from ".";
import {
  DealRunner,
  useAccountQuery,
  useCreateDealMutation,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { TextInput } from "@/src/components/tailwind/TextInput";
import { TextArea } from "@/src/components/tailwind/TextArea";
import { useState } from "react";
import { Button } from "@/src/components/tailwind/Button";
import { DealSideRadio } from "@/src/components/DealSideRadio";
import { useHistory } from "react-router-dom";
import { toasts } from "@/src/components/toasts/toasts";
import { actions } from "@/src/store/auth/slice";
import { useDispatch } from "react-redux";
import { useWebSocket } from "@/src/contexts/websockets";

export function CreateDeal() {
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);
  const createDeal = useCreateDealMutation(client);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [details, setDetails] = useState("");
  const [side, setSide] = useState<DealRunner>(DealRunner.Seller);

  const history = useHistory();
  const dispatch = useDispatch();
  const { subscribeToTopic } = useWebSocket();

  return (
    <div className="flex flex-1 flex-col">
      <OnboardingBody>
        <div className="flex flex-col  text-left">
          <H3 margin="0">Create your first deal</H3>
          <p className="text-sm text-gray-500/80">
            Deals are where everything for a transaction sits. You can always
            create your first deal later.
          </p>
        </div>

        <div className="w-full  flex flex-col mt-3 border border-concrete-200 shadow-lg p-4 rounded-md bg-white">
          <div className="flex-1 lg:max-w-md">
            <TextInput
              label="Company name"
              placeholder="Acme LLC"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={nameError}
            />
            <div className="flex justify-between items-center mt-4">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Details
              </label>
              <p className="text-xs text-gray-500/80">Optional</p>
            </div>
            <TextArea
              label="Extra details"
              placeholder="Any extra details about the company you want to include..."
              rows={2}
              name="description"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />

            <p className="mt-4 block text-sm font-medium text-gray-900">
              Deal side
            </p>
            <p className="mb-2 text-sm text-gray-500/80">
              Select which side of the deal you are on
            </p>
            <DealSideRadio dealRunner={side} setDealRunner={setSide} />
          </div>
          <div className="flex justify-end gap-x-2 mt-8">
            <Button
              variant="neutral"
              text="Skip"
              size="s"
              onClick={() => {
                history.push("/");
              }}
            />
            <Button
              variant="positive"
              text="Create deal"
              size="s"
              isLoading={createDeal.isPending}
              onClick={() => {
                if (!name) {
                  setNameError("Name is required");
                  return;
                }

                createDeal.mutate(
                  {
                    input: {
                      company: {
                        name: name,
                        description: details,
                        website: "",
                      },
                      runner: side,
                    },
                  },
                  {
                    onSuccess: (data) => {
                      accountQuery.refetch().then((accountData) => {
                        if (!accountData.data) {
                          toasts.error("Failed to create deal");
                          return;
                        }

                        dispatch(
                          actions.refreshAccount({
                            account: accountData.data.account,
                          })
                        );

                        toasts.success("Deal created");
                        history.push("/");

                        subscribeToTopic(`deal:${data.createDeal.id}`);
                      });
                    },
                    onError: () => {
                      toasts.error("Failed to create deal");
                    },
                  }
                );
              }}
            />
          </div>
        </div>
      </OnboardingBody>
    </div>
  );
}
