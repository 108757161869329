import { withMarginTw } from "../styles/withMargin";
import { withPaddingTw } from "../styles/withPadding";

interface Props {
  children: React.ReactNode;
  padding?: string;
  margin?: string;
}

export function Card(props: Props) {
  const padding = withPaddingTw({ padding: props.padding });
  const margin = withMarginTw({ margin: props.margin });

  return (
    <div
      className={`${padding} ${margin} overflow-visible rounded-lg bg-white shadow`}
    >
      <div>{props.children}</div>
    </div>
  );
}
