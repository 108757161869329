import {
  ArchiveBoxXMarkIcon,
  ArrowTopRightOnSquareIcon,
  ExclamationCircleIcon,
  NoSymbolIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import {
  DataRoomContext,
  DataRoomFileQuery,
  DeletionType,
  FileType,
  useCheckFileAccessQuery,
  useDataRoomFileQuery,
  useFileDownloadUrlQuery,
} from "../graphql/generated";
import { FileIcon } from "./FileIcon";
import useGqlClient from "../hooks/useGqlClient";
import { Link } from "react-router-dom";
import { UseQueryResult } from "@tanstack/react-query";
import Loading from "./Loading";
import { classNames } from "../utils/cn";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { AnimatedModal } from "./AnimatedModal";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { CloseIcon } from "./CloseIcon";
import { Spinner } from "./icons/Spinner";
import { DealRoleRestricted, adminRoles } from "./DealRoleRestricted";

function getContextUrl(context: DataRoomContext) {
  switch (context) {
    case DataRoomContext.Deal:
      return "deal";
    case DataRoomContext.Firm:
      return "firm";
    default:
      return "";
  }
}

export function FilePill(props: {
  id: string;
  name: string;
  type: FileType;
  onRemove?: () => void;
  showDetailsCard?: boolean;
  checkFileAccessAccountId?: string;
  noAccessMessage?: string;
  pageIndex?: number;
  highlights?: string[];
  rectsOnPage?: number[][];
  previewText?: string;
  quote?: string;
}) {
  const client = useGqlClient();
  const [showQuickView, setShowQuickView] = useState(false);

  const pillRef = useRef<HTMLDivElement>(null);
  const [cardPosition, setCardPosition] = useState<"above" | "below">("above");

  const dataRoomFileQuery = useDataRoomFileQuery(client, {
    id: props.id,
  });

  const checkFileAccess = useCheckFileAccessQuery(client, {
    accountId: props.checkFileAccessAccountId ?? "",
    fileId: props.id,
  });

  useEffect(() => {
    const checkPosition = () => {
      if (pillRef.current) {
        const pillRect = pillRef.current.getBoundingClientRect();
        const spaceAbove = pillRect.top;
        const cardHeight = 250; // Adjust based on your card's height

        // If there's not enough space above for the card, display it below
        if (spaceAbove < cardHeight) {
          setCardPosition("below");
        } else {
          setCardPosition("above");
        }
      }
    };

    // Attach the event listener to the pill for hover
    const pillElement = pillRef.current;
    if (pillElement) {
      pillElement.addEventListener("mouseenter", checkPosition);
    }

    // Clean up the event listener
    return () => {
      if (pillElement)
        pillElement.removeEventListener("mouseenter", checkPosition);
    };
  }, []);

  if (dataRoomFileQuery.isPending) {
    return (
      <div className="flex flex-wrap group justify-center relative">
        <div className="cursor-pointer  bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
          <Spinner withMargin={false} size="s" color={"gray"} />
          <p className="text-xs ml-2 mt-0.5 truncate">Loading</p>
        </div>
      </div>
    );
  }

  if (
    dataRoomFileQuery.data &&
    dataRoomFileQuery.data.dataRoomFile.deletedAt &&
    dataRoomFileQuery.data.dataRoomFile.deletionType === DeletionType.Hard
  ) {
    return (
      <div className="flex flex-wrap group justify-center relative">
        <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
          <ArchiveBoxXMarkIcon className="w-4 h-4 text-red-500" />
          <p className="text-xs ml-1 mt-0.5 truncate">Deleted file</p>
        </div>
      </div>
    );
  }

  if (
    (dataRoomFileQuery.data &&
      !dataRoomFileQuery.data.dataRoomFile.hasAccess) ||
    (dataRoomFileQuery.error &&
      (dataRoomFileQuery.error as any).message.includes("no permission"))
  ) {
    return (
      <div className="flex flex-wrap group justify-center relative">
        <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
          <NoSymbolIcon className="w-4 h-4 text-gray-500" />
          <p className="text-xs ml-1 mt-0.5 truncate">No access</p>
        </div>
        <span
          className={`transition-all z-50 delay-300 absolute ${
            cardPosition === "above" ? "bottom-8" : "top-8"
          } rounded-md shadow scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 border border-gray-200 bg-white w-64 h-52`}
        >
          <div className="px-3 flex text-center flex-col h-full justify-center items-center">
            <NoSymbolIcon className="w-6 h-6 text-gray-500" />
            <p className="mt-2 text-gray-700 font-semibold">No Access</p>
            <p className="text-sm mt-1 text-gray-500/80 leading-tight">
              You do not have the required permissions to view this file
            </p>
          </div>
        </span>
      </div>
    );
  }

  const baseUrl = dataRoomFileQuery.data
    ? `/${getContextUrl(
        dataRoomFileQuery.data.dataRoomFile.context
      )}/documents/folder/${
        dataRoomFileQuery.data.dataRoomFile.folderId
      }/file/${dataRoomFileQuery.data.dataRoomFile.id}`
    : "";

  const searchParams = new URLSearchParams();

  if (props.pageIndex) {
    searchParams.append("pageIndex", props.pageIndex.toString());
  }

  if (props.highlights && props.highlights.length > 0) {
    searchParams.append("highlights", JSON.stringify(props.highlights));
  }

  console.log("rectsOnPage", props.rectsOnPage);
  if (props.rectsOnPage && props.rectsOnPage.length > 0) {
    searchParams.append("rectsOnPage", JSON.stringify(props.rectsOnPage));
  }

  const to = `${baseUrl}?${searchParams.toString()}`;

  return (
    <div
      ref={pillRef}
      className="flex flex-wrap group justify-center text-left relative"
    >
      <Link to={dataRoomFileQuery.data ? to : ""}>
        <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
          <FileIcon fileType={props.type} size="s" />
          <p className="text-xs ml-1 text-gray-800 truncate">
            {props.name.slice(
              0,
              props.name.length <= 30 ? props.name.length : 30
            )}
            {props.name.length > 30 ? "..." : ""}
          </p>
          {props.pageIndex ? (
            <p className="text-xs truncate text-gray-500/80 ml-1 inline">
              Page {props.pageIndex + 1}
            </p>
          ) : null}
          {checkFileAccess.data && !checkFileAccess.data.checkFileAccess ? (
            <ExclamationCircleIcon className="ml-1 w-4 h-4 text-orange-700 hover:text-orange-800" />
          ) : null}
        </div>
      </Link>
      {props.onRemove ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (props.onRemove) props.onRemove();
          }}
          className="hidden group-hover:block absolute -top-1.5 -right-2.5 group"
        >
          <XCircleIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500" />
        </button>
      ) : null}
      {props.showDetailsCard === undefined || props.showDetailsCard ? (
        <span
          className={`transition-all z-20 delay-300 absolute ${
            cardPosition === "above" ? "bottom-8" : "top-8"
          } rounded-md shadow scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 border border-gray-200 bg-white w-64 h-52`}
        >
          <FileCard
            quote={props.quote}
            onQuickView={() => {
              setShowQuickView(true);
            }}
            query={dataRoomFileQuery}
            noAccessMessage={
              checkFileAccess.data &&
              !checkFileAccess.data.checkFileAccess &&
              props.noAccessMessage
                ? props.noAccessMessage
                : ""
            }
          />
        </span>
      ) : null}

      <AnimatedModal
        open={showQuickView}
        size="xl"
        onClose={() => {
          setShowQuickView(false);
        }}
      >
        <div className="w-full">
          {dataRoomFileQuery.data ? (
            <>
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-x-1.5">
                  <FileIcon
                    fileType={dataRoomFileQuery.data.dataRoomFile.fileType}
                  />
                  <p className="font-semibold mt-1">
                    {dataRoomFileQuery.data.dataRoomFile.name}
                  </p>
                </div>
                <CloseIcon
                  onClose={() => {
                    setShowQuickView(false);
                  }}
                />
              </div>
              <QuickView file={dataRoomFileQuery.data.dataRoomFile} />
            </>
          ) : null}
        </div>
      </AnimatedModal>
    </div>
  );
}

function FileCard(props: {
  onQuickView: () => void;
  query: UseQueryResult<DataRoomFileQuery, unknown>;
  noAccessMessage: string;
  pageNumber?: number;
  previewText?: string;
  highlights?: string[];
  quote?: string;
}) {
  if (props.query.error) {
    return <div className="flex justify-center h-full items-center">Error</div>;
  }

  if (props.query.isLoading || !props.query.data) {
    return (
      <div className="flex h-full justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (props.query.data.dataRoomFile.deletedAt) {
    return (
      <div className="flex flex-col h-full justify-center items-center">
        <ArchiveBoxXMarkIcon className="w-6 h-6 text-gray-500" />
        <p className="text-sm text-gray-500 font-semibold">
          File has been deleted
        </p>
      </div>
    );
  }

  if (props.quote) {
    return (
      <div className=" px-3 py-2 flex h-full flex-col overflow-hidden">
        <div className="overflow-y-scroll p-2 rounded-md border border-gray-200 bg-white">
          <p className="text-sm text-gray-500 ">{props.quote}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="px-3 py-2 flex h-full flex-col overflow-hidden">
      <div className="flex justify-between">
        <div>
          <p className="text-xs text-gray-500 truncate leading-tight">
            {props.query.data.dataRoomFile.parentFolders
              .map((folder) => (folder.name === "" ? "Home" : folder.name))
              .reverse()
              .join(" / ")}
          </p>
          <div className="mt-1 -ml-0.5 flex items-center">
            <FileIcon
              size="m"
              fileType={props.query.data.dataRoomFile.fileType}
            />
            <p className="text-sm font-semibold text-gray-800 ml-1">
              {props.query.data.dataRoomFile.name}
            </p>
          </div>
          <button
            onClick={() => {
              props.onQuickView();
            }}
            className="py-1 px-2 rounded-full gap-x-1 mt-2.5 font-medium text-gray-500 hover:text-gray-600  border border-gray-200 hover:border-gray-400 hover:shadow-sm  flex items-center"
          >
            <p className="text-xs">Quick view</p>
            <ArrowTopRightOnSquareIcon className="w-3 h-3" />
          </button>
        </div>
      </div>
      <div className="mt-3 flex flex-1 flex-col justify-between">
        <div className="flex ">
          <div className="relative flex h-6 w-6 -ml-1.5 flex-none items-center justify-center bg-white">
            <div
              className={classNames(
                "bg-green-400/10 text-green-400 animate-pulse",
                "flex-none rounded-full p-1"
              )}
            >
              <div className="h-2 w-2 rounded-full bg-current" />
            </div>
          </div>
          <div className="ml-1">
            <p className="text-gray-800 font-semibold text-sm">
              {props.query.data.dataRoomFile.latestVersion.summary}
            </p>
            <p className="text-xs text-gray-500 truncate">
              Uploaded{" "}
              {formatDistanceToNowStrict(
                fromUnixTime(
                  props.query.data.dataRoomFile.latestVersion.createdAt
                ),
                {
                  addSuffix: true,
                }
              )}{" "}
              by {props.query.data.dataRoomFile.latestVersion.createdBy.name}
            </p>
          </div>
        </div>
        {props.noAccessMessage ? (
          <div className="flex items-start">
            <ExclamationCircleIcon className="w-5 h-5 text-orange-700 hover:text-orange-800" />
            <p className="ml-1 text-xs text-orange-800 leading-tight">
              {props.noAccessMessage}
            </p>
          </div>
        ) : (
          <div className="flex justify-between mt-3">
            <DealRoleRestricted roles={adminRoles}>
              <div>
                <p className="text-sm text-gray-500 font-medium">Views</p>
                <p className="text-gray-800 font-semibold">
                  {props.query.data.dataRoomFile.stats.views}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500 font-medium">Downloads</p>
                <p className="text-gray-800 font-semibold">
                  {props.query.data.dataRoomFile.stats.downloads}
                </p>
              </div>
            </DealRoleRestricted>
            <div>
              <p className="text-sm text-gray-500 font-medium">Versions</p>
              <p className="text-gray-800 font-semibold">
                {props.query.data.dataRoomFile.versions.length}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function QuickView(props: { file: DataRoomFileQuery["dataRoomFile"] }) {
  const client = useGqlClient();
  const fileDownloadQuery = useFileDownloadUrlQuery(client, {
    id: props.file.id,
  });

  if (fileDownloadQuery.error) {
    return <div>Something went wrong</div>;
  }

  if (fileDownloadQuery.isPending || !fileDownloadQuery.data) {
    return <Loading />;
  }

  return (
    <div>
      <DocViewer
        config={{
          pdfVerticalScrollByDefault: true,
          header: {
            disableFileName: true,
            disableHeader: true,
          },
        }}
        prefetchMethod="GET"
        style={{ height: 800, width: "100%" }}
        documents={[
          {
            uri: fileDownloadQuery.data.fileDownloadUrl.viewUrl,
          },
        ]}
        pluginRenderers={DocViewerRenderers}
      />
    </div>
  );
}
