import { DataRoomHeader } from "@/src/components/data_room/DataRoomHeader";
import Loading from "@/src/components/Loading";
import {
  DataRoomPermission,
  useDataRoomFolderQuery,
  useFirmFilesQuery,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { authSelectors } from "@/src/store/auth/selector";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card } from "@/src/components/Card";
import { Files } from "./data_room/files";

export function FirmFiles() {
  const account = useSelector(authSelectors.account);
  const client = useGqlClient();
  const firmFilesQuery = useFirmFilesQuery(client, {
    id: account && account.firm ? account.firm.id : "",
  });

  const { folderId } = useParams<{ folderId: string }>();

  if (firmFilesQuery.error) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <p>Something went wrong</p>
      </div>
    );
  }

  if (firmFilesQuery.isLoading || !firmFilesQuery.data) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex-1">
      <DataRoomHeader
        title={`${firmFilesQuery.data.firm.name} drive`}
        folderId={
          folderId ? folderId : firmFilesQuery.data.firm.dataRoom.folder.id
        }
        dataRoomId={firmFilesQuery.data.firm.dataRoom.id}
        permissions={[]}
        recentSearchesKey={firmFilesQuery.data.firm.id}
        root="firm/drive"
      />

      <div className="p-8 pt-4">
        <Folder
          id={folderId ? folderId : firmFilesQuery.data.firm.dataRoom.folder.id}
          permissions={[]}
        />
      </div>
    </div>
  );
}

interface FolderProps {
  id: string;
  permissions: DataRoomPermission[];
}

function Folder(props: FolderProps) {
  const client = useGqlClient();
  const folderQuery = useDataRoomFolderQuery(client, {
    id: props.id,
  });

  return (
    <>
      <Card>
        <div className="h-96 overflow-y-scroll no-scrollbar">
          <Files
            isLoading={folderQuery.isPending}
            error={folderQuery.error}
            folder={
              folderQuery.data ? folderQuery.data.dataRoomFolder : undefined
            }
          />
        </div>
      </Card>

      {/* <DeleteFolder
        open={
          query.get("deleteFolder") !== null && query.get("deleteFolder") !== ""
        }
        onClose={() => {
          history.replace({ pathname: location.pathname, search: "" });
        }}
      /> */}
    </>
  );
}
