import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { withMarginTw } from "../styles/withMargin";
import { classNames } from "../utils/cn";

export function Tooltip(props: {
  text: string;
  margin?: string;
  width?: string;
}) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <div
      aria-aria-label={`Tooltip that says: ${props.text}`}
      className={`group ${margin} inline-block relative `}
    >
      <InformationCircleIcon className="w-4 h-4 text-gray-500 hover:text-gray-600" />
      <span
        className={` -translate-x-1/2 py-1 px-1.5 left-1/2 transition-all z-50 bottom-6 delay-300 absolute rounded-md scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 border border-gray-900 bg-gray-900 before:absolute before:left-1/2 before:bottom-0 before:-mb-[8px] before:border-transparent before:border-t-gray-900 before:border-t-[8px] before:border-x-[8px] before:border-b-0 before:content-[''] before:-translate-x-1/2`}
      >
        <p
          className={classNames(
            "text-sm font-semibold text-white",
            props.width ? `${props.width}` : ""
          )}
        >
          {props.text}
        </p>
      </span>
    </div>
  );
}
