import Logo from "../components/Logo";
import { SignIn } from "@clerk/clerk-react";

export function Login() {
  return (
    <div
      style={{ backgroundColor: "#F2F2F2 " }}
      className="flex h-full flex-1 items-center justify-center"
    >
      <div className="hidden xl:block z-0 absolute inset-0 w-1/2 bg-persian-950/90"></div>
      <div className="grid z-10 grid-cols-1 xl:grid-cols-2 max-w-5xl">
        <div className="mr-12 hidden xl:block">
          <Logo width={105} height={35} />
          <p className="text-indigo-50/60 font-semibold">
            Making deals happen.
          </p>

          <p className="text-white mt-4">
            Liquid pulls everything in a deal into one place. Powered by a
            powerful Data Room with a full audit log, granular access control
            and an AI toolkit to increase efficiency.
          </p>

          <div className="flex items-center mt-12 gap-x-3">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://liquidacquire.com/privacy-policy"
              className="text-persian-200 font-semibold hover:text-white text-sm"
            >
              Privacy Policy
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://liquidacquire.com/terms-of-service"
              className="text-persian-200 font-semibold hover:text-white text-sm"
            >
              Terms & Conditions
            </a>
          </div>
        </div>

        <div className="w-full ml-12 flex justify-end flex-col box-border xl:max-w-sm">
          <SignIn
            signUpUrl="/signup"
            appearance={{
              layout: {
                socialButtonsPlacement: "bottom",
              },
            }}
            afterSignInUrl="/"
          />
        </div>
      </div>
    </div>
  );
}
