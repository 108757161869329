import { useDispatch, useSelector } from "react-redux";
import { H3 } from "../../components/Heading";
import { PageContent } from "../../components/PageContent";
import { authSelectors } from "../../store/auth/selector";
import {
  AccountQuery,
  FirmAccountType,
  FirmQuery,
  FirmType,
  useAccountQuery,
  useCreateFirmInviteMutation,
  useCreateFirmMutation,
  useDeleteFirmInviteMutation,
  useFirmQuery,
  useUpdateFirmMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import Loading from "../../components/Loading";
import { Card } from "../../components/Card";
import { Button } from "../../components/tailwind/Button";
import { AnimatedModal } from "../../components/AnimatedModal";
import { TextInput } from "../../components/tailwind/TextInput";
import { useState } from "react";
import { TextArea } from "../../components/tailwind/TextArea";
import { CloseIcon } from "../../components/CloseIcon";
import { useQueryClient } from "@tanstack/react-query";
import Dropdown, { Option } from "../../components/tailwind/Dropdown";
import { formatFirmAccountType, formatFirmType } from "../../utils/enums";
import { Avatar } from "../../components/account/Avatar";
import { PlusIcon } from "@heroicons/react/20/solid";
import { actions } from "../../store/auth/slice";
import { TripleDotMenu } from "../../components/TripleDotMenu";
import { Menu } from "@headlessui/react";
import { toasts } from "../../components/toasts/toasts";
import { classNames } from "../../utils/cn";

export function Firm() {
  const account = useSelector(authSelectors.account);

  if (!account) {
    return null;
  }

  return (
    <div className="flex-1">
      <div className="bg-white px-8 py-5 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>Firm</H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
        </div>
      </div>
      <PageContent>
        {account.firm ? (
          <FirmDetails firm={account.firm} account={account} />
        ) : (
          <CreateFirm />
        )}
      </PageContent>
    </div>
  );
}

function FirmDetails(props: {
  firm: AccountQuery["account"]["firm"];
  account: AccountQuery["account"];
}) {
  const client = useGqlClient();
  const deleteFirmInvite = useDeleteFirmInviteMutation(client);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const firmQuery = useFirmQuery(client, {
    id: props.firm ? props.firm.id : "",
  });

  if (firmQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (firmQuery.isPending || !firmQuery.data) {
    return <Loading />;
  }

  const firm = firmQuery.data.firm;

  return (
    <div>
      <div className="flex flex-col xl:flex-row  xl:gap-x-7">
        <div className="xl:w-80 w-full">
          <Card padding="m">
            <div className="flex justify-between items-start">
              <div>
                <p className="font-semibold text-lg">{firm.name}</p>
                <p className="text-sm text-gray-500 leading-tight">
                  {firm.description}
                </p>
              </div>
              {props.account.firmAccountType === FirmAccountType.Admin ? (
                <button
                  onClick={() => {
                    setShowEditModal(true);
                  }}
                  className="font-semibold text-sm text-indigo-500 hover:text-indigo-600"
                >
                  Edit
                </button>
              ) : null}
            </div>

            <p className="text-sm font-semibold text-gray-800 mt-3">Website</p>
            {firm.website ? (
              <a
                target="_blank"
                href={firm.website}
                className="text-sm text-blue-600 underline"
              >
                {firm.website}
              </a>
            ) : (
              <p className="text-sm text-gray-500">No website</p>
            )}

            <p className="text-sm font-semibold text-gray-800 mt-3">Address</p>
            {firm.address ? (
              <p className="text-sm text-gray-600">{firm.address}</p>
            ) : (
              <p className="text-sm text-gray-500">No address</p>
            )}

            <p className="text-sm font-semibold text-gray-800 mt-3">Type</p>
            <p className="text-sm text-gray-600">{formatFirmType(firm.type)}</p>
          </Card>
        </div>
        <div className="w-full xl:w-1/2">
          <Card padding="m">
            <p className="font-semibold text-gray-800">People</p>
            <div className="mt-3">
              {firm.accounts.map((account) => {
                return (
                  <div key={account.id} className="my-3">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <Avatar account={account} />

                        <div className="ml-2">
                          <p className="text-sm font-semibold text-gray-700 leading-tight">
                            {account.name}
                          </p>
                          <p className="text-sm text-gray-500 leading-tight">
                            {account.email}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm text-gray-500 mt-0.5">
                          {formatFirmAccountType(account.firmAccountType)}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex items-center mt-5 justify-between">
              <p className="font-semibold  text-gray-800">Invites</p>
              {props.account.firmAccountType === FirmAccountType.Admin ? (
                <button
                  onClick={() => {
                    setShowInviteModal(true);
                  }}
                  className="text-indigo-500 text-sm hover:text-indigo-600 font-semibold"
                >
                  <div className="flex items-center">
                    <PlusIcon className="h-4 w-4" />
                    <p>Invite</p>
                  </div>
                </button>
              ) : null}
            </div>
            {firm.invites.length === 0 ? (
              <p className="text-sm text-gray-500">No invites</p>
            ) : null}
            {firm.invites.map((invite) => {
              return (
                <div
                  key={invite.id}
                  className="flex items-center justify-between mt-3"
                >
                  <div>
                    <p className="text-sm font-semibold text-gray-700 leading-tight">
                      {invite.email}
                    </p>
                  </div>
                  <div className="flex items-center gap-x-1.5">
                    <p className="text-sm text-gray-500">
                      {formatFirmAccountType(invite.role)}
                    </p>
                    <TripleDotMenu>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={(e) => {
                              deleteFirmInvite.mutate(
                                {
                                  id: invite.id,
                                },
                                {
                                  onSuccess: () => {
                                    firmQuery.refetch();
                                    toasts.success("Invite deleted");
                                  },
                                  onError: () => {
                                    toasts.error("Failed to delete invite");
                                  },
                                }
                              );
                            }}
                            className={classNames(
                              active ? "bg-gray-50 text-red-700" : "",
                              "block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer"
                            )}
                          >
                            Delete invite
                          </div>
                        )}
                      </Menu.Item>
                    </TripleDotMenu>
                  </div>
                </div>
              );
            })}
          </Card>
        </div>
      </div>
      <EditFirmModal
        firm={firm}
        open={showEditModal}
        onClose={() => {
          setShowEditModal(false);
        }}
      />

      <CreateFirmInviteModal
        firm={firm}
        open={showInviteModal}
        onClose={() => {
          setShowInviteModal(false);
        }}
      />
    </div>
  );
}

function EditFirmModal(props: {
  open: boolean;
  onClose: () => void;
  firm: FirmQuery["firm"];
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const updateFirm = useUpdateFirmMutation(client);

  const [name, setName] = useState(props.firm.name);
  const [nameError, setNameError] = useState("");

  const [description, setDescription] = useState(props.firm.description);
  const [descriptionError, setDescriptionError] = useState("");

  const [selectedType, setSelectedType] = useState<Option>({
    value: props.firm.type,
    label: formatFirmType(props.firm.type),
  });

  const [website, setWebsite] = useState(props.firm.website);
  const [address, setAddress] = useState(props.firm.address);

  return (
    <AnimatedModal open={props.open} onClose={props.onClose}>
      <div>
        <div className="flex items-center justify-between">
          <H3>Edit {props.firm.name}</H3>
          <CloseIcon onClose={props.onClose} />
        </div>
        <form
          className="mt-3"
          onSubmit={(e) => {
            e.preventDefault();

            if (!name || !description) {
              if (!name) {
                setNameError("Name is required");
              }
              if (!description) {
                setDescriptionError("Description is required");
              }
              return;
            }

            updateFirm.mutate(
              {
                input: {
                  id: props.firm.id,
                  name,
                  description,
                  website,
                  address,
                  type: selectedType.value as FirmType,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["Account", {}],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["Firm", { id: props.firm.id }],
                  });
                  props.onClose();
                },
              }
            );
          }}
        >
          <TextInput
            ignore1p
            label="Name"
            placeholder="Enter firm name..."
            value={name}
            onChange={(e) => {
              setNameError("");
              setName(e.currentTarget.value);
            }}
          />

          <label
            htmlFor="description"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Description
          </label>
          <TextArea
            label="Description"
            name="description"
            placeholder="A short description of your firm..."
            rows={2}
            value={description}
            onChange={(e) => [
              setDescriptionError(""),
              setDescription(e.currentTarget.value),
            ]}
          />

          <label
            htmlFor="firm_type_dropdown"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Firm type
          </label>
          <Dropdown
            options={options}
            onSelect={(o) => {
              setSelectedType(o);
            }}
            selectedOption={selectedType}
          />

          <TextInput
            margin="m 0 0 0"
            type="url"
            secondaryLabel="Optional"
            label="Website"
            placeholder="Enter firm website..."
            value={website}
            onChange={(e) => {
              setWebsite(e.currentTarget.value);
            }}
          />

          <TextInput
            ignore1p
            margin="m 0 0 0"
            label="Address"
            secondaryLabel="Optional"
            placeholder="Enter firm address..."
            value={address}
            onChange={(e) => {
              setAddress(e.currentTarget.value);
            }}
          />

          <div className="mt-6 flex justify-end">
            <Button
              isLoading={updateFirm.isPending}
              loadingText="Saving..."
              type="submit"
              variant="positive"
              text="Save"
            />
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}

function CreateFirm() {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex">
      <Card padding="m">
        <div className="h-full flex-1  flex flex-col">
          <p className="font-semibold text-lg text-gray-800">
            You don't have a firm
          </p>
          <p className="text-sm text-gray-500 leading-tight">
            Create one below or ask someone else at your Firm to add you
          </p>
        </div>
        <Button
          margin="xl 0 0 0"
          text="Create firm"
          variant="positive"
          onClick={() => {
            setOpen(true);
          }}
        />
      </Card>
      <CreateFirmModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}

const options: Option[] = [
  {
    value: FirmType.LawFirm,
    label: formatFirmType(FirmType.LawFirm),
  },
  {
    value: FirmType.Accountant,
    label: formatFirmType(FirmType.Accountant),
  },
  {
    value: FirmType.Broker,
    label: formatFirmType(FirmType.Broker),
  },
  {
    value: FirmType.Buyer,
    label: formatFirmType(FirmType.Buyer),
  },
  {
    value: FirmType.Seller,
    label: formatFirmType(FirmType.Seller),
  },
  {
    value: FirmType.Other,
    label: formatFirmType(FirmType.Other),
  },
];

function CreateFirmModal(props: { open: boolean; onClose: () => void }) {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const [website, setWebsite] = useState("");

  const [address, setAddress] = useState("");

  const [type, setType] = useState<Option>(options[0]);

  const queryClient = useQueryClient();
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);
  const createFirmMutation = useCreateFirmMutation(client);
  const dispatch = useDispatch();

  return (
    <AnimatedModal open={props.open} onClose={props.onClose} size="lg">
      <div>
        <div className="flex items-center justify-between">
          <div>
            <H3>Create Firm</H3>
            <p className="text-sm text-gray-500 leading-tight">
              Create a new firm
            </p>
          </div>
          <CloseIcon onClose={props.onClose} />
        </div>

        <form
          className="mt-3 "
          onSubmit={(e) => {
            e.preventDefault();
            if (!name || !description) {
              if (!name) {
                setNameError("Name is required");
              }
              if (!description) {
                setDescriptionError("Description is required");
              }
              return;
            }

            createFirmMutation.mutate(
              {
                input: {
                  name,
                  description,
                  website,
                  address,
                  type: type.value as FirmType,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["Account", {}],
                  });

                  accountQuery.refetch().then((data) => {
                    if (data.data) {
                      dispatch(
                        actions.refreshAccount({ account: data.data.account })
                      );
                      props.onClose();
                    }
                  });
                },
              }
            );
          }}
        >
          <TextInput
            ignore1p
            label="Name"
            placeholder="Enter firm name..."
            onChange={(e) => {
              setNameError("");
              setName(e.currentTarget.value);
            }}
          />

          <label
            htmlFor="description"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Description
          </label>
          <TextArea
            label="Description"
            name="description"
            placeholder="A short description of your firm..."
            error={descriptionError}
            rows={2}
            value={description}
            onChange={(e) => {
              setDescriptionError("");
              setDescription(e.currentTarget.value);
            }}
          />

          <label
            htmlFor="firm_type_dropdown"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Firm type
          </label>
          <Dropdown
            options={options}
            onSelect={(e) => {
              setType(e);
            }}
            selectedOption={type}
          />

          <TextInput
            margin="m 0 0 0"
            type="url"
            secondaryLabel="Optional"
            label="Website"
            placeholder="Enter firm website..."
            value={website}
            onChange={(e) => {
              setWebsite(e.currentTarget.value);
            }}
          />

          <TextInput
            ignore1p
            margin="m 0 0 0"
            label="Address"
            secondaryLabel="Optional"
            placeholder="Enter firm address..."
            value={address}
            onChange={(e) => {
              setAddress(e.currentTarget.value);
            }}
          />

          <div className="mt-6 flex justify-end">
            <Button
              variant="positive"
              text="Create firm"
              loadingText="Creating..."
              type="submit"
              isLoading={createFirmMutation.isPending}
            />
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}

const firmAccounTypeOptions: Option[] = [
  {
    value: FirmAccountType.Admin,
    label: "Admin",
  },
  {
    value: FirmAccountType.Staff,
    label: "Staff",
  },
];

function CreateFirmInviteModal(props: {
  open: boolean;
  onClose: () => void;
  firm: FirmQuery["firm"];
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const createFirmInvite = useCreateFirmInviteMutation(client);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [message, setMessage] = useState("");
  const [type, setType] = useState<Option>(firmAccounTypeOptions[0]);

  function clearForm() {
    setEmail("");
    setMessage("");
    setType(firmAccounTypeOptions[0]);
  }

  function close() {
    clearForm();
    props.onClose();
  }

  return (
    <AnimatedModal open={props.open} onClose={close}>
      <div>
        <div className="flex items-center justify-between">
          <H3>Invite to {props.firm.name}</H3>
          <CloseIcon onClose={close} />
        </div>

        <form
          className="mt-3"
          onSubmit={(e) => {
            e.preventDefault();
            if (!email) {
              setEmailError("Email is required");
              return;
            }

            createFirmInvite.mutate(
              {
                input: {
                  firmID: props.firm.id,
                  email,
                  message,
                  role: type.value as FirmAccountType,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ["Account", {}],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ["Firm", { id: props.firm.id }],
                  });
                  toasts.success("Invite sent");
                  close();
                },
                onError: () => {
                  toasts.error("Failed to send invite");
                },
              }
            );
          }}
        >
          <TextInput
            ignore1p
            label="Email"
            placeholder="Enter email..."
            value={email}
            error={emailError}
            onChange={(e) => {
              setEmailError("");
              setEmail(e.currentTarget.value);
            }}
          />

          <label
            htmlFor="firm_account_type_dropdown"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Account type
          </label>
          <Dropdown
            options={firmAccounTypeOptions}
            selectedOption={type}
            onSelect={(o) => {
              setType(o);
            }}
          />

          <div className="flex items-center justify-between mt-3 ">
            <label
              htmlFor="message"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Message
            </label>

            <label className="text-sm leading-6 text-gray-500">Optional</label>
          </div>
          <TextArea
            rows={2}
            name="message"
            label="Message"
            value={message}
            onChange={(e) => {
              setMessage(e.currentTarget.value);
            }}
          />

          <div className="flex justify-end mt-6">
            <Button
              isLoading={createFirmInvite.isPending}
              variant="positive"
              text="Invite"
              loadingText="Inviting..."
              type="submit"
            />
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}
