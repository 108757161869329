import { NavLink, useParams } from "react-router-dom";
import useGqlClient from "../../../hooks/useGqlClient";
import {
  Question as GqlQuestion,
  DueDiligenceChecklistItemType,
  useDealGroupQuery,
  DealGroupQuery,
} from "../../../graphql/generated";
import { H3 } from "../../../components/Heading";
import { UseQueryResult } from "@tanstack/react-query";
import Loading from "../../../components/Loading";
import { Card } from "../../../components/Card";
import { Pills } from "../../../components/Pills";
import { FilePill } from "../../../components/FilePill";
import { Question } from "../../questions";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../store/auth/selector";
import { Avatar } from "../../../components/account/Avatar";
import { Activity } from "../../../components/activity/Activity";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { DealProgress } from "../../../components/DealProgress";
import { formatDistanceToNow, fromUnixTime } from "date-fns";

export function Buyer() {
  const { id } = useParams<{ id: string }>();
  const client = useGqlClient();
  const dealFirmGroupQuery = useDealGroupQuery(client, {
    id: id,
  });

  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-white sticky top-0 z-20 px-8 py-5 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>
              {!dealFirmGroupQuery.data || dealFirmGroupQuery.isPending
                ? "Loading..."
                : dealFirmGroupQuery.error
                ? "Error"
                : dealFirmGroupQuery.data.dealGroup.name}
            </H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
        </div>
      </div>
      <div className="p-8 flex-1 flex">
        <BuyerContent query={dealFirmGroupQuery} />
      </div>
    </div>
  );
}

function BuyerContent(props: {
  query: UseQueryResult<DealGroupQuery, unknown>;
}) {
  const account = useSelector(authSelectors.account);
  if (props.query.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.query.isPending || !props.query.data) {
    return <Loading />;
  }

  const dealAccounts = props.query.data.dealGroup.dealFirms.flatMap(
    (df) => df.dealAccounts
  );

  return (
    <div className="flex-1">
      <DealProgress
        dealFirmGroupId={props.query.data.dealGroup.id}
        dealStages={props.query.data.dealGroup.deal.dealStages}
        currentDealStage={props.query.data.dealGroup.currentDealStage}
        currentDealStageStatus={
          props.query.data.dealGroup.currentDealStageStatus
        }
      />
      <div className="grid grid-cols-1 2xl:grid-cols-2 mt-8 gap-8">
        <div>
          <H3>Overview</H3>
          <Card margin="m 0 0 0" padding="m">
            <div className="grid grid-cols-2 xl:grid-cols-4 mt-4">
              <div>
                <p className="text-gray-600 font-semibold">Joined deal</p>
                <p className="text-sm text-gray-500">
                  {formatDistanceToNow(
                    fromUnixTime(props.query.data.dealGroup.createdAt),
                    { addSuffix: true }
                  )}
                </p>
              </div>
              <div>
                <p className="text-gray-600 font-semibold">Key files</p>
                {props.query.data.dealGroup.files.length === 0 ? (
                  <p className="text-gray-500 text-sm">No files uploaded yet</p>
                ) : null}
                <Pills>
                  {props.query.data.dealGroup.files
                    .sort((a, b) => {
                      return a.createdAt > b.createdAt ? -1 : 1;
                    })
                    .slice(
                      0,
                      Math.max(5, props.query.data.dealGroup.files.length)
                    )
                    .map((f) => {
                      return (
                        <FilePill id={f.id} name={f.name} type={f.fileType} />
                      );
                    })}
                </Pills>
              </div>
            </div>
          </Card>
        </div>
        <div>
          <div className="flex items-center justify-between">
            <H3>People</H3>
            <NavLink
              to={`/deal/access/group/${props.query.data.dealGroup.id}`}
              className="text-xs font-medium text-indigo-500 hover:text-indigo-600"
            >
              Manage
            </NavLink>
          </div>
          {dealAccounts.length === 0 ? (
            <Card margin="m 0 0 0" padding="m">
              <p className="text-gray-500 text-sm">No accounts added yet</p>
            </Card>
          ) : null}
          <div className="mt-3 gap-4 grid grid-cols-1 xl:grid-cols-2">
            {dealAccounts.map((da) => {
              return (
                <Card key={da.account.email} padding="m">
                  <div className="">
                    <div className="flex items-center space-x-3">
                      <Avatar account={da.account} size="m" />
                      <div className="flex-1">
                        <p className="text-gray-600 text-sm font-bold">
                          {da.account.name}
                        </p>
                        <p className="text-xs text-gray-500 text-ellipsis">
                          {da.account.email}
                        </p>
                        {/* <p className="text-gray-500 text-xs">
                          Last seen 3 hours ago
                        </p> */}
                      </div>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
        <div>
          <H3>Questions</H3>
          <Card margin="s 0 0 0">
            {props.query.data.dealGroup.questions.length === 0 ? (
              <div className="p-3 text-center">
                <p className="text-gray-500 text-sm">No questions asked yet</p>
              </div>
            ) : null}
            {props.query.data.dealGroup.questions.map((question, i) => {
              if (!props.query.data) {
                return null;
              }

              return (
                <>
                  <Question
                    key={question.id}
                    question={question as GqlQuestion}
                    isFirst={i === 0}
                    isLast={
                      i === props.query.data.dealGroup.questions.length - 1
                    }
                  />
                  {i !== props.query.data.dealGroup.questions.length - 1 ? (
                    <div className="border-b border-gray-200" />
                  ) : null}
                </>
              );
            })}
          </Card>
        </div>
        <div>
          <H3>Due Diligence Checklist</H3>
          <DueDiligenceChecklist
            ddChecklistItems={props.query.data.dealGroup.ddChecklistItems}
            dealFirmGroupId={props.query.data.dealGroup.id}
          />
        </div>
      </div>
      <div className="mt-12 w-full xl:w-1/2">
        <H3>Recent activity</H3>
        <Activity
          input={{
            dealGroupID: props.query.data.dealGroup.id,
            count: 30,
          }}
        />
      </div>
    </div>
  );
}

function DueDiligenceChecklist(props: {
  dealFirmGroupId: string;
  ddChecklistItems: DealGroupQuery["dealGroup"]["ddChecklistItems"];
}) {
  const conditionPrecedents = props.ddChecklistItems.filter(
    (dd) => dd.type === DueDiligenceChecklistItemType.ConditionPrecedent
  );
  const conditionSubsequents = props.ddChecklistItems.filter(
    (dd) => dd.type === DueDiligenceChecklistItemType.ConditionSubsequent
  );

  return (
    <Card margin="s 0 0 0">
      <NavLink to={`/buyers/dd-checklists/${props.dealFirmGroupId}`}>
        <div className="p-3 hover:bg-gray-100 rounded-t-md flex items-center justify-between">
          <div>
            <p className="font-semibold text-gray-700">Condition precedents</p>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="w-52">
              <ProgressBar
                progress={
                  conditionPrecedents.length === 0
                    ? 0
                    : (conditionPrecedents.filter((cp) => cp.completedAt)
                        .length /
                        conditionPrecedents.length) *
                      100
                }
              />
            </div>
            <p className="text-xs text-gray-500/80">
              {conditionPrecedents.filter((cp) => cp.completedAt).length}/
              {conditionPrecedents.length}
            </p>
            <ChevronRightIcon className="w-4 h-4 text-gray-500/80" />
          </div>
        </div>
        <div className="p-3 hover:bg-gray-100 rounded-b-md flex items-center justify-between">
          <div>
            <p className="font-semibold text-gray-700">Condition subsequents</p>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="w-52">
              <ProgressBar
                progress={
                  conditionSubsequents.length === 0
                    ? 0
                    : (conditionSubsequents.filter((cp) => cp.completedAt)
                        .length /
                        conditionSubsequents.length) *
                      100
                }
              />
            </div>
            <p className="text-xs text-gray-500/80">
              {conditionSubsequents.filter((cp) => cp.completedAt).length}/
              {conditionSubsequents.length}
            </p>
            <ChevronRightIcon className="w-4 h-4 text-gray-500/80" />
          </div>
        </div>
      </NavLink>
    </Card>
  );
}

function ProgressBar(props: { progress: number; color?: "orange" | "green" }) {
  const color = props.color || "green";
  return (
    <div className="h-2 w-full bg-green-500/20 rounded-md">
      <div
        className={`h-2 ${
          color === "green" ? "bg-green-500" : "bg-orange-500"
        } rounded-md transition-all duration-300 ease-in-out`}
        style={{ width: `${props.progress}%` }}
      ></div>
    </div>
  );
}
