interface Part {
  PartNumber: number;
  signedUrl: string;
}

interface UploadedPart {
  PartNumber: number;
  ETag: string;
}

interface Options {
  threadsQuantity?: number;
  parts: Part[];
  file: File;
  uploadId: string;
}

const CHUNK_SIZE = 1024 * 1024 * 5;

export function getNumberOfParts(file: File) {
  return Math.ceil(file.size / CHUNK_SIZE);
}

export class Uploader {
  private threadsQuantity: number;
  private file: File;
  private aborted: boolean;
  private uploadedSize: number;
  private progressCache: { [key: number]: number };
  private activeConnections: { [key: number]: XMLHttpRequest };
  private parts: Part[];
  private uploadedParts: UploadedPart[];
  private uploadId: string | null;
  private fileKey: string | null;
  private onProgressFn: (progress: {
    sent: number;
    total: number;
    percentage: number;
  }) => void;
  private onErrorFn: (error: Error) => void;
  private onCompleteFn: (uploadedParts: UploadedPart[]) => void;

  constructor(options: Options) {
    this.file = options.file;
    this.uploadId = options.uploadId;
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = options.parts;
    this.uploadedParts = [];
    this.fileKey = null;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
    this.onCompleteFn = () => {};
  }

  start() {
    this.initialize();
  }

  private async initialize() {
    try {
      this.sendNext();
    } catch (error: any) {
      await this.complete(error);
    }
  }

  private sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }
      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * CHUNK_SIZE;
      const chunk = this.file.slice(sentSize, sentSize + CHUNK_SIZE);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.parts.push(part);
          this.complete(error);
        });
    }
  }

  private async complete(error?: Error) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    this.onCompleteFn(this.uploadedParts);
  }

  private sendChunk(
    chunk: Blob,
    part: Part,
    sendChunkStarted: () => void
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error("Failed chunk upload"));
            return;
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private handleProgress(part: number, event: ProgressEvent) {
    if (this.file) {
      if (
        event.type === "progress" ||
        event.type === "error" ||
        event.type === "abort"
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === "uploaded") {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);
      const total = this.file.size;
      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  private upload(
    file: Blob,
    part: Part,
    sendChunkStarted: () => void
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      if (this.uploadId) {
        const xhr = (this.activeConnections[part.PartNumber - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1
        );

        xhr.upload.addEventListener("progress", progressListener);
        xhr.addEventListener("error", progressListener);
        xhr.addEventListener("abort", progressListener);
        xhr.addEventListener("loadend", progressListener);

        xhr.open("PUT", part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader("ETag");

            if (ETag) {
              const uploadedPart: UploadedPart = {
                PartNumber: part.PartNumber,
                ETag: ETag.replace(/"/g, ""),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = (error) => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error("Upload canceled by user"));
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  onComplete(onComplete: (uploadedParts: UploadedPart[]) => void) {
    this.onCompleteFn = onComplete;
    return this;
  }

  onProgress(
    onProgress: (progress: {
      sent: number;
      total: number;
      percentage: number;
    }) => void
  ) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError: (error: Error) => void) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
