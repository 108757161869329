import React, { useState } from "react";
import { classNames } from "../utils/cn";

interface Tab {
  label: string;
  content: React.ReactNode;
  badge?: string;
  icon?: React.ReactNode;
}

const Tabs = ({
  tabs,
  type = "underline",
}: {
  tabs: Tab[];
  type?: "underline" | "pill";
}) => {
  const [activeTab, setActiveTab] = useState(0);

  if (type === "pill") {
    return (
      <div className="flex-1 flex flex-col">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            defaultValue={tabs[activeTab].label}
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          >
            {tabs.map((tab) => (
              <option key={tab.label}>{tab.label}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav aria-label="Tabs" className="flex space-x-4">
            {tabs.map((tab, i) => (
              <button
                key={tab.label}
                onClick={() => setActiveTab(i)}
                aria-current={i === activeTab ? "page" : undefined}
                className={classNames(
                  i === activeTab
                    ? "bg-gray-200 text-gray-800"
                    : "text-gray-500 hover:text-gray-700",
                  "rounded-md px-3 py-2 text-sm font-medium flex items-center gap-x-2"
                )}
              >
                {tab.icon ? (
                  <span className="inline-flex items-center">{tab.icon}</span>
                ) : null}
                {tab.label}
              </button>
            ))}
          </nav>
        </div>
        <div className="flex-1 flex flex-col">
          {tabs[activeTab] && <div>{tabs[activeTab].content}</div>}
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Tabs Navigation */}
      <div className="flex border-b border-gray-200">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`px-4 py-2 -mb-px text-sm font-medium flex items-center gap-x-2 border-b-2 ${
              activeTab === index
                ? "border-persian-500 text-persian-600"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.icon ? (
              <span className="inline-flex items-center">{tab.icon}</span>
            ) : null}
            {tab.label}
            {tab.badge ? (
              <span
                className={`inline-flex items-center justify-center w-4 h-4 text-xs font-semibold ${
                  index === activeTab
                    ? "text-persian-800 bg-persian-200"
                    : "text-gray-800 bg-gray-200"
                } rounded-full`}
              >
                {tab.badge}
              </span>
            ) : null}
          </button>
        ))}
      </div>

      <div className="">
        {tabs[activeTab] && <div>{tabs[activeTab].content}</div>}
      </div>
    </div>
  );
};

export default Tabs;
