import { AppState } from "..";
import { DealRunner } from "../../graphql/generated";
import { AuthState } from "./slice";

function data(state: AppState): AuthState {
  return state.auth;
}

function isLoading(state: AppState): boolean {
  return state.auth.isLoading;
}

function isLoggedIn(state: AppState): boolean {
  return state.auth.isLoggedIn;
}

function accessToken(state: AppState) {
  return state.auth.accessToken;
}

function activeDealId(state: AppState) {
  return state.auth.activeDealId;
}

function activeDeal(state: AppState) {
  if (!state.auth.account || !state.auth.account.deals) {
    return undefined;
  }

  return state.auth.account.deals.find((c) => c.id === state.auth.activeDealId);
}

function deal(state: AppState, dealId: string) {
  if (!state.auth.account || !state.auth.account.deals) {
    return undefined;
  }

  return state.auth.account.deals.find((c) => c.id === dealId);
}

function dealLastSeen(state: AppState, dealId: string): number | undefined {
  if (!state.auth.dealsLastSeen[dealId]) {
    return undefined;
  }

  return state.auth.dealsLastSeen[dealId];
}

function dealsLastSeen(state: AppState): Record<string, number> {
  return state.auth.dealsLastSeen;
}

function activeDealRunner(state: AppState) {
  const deal = activeDeal(state);
  if (!deal) {
    return DealRunner.Seller;
  }

  return deal.runner;
}

function actor(state: AppState) {
  return state.auth.actor;
}

function activeDealAccount(state: AppState) {
  const deal = activeDeal(state);

  if (!deal || !state.auth.account) {
    return undefined;
  }

  const activeDealAccount = deal.dealAccounts.find((da) => {
    if (!state.auth.account) {
      return false;
    }

    if (state.auth.actor) {
      return da.account.id === state.auth.actor.account.id;
    }

    return da.account.id === state.auth.account.id;
  });

  return activeDealAccount;
}

function account(state: AppState) {
  return state.auth.account;
}

// hack hack hack
function staffSheetQuestionAnswer(state: AppState) {
  return state.auth.staffSheetQuestionAnswer;
}

export const authSelectors = {
  data,
  isLoading,
  isLoggedIn,
  accessToken,
  activeDeal,
  deal,
  activeDealId,
  activeDealRunner,
  actor,
  account,
  activeDealAccount,
  dealLastSeen,
  dealsLastSeen,

  // hack hack hack
  staffSheetQuestionAnswer,
};
