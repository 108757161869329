import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, ReactNode } from "react";
import { classNames } from "../../utils/cn";
import { withMarginTw } from "../../styles/withMargin";

export interface Option {
  label: string;
  description?: string;
  icon?: ReactNode;
  value: string | null;
}

const textSizeMap = {
  xs: "text-xs",
  s: "text-sm",
  m: "text-sm",
};

function RenderOption(props: {
  option: Option;
  variant?: "button" | "text" | "light";
  size?: "xs" | "s" | "m";
  hideSelectedOptionDescription?: boolean;
}) {
  return (
    <div className="flex items-center gap-x-2">
      {props.option.icon ? <>{props.option.icon}</> : null}
      <div>
        <p
          className={classNames(
            " truncate",
            props.variant === "light"
              ? "text-gray-500 font-normal"
              : "text-gray-600 font-semibold",
            textSizeMap[props.size ?? "m"]
          )}
        >
          {props.option.label}
        </p>
        {props.option.description &&
        !props.hideSelectedOptionDescription &&
        (!props.variant || props.variant !== "text") ? (
          <p
            className={classNames(
              " text-gray-500 font-normal truncate",
              textSizeMap[props.size ?? "m"]
            )}
          >
            {props.option.description}
          </p>
        ) : null}
      </div>
    </div>
  );
}

const paddingSizeMap = {
  xs: "py-1",
  s: "py-1.5",
  m: "py-2",
};

export default function Dropdown(props: {
  options: Option[];
  onSelect: (option: Option) => void;
  label?: string;
  defaultOption?: Option;
  selectedOption?: Option;
  margin?: string;
  variant?: "button" | "text" | "light";
  size?: "xs" | "s" | "m";
  hideSelectedOptionDescription?: boolean;
}) {
  const margin = withMarginTw({ margin: props.margin });

  const handleSelect = (option: Option) => {
    props.onSelect(option);
  };

  return (
    <div className={` ${margin}`}>
      {props.label ? (
        <label className="block text-sm font-medium leading-6 text-gray-900">
          {props.label}
        </label>
      ) : null}
      <Menu as="div" className="relative inline-block text-left w-full">
        <div>
          <Menu.Button
            className={classNames(
              "inline-flex w-full justify-between items-center text-left gap-x-1.5 rounded-md bg-white px-3  text-sm ",
              props.variant && props.variant === "text"
                ? ""
                : props.variant === "light"
                ? "shadow-sm ring-1 ring-inset font-light text-gray-500 ring-gray-300 outline-none focus:ring-gray-600 hover:bg-gray-50"
                : "shadow-sm ring-2 ring-inset ring-gray-300 outline-none focus:ring-gray-600 hover:bg-gray-50 font-semibold text-gray-900",
              paddingSizeMap[props.size ?? "m"]
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <RenderOption
              size={props.size}
              variant={props.variant}
              hideSelectedOptionDescription={
                props.hideSelectedOptionDescription
              }
              option={
                props.selectedOption ??
                props.defaultOption ?? {
                  label: "Select",
                  value: "",
                }
              }
            />

            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {props.options.map((o) => {
                return (
                  <Menu.Item key={o.value}>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm w-full text-left"
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelect(o);
                        }}
                      >
                        <RenderOption option={o} />
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
