import { Link, Route, Switch, useLocation } from "react-router-dom";
import { H1 } from "../../../components/Heading";

const navigation = [
  {
    name: "Profit & Loss",
    href: "/finances/statements/profit-loss",
    current: true,
  },
  {
    name: "Cash Flow",
    href: "/finances/statements/cash-flow",
    current: false,
  },
  {
    name: "Balance Sheet",
    href: "/finances/statements/balance-sheet",
    current: false,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function Statements() {
  const location = useLocation();
  return (
    <div className="flex-1 mb-8">
      <H1>Statements</H1>
      <div className="flex h-16 justify-between">
        <div className="flex">
          <div className="hidden sm:-my-px  sm:flex sm:space-x-8">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  location.pathname === item.href
                    ? "border-indigo-500 text-gray-900"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                )}
                aria-current={item.current ? "page" : undefined}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="flex-1 mt-8">
        <Switch>
          <Route path="/finances/statements/profit-loss"></Route>
        </Switch>
      </div>
    </div>
  );
}
