import * as React from "react";

const Logo = (
  props: React.SVGProps<SVGSVGElement> & { mode?: "dark" | "light" }
) => {
  if (props.mode && props.mode === "light") {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1722 445.99"
        {...props}
      >
        <defs>
          <style>
            {
              ".cls-1 { fill: #A56CFF;}  .cls-1, .cls-2 { stroke-width: 0px; } .cls-2 { fill: #2F007A; }"
            }
          </style>
        </defs>
        <g>
          <path
            className="cls-2"
            d="M578.19,56.33h54.27v261.1h133.89v45.82h-188.16V56.33Z"
          />
          <path
            className="cls-2"
            d="M819.59,38.54c18.68,0,33.36,14.68,33.36,32.47,0,18.68-14.68,32.47-33.36,32.47s-32.02-13.79-32.02-32.47,14.23-32.47,32.02-32.47ZM793.34,131.95h52.94v231.3h-52.94v-231.3Z"
          />
          <path
            className="cls-2"
            d="M1064.64,332.12h-.89c-13.79,20.9-42.71,37.36-78.29,37.36-64.94,0-113.43-52.49-113.43-121.43s49.82-122.32,113.43-122.32c36.02,0,67.61,17.79,80.95,40.92h.89v-34.7h50.26v314.04h-52.93v-113.87ZM996.59,322.33c40.92,0,69.39-32.47,69.39-74.73s-28.47-75.17-69.39-75.17-69.4,32.92-69.4,75.17,28.47,74.73,69.4,74.73Z"
          />
          <path
            className="cls-2"
            d="M1151.36,279.63v-147.68h53.37v138.79c0,34.25,22.69,51.6,52.94,51.6s53.38-17.35,53.38-51.6v-138.79h52.93v147.68c0,50.27-40.48,89.86-106.31,89.86s-106.31-39.59-106.31-89.86Z"
          />
          <path
            className="cls-2"
            d="M1424.01,38.54c18.68,0,33.36,14.68,33.36,32.47,0,18.68-14.68,32.47-33.36,32.47s-32.02-13.79-32.02-32.47,14.23-32.47,32.02-32.47ZM1397.77,131.95h52.94v231.3h-52.94v-231.3Z"
          />
          <path
            className="cls-2"
            d="M1476.47,247.16c0-68.95,48.48-121.43,113.43-121.43,35.58,0,64.49,16.46,78.29,37.36h.89V29.64h52.93v333.61h-50.26v-34.25h-.89c-13.34,23.13-44.93,40.48-80.95,40.48-63.61,0-113.43-52.05-113.43-122.32ZM1601.02,322.78c40.92,0,69.39-32.91,69.39-74.73s-28.47-75.17-69.39-75.17-69.4,32.47-69.4,75.17,28.47,74.73,69.4,74.73Z"
          />
        </g>
        <path
          className="cls-2"
          d="M77.86,341.72V78.04h0c-43,0-77.86,34.86-77.86,77.86v188.74c0,41.39,33.55,74.94,74.94,74.94h188.75c43,0,77.86-34.86,77.86-77.86h0s-263.68,0-263.68,0Z"
        />
        <path
          className="cls-1"
          d="M344.64,0h-188.75c-43,0-77.86,34.86-77.86,77.86h208.63l-143.43,143.43,55.05,55.05,143.43-143.43v208.63h0c43,0,77.86-34.86,77.86-77.86V74.94c0-41.39-33.55-74.94-74.94-74.94Z"
        />
      </svg>
    );
  }

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1722 445.99"
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-1 { fill: #a56cff;}  .cls-1, .cls-2 { stroke-width: 0px; } .cls-2 { fill: #f2f2f2; }"
          }
        </style>
      </defs>
      <g>
        <path
          className="cls-2"
          d="M578.19,56.33h54.27v261.1h133.89v45.82h-188.16V56.33Z"
        />
        <path
          className="cls-2"
          d="M819.59,38.54c18.68,0,33.36,14.68,33.36,32.47,0,18.68-14.68,32.47-33.36,32.47s-32.02-13.79-32.02-32.47,14.23-32.47,32.02-32.47ZM793.34,131.95h52.94v231.3h-52.94v-231.3Z"
        />
        <path
          className="cls-2"
          d="M1064.64,332.12h-.89c-13.79,20.9-42.71,37.36-78.29,37.36-64.94,0-113.43-52.49-113.43-121.43s49.82-122.32,113.43-122.32c36.02,0,67.61,17.79,80.95,40.92h.89v-34.7h50.26v314.04h-52.93v-113.87ZM996.59,322.33c40.92,0,69.39-32.47,69.39-74.73s-28.47-75.17-69.39-75.17-69.4,32.92-69.4,75.17,28.47,74.73,69.4,74.73Z"
        />
        <path
          className="cls-2"
          d="M1151.36,279.63v-147.68h53.37v138.79c0,34.25,22.69,51.6,52.94,51.6s53.38-17.35,53.38-51.6v-138.79h52.93v147.68c0,50.27-40.48,89.86-106.31,89.86s-106.31-39.59-106.31-89.86Z"
        />
        <path
          className="cls-2"
          d="M1424.01,38.54c18.68,0,33.36,14.68,33.36,32.47,0,18.68-14.68,32.47-33.36,32.47s-32.02-13.79-32.02-32.47,14.23-32.47,32.02-32.47ZM1397.77,131.95h52.94v231.3h-52.94v-231.3Z"
        />
        <path
          className="cls-2"
          d="M1476.47,247.16c0-68.95,48.48-121.43,113.43-121.43,35.58,0,64.49,16.46,78.29,37.36h.89V29.64h52.93v333.61h-50.26v-34.25h-.89c-13.34,23.13-44.93,40.48-80.95,40.48-63.61,0-113.43-52.05-113.43-122.32ZM1601.02,322.78c40.92,0,69.39-32.91,69.39-74.73s-28.47-75.17-69.39-75.17-69.4,32.47-69.4,75.17,28.47,74.73,69.4,74.73Z"
        />
      </g>
      <path
        className="cls-2"
        d="M77.86,341.72V78.04h0c-43,0-77.86,34.86-77.86,77.86v188.74c0,41.39,33.55,74.94,74.94,74.94h188.75c43,0,77.86-34.86,77.86-77.86h0s-263.68,0-263.68,0Z"
      />
      <path
        className="cls-1"
        d="M344.64,0h-188.75c-43,0-77.86,34.86-77.86,77.86h208.63l-143.43,143.43,55.05,55.05,143.43-143.43v208.63h0c43,0,77.86-34.86,77.86-77.86V74.94c0-41.39-33.55-74.94-74.94-74.94Z"
      />
    </svg>
  );
};
export default Logo;
