import useGqlClient from "@/src/hooks/useGqlClient";
import { AccessList } from "../AccessList";
import { useQueryClient } from "@tanstack/react-query";
import { useInternalAccessQuery } from "@/src/graphql/generated";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import Loading from "@/src/components/Loading";

export function InternalAccess() {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const internalAccessQuery = useInternalAccessQuery(client, {
    dealId: activeDealId ?? "",
  });

  if (internalAccessQuery.error) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <p className="text-gray-700 font-semibold">Something went wrong</p>
      </div>
    );
  }

  if (internalAccessQuery.isLoading || !internalAccessQuery.data) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className=" w-full flex-1 flex flex-col xl:gap-y-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold text-gray-900">
            Internal access
          </h1>
          <p className="text-sm text-gray-700">
            Everyone here is an admin of this deal. They have access to all
            documents in the deal, the Toolkit and Assistant.
          </p>
        </div>
      </div>

      <AccessList
        dealId={internalAccessQuery.data.deal.id}
        dataRoomId={internalAccessQuery.data.deal.dataRoom.id}
        permissions={internalAccessQuery.data.deal.dataRoom.permissions}
        activeDealAccount={internalAccessQuery.data.deal.activeDealAccount}
        groups={internalAccessQuery.data.deal.ownerGroups}
        invites={[]}
        otherGuests={[]}
        accountSearch={""}
      />
    </div>
  );
}

function InternalAccessContent() {}
