import { useState } from "react";
import { AnimatedModal } from "../../components/AnimatedModal";
import { CloseIcon } from "../../components/CloseIcon";
import { H3 } from "../../components/Heading";
import { TextArea } from "../../components/tailwind/TextArea";
import { DataRoomFileErrorQuery } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { Button } from "../../components/tailwind/Button";
import { NoSymbolIcon } from "@heroicons/react/20/solid";

export function DismissErrorModal(props: {
  dataRoomFileError: DataRoomFileErrorQuery["dataRoomFileError"];
  open: boolean;
  onClose: () => void;
}) {
  const client = useGqlClient();
  const [dismissalReason, setDismissalReason] = useState<string>("");
  const [ignoreKey, setIgnoreKey] = useState(false);

  return (
    <AnimatedModal size="lg" open={props.open} onClose={props.onClose}>
      <div>
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-x-2">
              <NoSymbolIcon className="h-8 w-8 -ml-1 text-gray-500" />
              <div>
                <H3>Ignore error</H3>
                <p className="text-sm text-gray-500/80 leading-tight">
                  Ignore{" "}
                  <span className="font-medium">
                    {props.dataRoomFileError.key}
                  </span>{" "}
                </p>
              </div>
            </div>
          </div>
          <CloseIcon onClose={props.onClose} />
        </div>
        <form
          className="mt-6"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div>
            <label
              htmlFor="Reason"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Reason
            </label>
            <TextArea
              label="Reason"
              rows={2}
              placeholder="Provide a reason for ignoring this error"
              name="Reason"
              value={dismissalReason}
              onChange={(e) => {
                setDismissalReason(e.currentTarget.value);
              }}
            />

            <div className="mt-4 relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="ignoreKey"
                  aria-describedby={`Ignore ${props.dataRoomFileError.baseKey} for all future errors`}
                  name="Ignore Key"
                  type="checkbox"
                  checked={ignoreKey}
                  onChange={(e) => {
                    setIgnoreKey(e.currentTarget.checked);
                  }}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label
                  htmlFor="granularAccess"
                  className="font-medium text-gray-900 leading-tight cursor-pointer"
                >
                  Always ignore {props.dataRoomFileError.baseKey}
                </label>
                <p
                  id="granularAccess-description"
                  className="text-gray-500 leading-tight"
                >
                  <span className="font-medium">
                    {props.dataRoomFileError.baseKey}
                  </span>{" "}
                  won't be checked for errors in the future
                </p>
              </div>
            </div>

            <div className="flex justify-end mt-12">
              <Button
                variant="positive"
                text="Ignore"
                isDisabled={dismissalReason.length === 0}
                loadingText="Ignoring..."
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}
