import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DealFirmGroupPresence } from "../../types";

export type PresenceState = {
  dealFirmGroups: {
    [id: string]: DealFirmGroupPresence;
  };
};

const initialState: PresenceState = {
  dealFirmGroups: {},
};

export const { actions, reducer } = createSlice({
  name: "presence",
  initialState,
  reducers: {
    updateDealFirmGroupPresence: (
      state,
      action: PayloadAction<DealFirmGroupPresence>
    ) => {
      state.dealFirmGroups[action.payload.id] = action.payload;
    },
  },
});
