import { Dialog } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useGqlClient from "../../hooks/useGqlClient";
import { useQueryClient } from "@tanstack/react-query";
import {
  DataRoomFileQuery,
  DeletionType,
  useDataRoomFileQuery,
  useDeleteDataRoomFileMutation,
} from "../../graphql/generated";
import { DocumentIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { AnimatedModal } from "../../components/AnimatedModal";
import { classNames } from "../../utils/cn";
import { useInvalidateQueryKeys } from "../../hooks/useInvalidateQueryKeys";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface DeleteFileProps {
  onClose: () => void;
  onDeleted?: () => void;
  open: boolean;
  dataRoomFileId: string;
}

export function DeleteFile(props: DeleteFileProps) {
  return (
    <AnimatedModal open={props.open} onClose={props.onClose} size="xl">
      <DeleteFileContent
        id={props.dataRoomFileId}
        onCancel={() => props.onClose()}
        onDelete={() => {
          if (props.onDeleted) {
            props.onDeleted();
          }

          props.onClose();
        }}
      />
    </AnimatedModal>
  );
}

interface DeleteFileContentProps {
  id: string | null;
  onCancel: () => void;
  onDelete: () => void;
}

function DeleteFileContent(props: DeleteFileContentProps) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const fileQuery = useDataRoomFileQuery(client, {
    id: props.id || "",
  });

  const deleteDataRoomFile = useDeleteDataRoomFileMutation(client);
  const { invalidateDealActivities } = useInvalidateQueryKeys();

  const [data, setData] = useState<DataRoomFileQuery | null>(null);

  const [selectedDeletionType, setSelectedDeletionType] =
    useState<DeletionType>(DeletionType.Soft);

  useEffect(() => {
    if (fileQuery.data) {
      setData(fileQuery.data);
    }
  }, [fileQuery.data]);

  if (fileQuery.isPending && !data) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <DocumentIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Loading...
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">Please wait</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (fileQuery.error || !data) {
    return (
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Something went wrong
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                We couldn't load the file. Please try again.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <DocumentIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            {`Delete ${data.dataRoomFile.name}`}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to delete {data.dataRoomFile.name}?
            </p>
          </div>
          <div className="mt-3">
            <p className="text-sm font-semibold text-gray-700">Deletion type</p>
            <div className="flex gap-x-2 mt-2">
              <div
                onClick={() => setSelectedDeletionType(DeletionType.Soft)}
                className={classNames(
                  "flex-1 border-2 rounded-md p-3 cursor-pointer",
                  selectedDeletionType === DeletionType.Soft
                    ? "border-2 border-indigo-400"
                    : ""
                )}
              >
                <p className="text-sm font-semibold text-gray-600">
                  Soft delete
                </p>
                <ol className="space-y-1 mt-1">
                  <li className="list-none text-xs text-gray-500">
                    File cannot be viewed/downloaded
                  </li>
                  <li className="list-none text-xs text-gray-500">
                    File activity is preserved in the audit log
                  </li>
                  <li className="list-none text-xs text-gray-500">
                    On file pill name is still visible but no other information
                    is accessible
                  </li>
                </ol>
              </div>
              <div
                onClick={() => setSelectedDeletionType(DeletionType.Hard)}
                className={classNames(
                  "flex-1 border-2 rounded-md p-3 cursor-pointer",
                  selectedDeletionType === DeletionType.Hard
                    ? "border-2 border-indigo-400"
                    : ""
                )}
              >
                <p className="text-sm font-semibold text-gray-600">
                  Hard delete
                </p>
                <ol className="space-y-1 mt-1">
                  <li className=" text-xs text-gray-500">
                    File cannot be accessed
                  </li>
                  <li className="text-xs text-gray-500">
                    File activity is not preserved in the audit log
                  </li>
                  <li className="text-gray-500 text-xs">
                    On file pills the file name is not visible
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 sm:mt-12 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            deleteDataRoomFile.mutate(
              {
                input: {
                  id: data.dataRoomFile.id,
                  deletionType: selectedDeletionType,
                },
              },
              {
                onSuccess: () => {
                  invalidateDealActivities();
                  queryClient
                    .invalidateQueries({
                      queryKey: [
                        "DataRoomFolder",
                        {
                          id: data.dataRoomFile.parentFolders[0].id,
                        },
                      ],
                    })
                    .then(() => {
                      props.onDelete();
                    });
                },
              }
            );
          }}
        >
          {deleteDataRoomFile.isPending ? "Deleting..." : "Delete"}
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {
            props.onCancel();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
