import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/cn";
import { withMarginTw } from "../../styles/withMargin";

type InputProps = {
  label?: string;
  secondaryLabel?: string;
  name?: string;
  id?: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  help?: string;
  type?: string;
  error?: string;
  autoFocus?: boolean;
  inputSize?: "s";
  margin?: string;
  ignore1p?: boolean;
  endComponent?: React.ReactNode;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export function TextInput(props: InputProps) {
  const margin = withMarginTw({ margin: props.margin });
  return (
    <div className={margin}>
      <div className="flex items-center justify-between">
        {props.label ? (
          <label
            htmlFor={props.name}
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {props.label}
          </label>
        ) : null}
        {props.secondaryLabel ? (
          <span className="text-xs text-gray-500">{props.secondaryLabel}</span>
        ) : null}
      </div>

      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          onClick={(e) => {
            e.stopPropagation();
          }}
          onBlur={props.onBlur}
          type={props.type}
          value={props.value}
          name={props.name}
          id={props.id}
          disabled={props.disabled}
          className={classNames(
            "block w-full rounded-md border-0  text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6",
            props.error
              ? "ring-red-300 focus:ring-red-600"
              : "ring-gray-300 focus:ring-gray-600",
            props.inputSize === "s" ? "py-1 leading-6" : "py-1.5",
            props.disabled ? "bg-gray-100 ring-0" : "bg-white"
          )}
          placeholder={props.placeholder}
          onChange={props.onChange}
          data-1p-ignore={props.ignore1p}
        />
        {props.endComponent ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            {props.endComponent}
          </div>
        ) : null}
        {props.error && !props.endComponent ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {props.error ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.error}
        </p>
      ) : null}
    </div>
  );
}
