import {
  AllDataRoomFoldersQuery,
  DataRoomFile,
  useAllDataRoomFoldersQuery,
} from "../graphql/generated";
import { PlusIcon } from "@heroicons/react/20/solid";
import { Combobox, Popover, Transition } from "@headlessui/react";
import { useRef, useState } from "react";
import useGqlClient from "../hooks/useGqlClient";
import Loading from "./Loading";
import { classNames } from "../utils/cn";
import { FileIcon } from "./FileIcon";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";

export function AddFilePill(props: {
  onFileSelected: (file: DataRoomFile) => void;
}) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <Popover className="relative">
      <Popover.Button
        ref={buttonRef}
        className="relative cursor-pointer  hover:border-gray-400 bg-gray-100 hover:shadow-sm flex items-center rounded-2xl border px-2 py-1"
      >
        <PlusIcon className="h-5 w-5 text-gray-600/80" />
        <p className="text-xs select-none text-gray-600 ml-1 truncate">
          Add file
        </p>
        <Transition
          enter="ease-in-out duration-0"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-0"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Popover.Panel className="absolute z-20 mt-5 w-96 -translate-x-1/3 transform px-4">
            <PopOut
              onFileSelected={(file) => {
                props.onFileSelected(file);
                buttonRef.current?.click();
              }}
            />
          </Popover.Panel>
        </Transition>
      </Popover.Button>
    </Popover>
  );
}

const PopOut = ({
  onFileSelected,
}: {
  onFileSelected: (file: DataRoomFile) => void;
}) => {
  // Adjust the style below based on the exact positioning you need

  const [selected, setSelected] = useState();
  const [query, setQuery] = useState("");

  return (
    <div className="border rounded-md ml-10">
      <Combobox
        onChange={(f: DataRoomFile) => {
          onFileSelected(f);
        }}
      >
        <div className="">
          <div className="relative w-full cursor-default overflow-hidden rounded-t-md bg-white text-left border-b border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              placeholder="Search files..."
              autoFocus
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>

          <div className="bg-white rounded-b-md">
            <SearchResults searchTerm={query} />
          </div>
        </div>
      </Combobox>
    </div>
  );
};

interface SearchResultsProps {
  searchTerm: string;
}

function SearchResults(props: SearchResultsProps) {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);

  const allFolders = useAllDataRoomFoldersQuery(client, {
    dealId: activeDealId ?? "",
  });

  if (allFolders.isError) {
    return <div>Something went wrong</div>;
  }

  if (allFolders.isPending || !allFolders.data) {
    return (
      <div className="h-full bg-white justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Combobox.Options
        static
        className="max-h-80 scroll-py-2 divide-y  divide-gray-100 overflow-y-auto"
      >
        <li>
          <ul className="text-sm text-gray-700">
            {allFolders.data.deal.dataRoom.allFolders.map((folder) => {
              return (
                <FolderContent
                  folder={folder}
                  searchTerm={props.searchTerm}
                  key={folder.id}
                />
              );
            })}
          </ul>
        </li>
      </Combobox.Options>
    </>
  );
}

function FolderContent(props: {
  folder: AllDataRoomFoldersQuery["deal"]["dataRoom"]["allFolders"][0];
  searchTerm: string;
}) {
  if (
    props.folder.files.filter((file) =>
      props.searchTerm.length > 2
        ? file.name.toLowerCase().includes(props.searchTerm.toLowerCase())
        : true
    ).length === 0
  ) {
    return null;
  }

  return (
    <div>
      <p className="text-xs px-3 py-1 text-left text-ellipsis">
        {props.folder.parentFolders.length > 0
          ? `${props.folder.parentFolders
              .map((f) => (f.name ? f.name : "Home"))
              .reverse()
              .join(" / ")}`
          : "Home /"}
      </p>
      {props.folder.files
        .filter((file) =>
          props.searchTerm.length > 2
            ? file.name.toLowerCase().includes(props.searchTerm.toLowerCase())
            : true
        )
        .map((file) => {
          return (
            <Combobox.Option
              key={file.id}
              value={file}
              className={({ active }) =>
                classNames(
                  "cursor-default select-none last:rounded-b-md px-3 py-2",
                  active ? "bg-gray-200/70" : ""
                )
              }
            >
              {({ active }) => (
                <div className="">
                  <div className="flex items-center">
                    <FileIcon fileType={file.fileType} />
                    <p className="ml-1 font-bold leading-tight mt-0.5 text-sm text-left">
                      {file.name}
                    </p>
                  </div>
                </div>
              )}
            </Combobox.Option>
          );
        })}
    </div>
  );
}
