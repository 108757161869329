import * as React from "react";
import { useTheme } from "../hooks/useTheme";

export default function Loading() {
  return (
    <div className="flex h-full flex-1 justify-center items-center">
      <Loader style={{ width: 30, height: 6 }} />
    </div>
  );
}

export function Loader(
  props: React.SVGProps<SVGSVGElement> & { fill?: string }
) {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 60 12"
      {...props}
    >
      <circle
        cx={6}
        cy={6}
        r={6}
        fill={props.fill ? props.fill : theme.color.typography.heading}
      >
        <animate
          attributeName="opacity"
          begin={0.1}
          dur="1s"
          repeatCount="indefinite"
          values="0;1;0"
        />
      </circle>
      <circle
        cx={26}
        cy={6}
        r={6}
        fill={props.fill ? props.fill : theme.color.typography.heading}
      >
        <animate
          attributeName="opacity"
          begin={0.2}
          dur="1s"
          repeatCount="indefinite"
          values="0;1;0"
        />
      </circle>
      <circle
        cx={46}
        cy={6}
        r={6}
        fill={props.fill ? props.fill : theme.color.typography.heading}
      >
        <animate
          attributeName="opacity"
          begin={0.3}
          dur="1s"
          repeatCount="indefinite"
          values="0;1;0"
        />
      </circle>
    </svg>
  );
}
